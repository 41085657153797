import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataserviceService } from 'src/app/dataservice.service';
import { IPolicy } from 'src/interfaces';

@Component({
    selector: 'app-alarmpanel',
    templateUrl: './alarmpanel.component.html',
    styleUrls: ['./alarmpanel.component.scss']
})
export class AlarmpanelComponent implements OnInit {
    policy: IPolicy;
    constructor(public dataservice: DataserviceService, public dialogRef: MatDialogRef<AlarmpanelComponent>, @Inject(MAT_DIALOG_DATA) public data: { policy: IPolicy }) {
        this.policy = data.policy;
    }

    ngOnInit(): void {}

    getParams(type: string, key?: string, invert?: boolean) {
        const retval = [];
        for (const act in this.policy.parameters) {
            if (this.policy.parameters.hasOwnProperty(act)) {
                if (this.policy.parameters[act].code.indexOf(type) !== -1) {
                    if (undefined === key || (invert !== true && undefined !== key && this.policy.parameters[act].message[key]) || (invert === true && undefined !== key && !this.policy.parameters[act].message[key])) {
                        retval.push(this.policy.parameters[act]);
                    }
                }
            }
        }
        return retval;
    }
    close() {
        this.dialogRef.close();
    }

    insertAll() {
        const data = {
            value: 0,
            action: 'ARM'
        };
        this.dataservice.sendMessageToServer(this.policy, 'ARMPART', data);
    }

    excludeAll() {
        const data = {
            action: 'DISARM',
            value: 0
        };
        this.dataservice.sendMessageToServer(this.policy, 'ARMPART', data);
    }

}
