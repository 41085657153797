import { DataserviceService } from 'src/app/dataservice.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-siteconfig',
    templateUrl: './siteconfig.component.html',
    styleUrls: ['./siteconfig.component.scss']
})
export class SiteconfigComponent implements OnInit {
    constructor(public dataservice: DataserviceService) { }

    ngOnInit(): void { }
    update() {
        if (this.dataservice.siteConfiguration.codeLen < 4 || this.dataservice.siteConfiguration.codeLen > 8) {
            this.dataservice.siteConfiguration.codeLen = 6;
        }
        this.dataservice.saveSiteConfiguration();
    }
}
