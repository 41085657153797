import { Component, Input } from '@angular/core';
import { DataserviceService } from 'src/app/dataservice.service';
import { IInputUpdateItem, ILogicalElement, ILogicalElementItem, IParameterInfo } from 'src/interfaces';

@Component({
  selector: 'app-wallbuttons',
  templateUrl: './wallbuttons.component.html',
  styleUrls: ['./wallbuttons.component.scss']
})
export class WallbuttonsComponent {
  @Input() data: ILogicalElement;
  currentItem: ILogicalElementItem = undefined;

  public constructor(public dataservice: DataserviceService) {
    this.dataservice.deviceInputUpdate.subscribe((input) => {
      if (this.currentItem !== undefined) {
        const inp = input as IInputUpdateItem;
        console.log(inp);



        const devnew = {} as IParameterInfo;
        devnew.device_id = inp.device_id;
        devnew.element = [inp.mask];
        this.currentItem.data = devnew;


        devnew.element.push(inp.mask);

      }

    });
  }
}
