import { Component, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import { DataserviceService } from '../dataservice.service';
import { ICard } from 'src/interfaces';
import { MatDialog } from '@angular/material/dialog';
import { RoomlistComponent } from '../roomlist/roomlist.component';

@Component({
    selector: 'app-cards',
    templateUrl: './cards.component.html',
    styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit {
    constructor(public dialog: MatDialog, public dataservice: DataserviceService, public cd: ChangeDetectorRef) {}
    displayedColumns: string[] = ['typ','card', 'valid', 'name', 'action'];
    currentCard: ICard;
    filterPass = true;
    filterNorm = true;
    filterFind = '';
    ngOnInit() {
        /*  this.currentCard = {
            code: '--------',
            cardName: '-----'
        } as ICard;*/
        this.dataservice.updateCards();
        this.dataservice.fieldUpdate.push((retval) => {
            if (this.currentCard) {
                const card = this.dataservice.cards.filter((x) => x.code === this.currentCard.code);
                if (card.length > 0) {
                    this.currentCard = card[0];
                }
            }
        });
        this.dataservice.receptionchanged.subscribe((card) => {

            this.currentCard = card;
            this.currentCard.alwaiseValid = true;
            this.filterFind = card.code;
        });
    }
    getFiltered() {
        const f1 = this.dataservice.cards.filter((x) => (x.isPassepartout && this.filterPass) || (!x.isPassepartout && this.filterNorm));
        if (this.filterFind.length > 3) {
            const tofind = this.filterFind.toLowerCase();
            return f1.filter((x) => this.filterFind != '' && (x.code.toLowerCase().indexOf(tofind) != -1 || (x.cardName && x.cardName.toLowerCase().indexOf(tofind) != -1)));
        }
        return f1;
    }
    getReceptionCards() {
        /* if (this.dataservice.lastReceptionCard !== undefined) {
            return this.dataservice.lastReceptionCard.code;
        }*/
        if (this.currentCard !== undefined) {
            return this.currentCard.code;
        }
        return '--------';
    }
    select(card: ICard) {
        this.currentCard = card;
    }
    remove() {}

    addRoom(element: ICard) {
        const dialogRef = this.dialog.open(RoomlistComponent, {
            width: '1024px',
            height: '740px',
            data: { ...{}, ...element }
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log('The dialog was closed');
        });
    }

    makePassepartout() {
        if (this.currentCard.isPassepartout) {
            this.dataservice.confirm('Vuoi convertire la tessera da PASSEPARTOUT a tessera normale?', ['Si', 'No']).then((data) => {
                if (data == 0) {
                    this.dataservice.makePassepartout(this.currentCard, false).subscribe((data) => {
                        this.dataservice.toastr.success('La tessera ' + this.currentCard.code + ' è stata rimossa dai passepartout');
                        this.currentCard.isPassepartout = false;
                        this.dataservice.updateCards();
                    });
                }
            });
        } else {
            this.dataservice.confirm('Vuoi convertire la tessera in PASSEPARTOUT?', ['Si', 'No']).then((data) => {
                if (data == 0) {
                    const passe = this.dataservice.cards.filter((x) => x.isPassepartout);
                    if (passe.length < 32) {
                        this.dataservice.makePassepartout(this.currentCard, true).subscribe((data) => {
                            this.dataservice.toastr.success('La tessera ' + this.currentCard.code + ' è ora un passepartout');
                            this.currentCard.isPassepartout = true;
                            this.dataservice.updateCards();
                        });
                    } else {
                        this.dataservice.confirm('Raggiunto il limite massimo di 32 passepartout', ['OK']);
                    }
                }
            });
        }
    }

    removeCard() {
        if (this.currentCard.isPassepartout) {
            this.dataservice.makePassepartout(this.currentCard, false).subscribe((data) => {
                this.dataservice.toastr.success('La tessera ' + this.currentCard.code + ' è stata rimossa dai passepartout');
                this.dataservice.deleteCard(this.currentCard.code).subscribe(() => {
                    this.dataservice.toastr.success('Tessera eliminata');
                    /*if (this.dataservice.cards.length > 0) {
                        this.currentCard = this.dataservice.cards[0];
                    }*/
                    this.currentCard = undefined;
                });
            });
        } else {
            this.dataservice.deleteCard(this.currentCard.code).subscribe((data) => {
                this.dataservice.toastr.success('Tessera eliminata');
                this.currentCard = undefined;
                /*
                if (this.dataservice.cards.length > 0) {
                    this.currentCard = this.dataservice.cards[0];
                }*/
            });
        }
    }
    updateCard() {
        this.dataservice.updateCardData(this.currentCard);
    }
}
