<div class="device" [ngClass]="{ online: device.isOnline, selected: device.selected, root: device.deviceType === 100, used: toolTipCount > 0 }">
    <img class="icondev" [src]="getDevIcon()" />
    <div>
        <div>
            <div>
                <b *ngIf="device.name">{{ device.name }}</b>
            </div>

            <div>{{ getType(device.deviceType) }}-{{ device.deviceType }}</div>
            <div class="legacyprod" *ngIf="device.legacy"><b>LEGACY</b></div>
        </div>
        <div>
            <b
                ><span>[{{ device.device_id }}]</span> <span *ngIf="!device.legacy">&nbsp;-&nbsp;{{ device.mac }}</span
                ><span *ngIf="device.legacy">&nbsp;-&nbsp;{{ device.device_id.toString(16) }}</span></b
            >
            <!--<b *ngIf="getPerm('IDX')">{{ device.device_id }}</b>-->
        </div>
        <!-- <div *ngIf="getPerm('ISMESH')">{{ device.parent }}</div>-->
        <div *ngIf="!device.legacy">sn: {{ device.serial_number }}</div>
        <div *ngIf="!device.legacy">
            up: {{ getUp() }} <span [ngStyle]="GetMStyle()">mem: {{ device.mem8 }}</span>
        </div>
    </div>
    <div *ngIf="device.isOnline && !device.legacy"><span [ngClass]="getFwClass()"></span>Firmware 1.{{ device.fwVersion }}</div>
    <div *ngIf="device.status > 0">Downloading: {{ (device.status / 1000).toFixed(0) }}Kb</div>
    <div *ngIf="getPerm('RSSI') && device.isOnline" [ngClass]="getSignalClass()">RSSI: {{ device.signal - 256 }}</div>
    <div *ngIf="getPerm('485LEV') && device.isOnline" [ngClass]="getSignalClass()">BUS: {{ (device.signal / 10).toFixed(1) }}%</div>
    <div class="devicon_used" *ngIf="toolTip !== ''" aria-hidden="false" [matTooltip]="toolTip" matTooltipClass="allow-cr">{{ toolTipCount }}</div>
    <mat-icon class="devicon" aria-hidden="false" [ngClass]="{ offline: !device.isOnline }">{{ getIcon() }}</mat-icon>
    <ng-template [ngIf]="getSelectable()">
        <mat-icon class="devicon_select" *ngIf="device.isOnline" (click)="seldevice()" [ngClass]="{ 'sel-red': this.device.selected }" aria-hidden="false">{{ this.device.selected ? 'check_box' : 'check_box_outline_blank' }}</mat-icon>
    </ng-template>
    <div class="devdash">
        <mat-icon *ngIf="forbid" class="bullet" aria-hidden="false">remove_circle</mat-icon>
        <mat-icon class="devicon_edit" aria-hidden="false" (click)="editClick()">build</mat-icon>
        <mat-icon class="devicon_delete" [ngClass]="{ col_red: device.deleted }" aria-hidden="false" (click)="deleteClick()">delete_forever</mat-icon>
        <mat-icon class="devicon_find" *ngIf="device.isOnline" (click)="blink()" aria-hidden="false">find_in_page</mat-icon>
        <mat-icon class="devicon_conf" *ngIf="device.isOnline" (click)="goconf()" aria-hidden="false">bluetooth</mat-icon>
        <mat-icon class="devicon_conf" *ngIf="device.isOnline" (click)="reset()" aria-hidden="false">refresh</mat-icon>
    </div>
</div>

<!--


-->
