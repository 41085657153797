import { Component, OnInit, Inject } from '@angular/core';
import { IPolicy, ICard, IConnectedRoomsDetails } from 'src/interfaces';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataserviceService } from 'src/app/dataservice.service';
import moment from 'moment';

@Component({
    selector: 'app-roomdetails',
    templateUrl: './roomdetails.component.html',
    styleUrls: ['./roomdetails.component.scss']
})
export class RoomdetailsComponent implements OnInit {
    displayedColumns: string[] = ['card', 'name', 'guest', 'valid', 'action'];
    room: IPolicy;
    card: ICard;
    errorMessage = '';
    assignCard = false;
    currentCardsRooms: IPolicy[] = [];
    currentCard: ICard;
    details: IConnectedRoomsDetails;
    linkedRoom = '';
    linkedRoomEnabled = false;
    linkedRoomName = '';
    constructor(public dataservice: DataserviceService, public dialogRef: MatDialogRef<RoomdetailsComponent>, @Inject(MAT_DIALOG_DATA) public data: { policy: IPolicy; card: ICard }) {
        this.room = data.policy;
        this.card = data.card;
        if (this.room.parameters.LINK) {
            this.linkedRoom = this.room.parameters.LINK.message;
        }
    }

    ngOnInit() {
        this.dataservice.lastReceptionCard.code = '00000000';
        console.log('Init roomdetail');
        this.linkedRoomName = this.GetLinkedRoomId();
        this.dataservice.receptionchanged.subscribe((card) => {
            this.assignCard = true;
            console.log('Get card: ', card);
            const connection = card.connectedRooms;

            if (connection === undefined || connection[this.room.GUID] === undefined) {
                this.details = {
                    validFrom: moment().toDate(),
                    validTo: moment().add(1, 'days').startOf('day').add(5, 'hour').toDate(),
                    alwaiseValid: this.dataservice.siteConfiguration.cardAlwaiseValid,
                    timeSlot: [],
                    guestName: '',
                    checkoutTime: this.dataservice.defaults.checkoutTime
                };
            } else {
                this.details = connection[this.room.GUID];
            }
            this.currentCard = card;
            this.currentCardsRooms = this.dataservice.fields.filter((x) => card.connectedRooms[x.GUID] !== undefined);
        });
        if (this.card) {
            this.dataservice.setLastReceptionCard(this.card);
        }
    }

    getClean() {
        if (this.room.parameters.FLAGS) {
            return (parseInt(this.room.parameters.FLAGS.value, 16) & 0x40) != 0;
        }
        return false;
    }

    setClean() {
        if (this.getClean()) {
            this.dataservice.sendMessageToServer(this.room, 'CLEAN', 0);
        } else {
            this.dataservice.sendMessageToServer(this.room, 'CLEAN', 1);
        }
    }

    setNotDisturb() {
        if (this.getNotDisturb()) {
            this.dataservice.sendMessageToServer(this.room, 'NOTD', 0);
        } else {
            this.dataservice.sendMessageToServer(this.room, 'NOTD', 1);
        }
    }

    getNotDisturb() {
        if (this.room.parameters.FLAGS) {
            return (parseInt(this.room.parameters.FLAGS.value, 16) & 0x80) != 0;
        }
        return false;
    }

    linkedRoomChanged(event) {
        console.log('Changed: ', event);

        if (event.checked !== undefined) {
            this.linkedRoomEnabled = event.checked;
        }
        if (event.checked) {
            const lroom = this.dataservice.fields.find((x) => x.name == this.linkedRoomName);
            if (lroom) {
                this.linkedRoom = lroom.GUID;
            }
        }
        this.updateLink();
    }
    getGuest(element) {
        const ret = element.connectedRooms[this.room.GUID].guestName;
        return ret;
    }
    getCards() {
        const retval: Array<ICard> = [];

        for (const card of this.dataservice.cards) {
            if (card.connectedRooms[this.room.GUID] !== undefined) {
                retval.push(card);
            }
        }
        return retval;
    }

    openDoor() {
        this.dataservice.sendMessageToServer(this.room, 'DOOR', 'OPEN');
    }

    GetLinkedRoomId() {
        /*
        const name = this.room.name;
        if (name.indexOf('A') !== -1) {
            return name.replace('A', 'B');
        }
        if (name.indexOf('B') !== -1) {
            return name.replace('B', 'A');
        }*/
        console.log(this.room);
        if (this.room.configurations[0]) {
            return this.room.configurations[0];
        }
        return '';
    }

    clearLink() {
      this.linkedRoomEnabled = false;
      this.updateLink();
    }

    updateLink() {
        if (this.linkedRoom !== '' && this.room) {
            const data = {
                action: this.linkedRoomEnabled,
                room1: this.room.GUID,
                room2: this.linkedRoom
            };
            this.dataservice.sendMessageToServer(this.room, 'LINK', data);
        }
    }

    getlinkedRoom(): IPolicy {
        console.log('getLinkedRoom: ', this.room.parameters.LINK.message);

        if (this.room.parameters.LINK) {
            const pol = this.dataservice.fields.find((x) => x.GUID == this.room.parameters.LINK.message);
            if (pol) {
                return pol;
            }
        }
        return this.dataservice.fields[0];
    }

    getlinkedEnabled() {
        if (this.room.parameters.LINK) {
            return this.room.parameters.LINK.message != '';
        }
        return false;
    }

    alarmOff() {
        this.dataservice.sendMessageToServer(this.room, 'ALARM', 0);
    }

    getPowerValue() {
        if (this.room.parameters.FLAGS) {
            const val = (parseInt(this.room.parameters.FLAGS.value, 16) >> 3) & 3;
            return val;
        }
    }

    setPowerValue(val) {
        if (this.room.parameters.FLAGS) {
            this.dataservice.sendMessageToServer(this.room, 'POWER', val);
        }
    }

    getPowerOff() {
        if (this.room.parameters.FLAGS) {
            const val = (parseInt(this.room.parameters.FLAGS.value, 16) >> 2) & 3;
            return val === 1;
        }
    }

    getPowerOn() {
        if (this.room.parameters.FLAGS) {
            const val = (parseInt(this.room.parameters.FLAGS.value, 16) >> 2) & 3;
            return val === 2;
        }
    }

    getPowerAuto() {
        if (this.room.parameters.FLAGS) {
            const val = (parseInt(this.room.parameters.FLAGS.value, 16) >> 2) & 3;
            return val === 0;
        }
    }

    getPowerStat() {
        if (this.room.parameters.FLAGS) {
            const val = (parseInt(this.room.parameters.FLAGS.value, 16) >> 2) & 3;
            return val;
        }
    }

    forcePowerOn() {
        this.dataservice.sendMessageToServer(this.room, 'POWER', 1);
    }

    forcePowerOff() {
        this.dataservice.sendMessageToServer(this.room, 'POWER', 2);
    }

    forcePowerAuto() {
        this.dataservice.sendMessageToServer(this.room, 'POWER', 0);
    }

    sendCameraBind() {
        const message = {
            policy: this.linkedRoom,
            enabled: this.linkedRoomEnabled
        };
        this.dataservice.sendMessageToServer(this.room, 'LINK', message);
    }

    sendMessage(mess: string) {
        //    let message = '';
        /*const Indicator = parseInt(this.room.parameters.Indicator.value, 16);
        switch (mess) {
            case 'NOTDIST':
                // tslint:disable-next-line:no-bitwise
                if ((Indicator & 0xff0000) === 0) {
                    message = '00ff01ff';
                } else {
                    message = '00ff0100';
                }
                break;
            case 'CLEAN':
                // tslint:disable-next-line:no-bitwise
                if ((Indicator & 0xff00) === 0) {
                    message = '00ff03ff';
                } else {
                    message = '00ff0300';
                }
                break;
        }
        this.dataservice.sendmessage(this.room, 'SET', message);
        */
    }

    getRooms() {
        const rooms = this.dataservice.fields.filter((x) => x.type === 'hotel_simple_room');
        return rooms;
    }
    abortCard() {
        this.assignCard = false;
        this.dataservice.lastReceptionCard.code = '00000000';
        if (this.card) {
            this.dialogRef.close();
        }
    }

    getDoor() {
        return undefined !== this.room.parameters.FLAGS && (parseInt(this.room.parameters.FLAGS.value, 16) & 0x20) !== 0;
    }

    getOr(value) {
        if (undefined === this.room.parameters.Indicator) {
            return 0;
        }
        return (
            // tslint:disable-next-line:no-bitwise
            parseInt(this.room.parameters.Indicator.value, 16) & parseInt(value, 16)
        );
    }

    getFlag() {
        if (this.room.parameters.FLAGS) {
            return this.room.parameters.FLAGS.value;
        }
    }

    getIsAlarm() {
        if (!this.dataservice.siteConfiguration.alarmEnabled) {
            return false;
        }

        return this.room.parameters.ALARM && parseInt(this.room.parameters.ALARM.value, 16) != 0;
    }

    getTemp(key: string) {
        if (undefined !== this.room.parameters[key]) {
            const t = parseInt(this.room.parameters[key].value, 16);
            return (t / 10).toFixed(1);
        }
        return 0;
    }

    isInRoom() {
        const card = this.dataservice.cards.find((x) => x.code === this.dataservice.lastReceptionCard.code);
        return card && card.connectedRooms[this.room.GUID] !== undefined;
    }

    isOtherRoom() {}

    getPermission(tag: string) {
        switch (tag) {
            case 'CARD':
                //console.log(this.room);
                return this.room.type === 'hotel_simple_room';
        }
    }

    startAssignCard() {
        this.assignCard = true;
        this.dataservice.lastReceptionCard.code = '00000000';
    }

    confirmCard() {
        this.dataservice.lastReceptionCard.connectedRooms = {};
        if (this.details.validTo.setHours !== undefined) {
            this.details.validTo.setHours(0, 0, 0, 0);
        }

        this.dataservice.lastReceptionCard.connectedRooms[this.room.GUID] = this.details;
        this.dataservice.updateCard(this.dataservice.lastReceptionCard, this.room.GUID, false).subscribe((data) => {
            console.log('OK');
            this.assignCard = false;
            this.dataservice.lastReceptionCard.code = '00000000';
        });
    }
    remove(card: ICard, event) {
        event.stopPropagation();
        this.dataservice.updateCard(card, this.room.GUID, true).subscribe((data) => {
            this.dataservice.toastr.success('Tessera rimossa');
        });
    }

    edit(card: ICard) {
        this.dataservice.setLastReceptionCard(card);
    }

    canUpdate() {
        if (this.dataservice.lastReceptionCard.code === '00000000') {
            this.errorMessage = '';
            return false;
        }
        if (this.dataservice.lastReceptionCard.isPassepartout) {
            this.errorMessage = 'La tessera è un passepartout, non può essere associata alla stanza';

            return false;
        }
        /*
        if (this.dataservice.lastReceptionCard.connectedRooms !== undefined && this.dataservice.lastReceptionCard.connectedRooms[this.room.GUID] !== undefined) {
            this.errorMessage = 'La tessera è già associata alla stanza';
            return false;
        }*/
        this.errorMessage = '';
        return true; // !this.isInRoom();
    }

    async addCode() {

        const newcode=await this.dataservice.getNewCode();
        this.dataservice.lastReceptionCard = {} as ICard;
        this.dataservice.lastReceptionCard.code = newcode.code;
        this.dataservice.lastReceptionCard.isPin = true;
        this.dataservice.lastReceptionCard.connectedRooms = {};
        this.dataservice.receptionchanged.emit(this.dataservice.lastReceptionCard);
    }

    getPinLabel(cart: ICard) {
        if (cart) {
            if (cart.isPin) {
                return 'Codice:';
            }
            return 'Tessera:';
        }
        return 'Tessera/Codice:';
    }
}
