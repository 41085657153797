<div>
    <div><div class="citem">Componenti</div></div>

    <div class="citem">
        <mat-label>RFID Esterno</mat-label>
        <mat-slide-toggle slot="start" [(ngModel)]="config.extr"></mat-slide-toggle>
    </div>
    <div class="citem">
        <mat-label>RFID Interno</mat-label>
        <mat-slide-toggle slot="start" [(ngModel)]="config.intr"></mat-slide-toggle>
    </div>
    <div class="citem">
        <mat-label>Tastiera Wiegand</mat-label>
        <mat-slide-toggle slot="start" [(ngModel)]="config.keyb"></mat-slide-toggle>
    </div>
    <div class="cblock">
        <div class="citem">Ingressi</div>
        <div class="combocont">
            <app-functioncombo label="In 1" [(value)]="config.din[0]" [items]="dataservice.getActions()"></app-functioncombo>
            <app-functioncombo label="In 2" [(value)]="config.din[1]" [items]="dataservice.getActions()"></app-functioncombo>
            <app-functioncombo label="In 3" [(value)]="config.din[2]" [items]="dataservice.getActions()"></app-functioncombo>
            <app-functioncombo label="In 4" [(value)]="config.din[3]" [items]="dataservice.getActions()"></app-functioncombo>
        </div>
    </div>

    <div class="cblock">
        <mat-label>Espansione Ingressi</mat-label>
        <mat-slide-toggle slot="start" [(ngModel)]="config.espin"></mat-slide-toggle>

        <div *ngIf="config.espin" class="combocont">
            <app-functioncombo label="In 1" [(value)]="config.din[8]" [items]="dataservice.getActions()"></app-functioncombo>
            <app-functioncombo label="In 2" [(value)]="config.din[9]" [items]="dataservice.getActions()"></app-functioncombo>
            <app-functioncombo label="In 3" [(value)]="config.din[10]" [items]="dataservice.getActions()"></app-functioncombo>
            <app-functioncombo label="In 4" [(value)]="config.din[11]" [items]="dataservice.getActions()"></app-functioncombo>
            <app-functioncombo label="In 5" [(value)]="config.din[12]" [items]="dataservice.getActions()"></app-functioncombo>
            <app-functioncombo label="In 6" [(value)]="config.din[13]" [items]="dataservice.getActions()"></app-functioncombo>
            <app-functioncombo label="In 7" [(value)]="config.din[14]" [items]="dataservice.getActions()"></app-functioncombo>
            <app-functioncombo label="In 8" [(value)]="config.din[16]" [items]="dataservice.getActions()"></app-functioncombo>
        </div>
    </div>

    <div class="cblock">
        <div class="citem">Uscite</div>
        <div class="combocont">
            <app-functioncombo label="Out 1" [(value)]="config.dout[0]" [items]="dataservice.getActions()"></app-functioncombo>
            <app-functioncombo label="Out 2" [(value)]="config.dout[1]" [items]="dataservice.getActions()"></app-functioncombo>
            <app-functioncombo label="Out 3" [(value)]="config.dout[2]" [items]="dataservice.getActions()"></app-functioncombo>
            <app-functioncombo label="Out 4" [(value)]="config.dout[3]" [items]="dataservice.getActions()"></app-functioncombo>
        </div>
    </div>
    <div class="cblock">
        <mat-label>Espansione Uscite</mat-label>
        <mat-slide-toggle slot="start" [(ngModel)]="config.espout"></mat-slide-toggle>

        <div *ngIf="config.espout" class="combocont">
            <app-functioncombo label="Out 1" [(value)]="config.dout[8]" [items]="dataservice.getActions()"></app-functioncombo>
            <app-functioncombo label="Out 2" [(value)]="config.dout[9]" [items]="dataservice.getActions()"></app-functioncombo>
            <app-functioncombo label="Out 3" [(value)]="config.dout[10]" [items]="dataservice.getActions()"></app-functioncombo>
            <app-functioncombo label="Out 4" [(value)]="config.dout[11]" [items]="dataservice.getActions()"></app-functioncombo>
            <app-functioncombo label="Out 5" [(value)]="config.dout[12]" [items]="dataservice.getActions()"></app-functioncombo>
            <app-functioncombo label="Out 6" [(value)]="config.dout[13]" [items]="dataservice.getActions()"></app-functioncombo>
            <app-functioncombo label="Out 7" [(value)]="config.dout[14]" [items]="dataservice.getActions()"></app-functioncombo>
            <app-functioncombo label="Out 8" [(value)]="config.dout[15]" [items]="dataservice.getActions()"></app-functioncombo>
        </div>
    </div>

    <button mat-raised-button (click)="saveCustom()"><mat-icon>save</mat-icon>Salva</button>
    <button mat-raised-button (click)="saveCustomAll()" color="warn"><mat-icon>save</mat-icon>Salva a tutti</button>
</div>
