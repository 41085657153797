import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IPolicy, ISceneryConfig } from 'src/interfaces';
import { DataserviceService } from '../dataservice.service';

@Component({
    selector: 'app-sceneryscheduler',
    templateUrl: './sceneryscheduler.component.html',
    styleUrls: ['./sceneryscheduler.component.scss']
})
export class SceneryschedulerComponent implements OnInit {
    scheduleMode = 0;
    days = '';
    timescheduler = '12:00';
    offset = 0;
    scenery: ISceneryConfig;
    constructor(public dataservice: DataserviceService, public dialogRef: MatDialogRef<SceneryschedulerComponent>, @Inject(MAT_DIALOG_DATA) public data: { scenery: ISceneryConfig }) {
        this.scenery = data.scenery;
        this.offset = new Date().getTimezoneOffset();
    }

    getDay(day: string) {
        return this.days.indexOf(day) !== -1;
    }

    toggleDay(day: string) {
        if (undefined === this.days) {
            this.days = '';
        }
        if (this.days.indexOf(day) === -1) {
            this.days += day;
        } else {
            this.days = this.days.replace(day, '');
        }
        console.log('Day: ', this.days);
    }

    sendNow() {
        this.dataservice.sendmessage({ GUID: this.scenery.guid } as IPolicy, 'SET', 'ff');
        this.dialogRef.close();
    }
    ngOnInit(): void {
        this.timescheduler = this.scenery.time;
        if (undefined === this.scenery.time) {
            this.scenery.time = '12:00';
        }
        this.days = this.scenery.days;
        if (undefined === this.days) {
            this.days = '';
        }
        this.scheduleMode = this.scenery.mode;
        console.log(' - - - - - - - - - ', this.timescheduler);
    }
    close() {
        this.dialogRef.close();
    }

    update() {
        this.scenery.time = this.timescheduler;
        this.scenery.mode = this.scheduleMode;
        this.scenery.days = this.days;
        console.log('Update');
        this.dataservice.updateScenery(this.scenery);
    }

    schedulerData(data) {
        return this.scheduleMode === data;
    }

    schedulerSet(data) {
        if (this.scheduleMode === data) {
            this.scheduleMode = 0;
        } else {
            this.scheduleMode = data;
        }
    }

    timeChanged(event) {
        this.timescheduler = event + '_' + this.offset.toString();
    }
}
