import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-confirmdialog',
  templateUrl: './confirmdialog.component.html',
  styleUrls: ['./confirmdialog.component.scss']
})
export class ConfirmdialogComponent implements OnInit {

  text: string;

  constructor(public dialogRef: MatDialogRef<ConfirmdialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (this.data.buttons === undefined) {
      this.data.buttons = ['OK', 'Annulla'];
    }
  }

  ngOnInit() {
  }
  btclick(text: string) {
    this.dialogRef.close(this.data.buttons.indexOf(text));
  }
}
