import { Component, OnInit, Inject } from '@angular/core';
import { DataserviceService } from '../dataservice.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IParameterParamType, IMeshDevice } from 'src/interfaces';

@Component({
    selector: 'app-adddevice',
    templateUrl: './adddevice.component.html',
    styleUrls: ['./adddevice.component.scss']
})
export class AdddeviceComponent implements OnInit {
    device: IMeshDevice;
    convhex = false;
    constructor(public dataservice: DataserviceService, public dialogRef: MatDialogRef<AdddeviceComponent>) {
        // this.policyType = this.dataservice.getPolycySpec(data);
    }

    ngOnInit() {
        this.device = {} as IMeshDevice;
        this.device.root = this.dataservice.cpus[0];
        this.device.device_id = 65535;
        this.device.mac = '000000000000';
        this.device.communicationType = 0;
        this.device.deviceType = 180;
        this.device.name = '';
        this.device.parent = '';
    }

    addDevice() {
        if (typeof this.device.device_id === 'string') {
            if (this.convhex) {
                this.device.device_id = parseInt(this.device.device_id, 16);
            } else {
                this.device.device_id = parseInt(this.device.device_id, 10);
            }
        }
        this.dataservice.addMeshDevice(this.device);
    }

    cancel() {
        this.dialogRef.close();
    }
}
