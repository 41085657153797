import { Component } from '@angular/core';

@Component({
  selector: 'app-btconfig',
  templateUrl: './btconfig.component.html',
  styleUrls: ['./btconfig.component.scss']
})
export class BtconfigComponent {

}
