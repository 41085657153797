import { SiteconfigComponent } from './siteconfig/siteconfig.component';
import { ILogicalElement, ISiteConfiguration, IScanData, IImportCard, ICode } from './../interfaces';
import { Injectable, OnInit, EventEmitter, Output, Directive } from '@angular/core';
import { webSocket } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';
import { IPolicy, IUpdateMessage, ICard, IdebacaUser, IArea, IMeshDevice, IPolicyItem, IdebacaFieldCommand, IUpgradeMessages, IFirmwareItem, IOtaUpdate, IPolicySpecification, IParameterSpecification, ICpuinfo, IInputUpdateItem, ISceneryConfig, IUserProfile, IParameter, ISceneryConfigAction, IServerMessage } from 'src/interfaces';
import { AlarmwinComponent } from './alarmwin/alarmwin.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import moment from 'moment';
import { ConfirmdialogComponent } from './confirmdialog/confirmdialog.component';
import deviceDefs from './policies_devices.json';
import { firstValueFrom, interval } from 'rxjs';
import { timeout } from 'rxjs/operators';

@Directive()
@Injectable({
    providedIn: 'root'
})
// tslint:disable-next-line:directive-class-suffix
export class DataserviceService {
    connected = false;
    public systems: Array<IPolicyItem> = [];
    socket: any;
    fields: IPolicy[];
    sceneries: ISceneryConfig[] = [];
    splittedFields: { [id: string]: Array<IPolicy | ISceneryConfig> };
    areas: Array<IArea> = [];
    cards: Array<ICard> = [];
    token: string;
    allowedRooms = ['hotel_full_room', 'hotel_simple_room', 'hotel_common'];
    lastReceptionCard: ICard = {} as ICard;
    public currentUser: IdebacaUser;
    userLoggedIn: boolean;
    onCardCnanged: CustomEvent;
    @Output() onScanChanged = new EventEmitter<IScanData>();
    meshDevces: Array<IMeshDevice> = [];
    coordinator: IMeshDevice = {} as IMeshDevice;
    firmwares: IFirmwareItem[] = [];
    deviceOnline = 0;
    deviceOffline = 0;
    deviceDeleted = 0;
    policiesDefs: Array<IPolicySpecification>;
    deviceDefs: Array<IParameterSpecification> = [];
    commTypes = ['wired rs485', 'Mesh network', 'WiFi Lan'];
    random: number;
    currentRoot: string;
    cpus: string[] = [];
    alerts: IServerMessage[] = [];
    baseUrl = '/api/';
    siteConfiguration: ISiteConfiguration;
    public deviceFilter: IMeshDevice = undefined;
    logicalElements: ILogicalElement[] = [];
    // alarmEnabled = true;
    @Output() receptionchanged = new EventEmitter<ICard>();
    @Output() deviceInputUpdate = new EventEmitter<IInputUpdateItem>();
    @Output() doorUpdate = new EventEmitter<string>();

    summary = {
        notdisturb: 0,
        clean: 0,
        alarm: 0
    };

    fieldUpdate: ((param: string) => void)[] = [];
    otaprogress: number;
    public defaults = {
        checkoutTime: '11:00'
    };
    constructor(private http: HttpClient, public dialog: MatDialog, private router: Router, public toastr: ToastrService) {
        console.log('bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb');

        this.deviceDefs = deviceDefs as unknown as Array<IParameterSpecification>;

        //  if (environment.production) {
        const getUrl = window.location;
        this.socket = webSocket(`wss://` + getUrl.host + '/ws');
        // } else {
        //    this.socket = webSocket(`ws://${environment.serviceWebSockets}`);
        // }
        const pst = window.localStorage.getItem('polspec');

        const token = localStorage.getItem('token');
        this.onCardCnanged = new CustomEvent('cards_updated', { detail: 3 });
        this.getfirmwares();
        if (token) {
            this.token = token;
            this.whoAmI();
        }
        this.getSiteConfig();
        this.getPoliciesDef().subscribe((data) => {
            window.localStorage.setItem('polspec', JSON.stringify(data));
            this.policiesDefs = data as IPolicySpecification[];
            console.log('policiesdefs', data);
        });
        this.getFieldConfig(true).subscribe(
            (data) => {
                // this.dataservice.toastr.success('Configurazione caricata');
                this.setSystem((data as any).policies as Array<IPolicyItem>);
                this.sceneries = (data as any).sceneries as ISceneryConfig[];
                if (undefined === this.sceneries) {
                    this.sceneries = [];
                }
                console.log('System: ', this.systems);
            },
            (error) => {
                this.toastr.warning('Nessuna configurazione presente sul server');
            }
        );
    }

    getLinearColor(value) {
        // value from 0 to 1
        const hue = ((1 - value) * 120).toString(10);
        return ['hsl(', hue, ',100%,50%)'].join('');
    }

    FromFloatSafe(fb: number): number {
        /* tslint:disable:no-bitwise */
        const sign = (fb >> 31) & 1;
        let exponent = (fb >> 23) & 0xff;
        const mantissa = fb & 0x7fffff;

        let fMantissa;
        const fSign = sign === 0 ? 1.0 : -1.0;

        if (exponent !== 0) {
            exponent -= 127;
            fMantissa = 1.0 + mantissa / 0x800000;
        } else {
            if (mantissa !== 0) {
                // denormal
                exponent -= 126;
                fMantissa = 1.0 / 0x800000;
            } else {
                // +0 and -0 cases
                fMantissa = 0;
            }
        }

        const fExponent = Math.pow(2.0, exponent);
        const ret = fSign * fMantissa * fExponent;
        return ret;
        /* tslint:enable:no-bitwise */
    }

    addMeshDevice(device: IMeshDevice) {
        this.http.post(this.baseUrl + 'configuration/device', device, this.getHeader()).subscribe(
            (retval) => {
                //  this.meshDevces = retval as Array<IMeshDevice>;
                this.toastr.success('Dispositivo aggiunto');
            },
            (error) => {
                this.toastr.error('impossibile aggiungere il dispositivo');
            }
        );
    }

    async getNewCode(): Promise<ICode> {
        return await firstValueFrom(this.http.get<ICode>(this.baseUrl + 'card/code', this.getHeader()));
    }

    getDeviceCustomConfig(device: IMeshDevice, reg: number, dlen: number) {
        return this.http.get(this.baseUrl + 'device/getcustdata/' + device.root + '/' + device.device_id + '/' + reg + '/' + dlen, this.getHeader());
    }

    getDeviceCustomConfigMultiReg(device: IMeshDevice, regs: number[], dlen: number) {
        return this.http.post(this.baseUrl + 'device/getcustdata/' + device.root + '/' + device.device_id + '/' + dlen, regs, this.getHeader());
    }

    setDeviceCustomConfig(device: IMeshDevice, reg, data: number[]) {
        return this.http.post(this.baseUrl + 'device/sendcustdata/' + device.root + '/' + device.device_id + '/' + reg, data, this.getHeader());
    }

    setDeviceCustomConfigMultiple(device: IMeshDevice, reg, data: number[]) {
        return this.http.post(this.baseUrl + 'device/sendcustdatas/' + device.root + '/' + device.deviceType + '/' + reg, data, this.getHeader());
    }

    updateMeshDevice(device: IMeshDevice) {
        this.http.put(this.baseUrl + 'configuration/device', device, this.getHeader()).subscribe(
            (retval) => {
                // this.meshDevces = retval as Array<IMeshDevice>;
                this.toastr.success('Dispositivo aggiornato');
            },
            (error) => {
                this.toastr.error('impossibile aggiornare il dispositivo');
            }
        );
    }

    updateCardData(card: ICard) {
        this.http.put(this.baseUrl + 'card', card, this.getHeader()).subscribe(() => {
            this.toastr.success('Aggiornamento eseguito');
            this.updateCards();
        });
    }

    importCards(data: IImportCard[], invert: boolean) {
        this.http.post(this.baseUrl + 'card/import/' + (invert ? '1' : '0'), data, this.getHeader()).subscribe(
            () => {
                this.toastr.success('Operazione eseguita');
            },
            () => {
                this.toastr.error("Errore durante l'operazione");
            }
        );
    }

    updateCards() {
        this.http.get(this.baseUrl + 'card/all', this.getHeader()).subscribe((success) => {
            this.cards = success as Array<ICard>;
            if (this.fields) {
                for (const policy of this.fields) {
                    const cards = this.cards.filter((x) => x.connectedRooms[policy.GUID] !== undefined);
                    policy.haveCard = cards.length > 0;
                }
            }
            // console.log('cards: ', this.cards);
        });
    }

    deleteCard(card: string) {
        return this.http.delete(this.baseUrl + 'card/' + card, this.getHeader());
    }

    setDeviceId(oldId: number, newId: number, cpu: string) {
        this.http.get(this.baseUrl + 'device/setid/' + cpu + '/' + oldId + '/' + newId, this.getHeader()).subscribe(
            () => {
                this.toastr.success('Eseguito');
            },
            () => {
                this.toastr.error('Errore');
            }
        );
    }

    updateField() {
        const postdata = {
            areas: this.areas,
            fields: this.fields,
            sceneries: this.sceneries
        };
        this.http.post(this.baseUrl + 'field', postdata, this.getHeader()).subscribe(
            (data) => {
                console.log('aggiornamento field eseguito');
            },
            (error) => {
                // tslint:disable-next-line:quotemark
                this.toastr.error("Error durante l'aggiornamento");
            }
        );
    }

    startWebsocket() {
        if (!this.connected) {
            console.log('Start webSocket');
            this.socket.subscribe(
                (message) => {
                    this.connected = true;
                    this.decodeSchenery(message);
                    this.decodeCards(message);
                    this.decodeRows(message);
                    this.decodeUpdates(message);
                    this.decodeDevice(message);
                    this.decodeOtaUpdate(message);
                    this.decodeUpdateInput(message);
                    this.decodeAlerts(message);
                    this.decodeScanProgress(message);
                    // console.log(message);
                    for (const fn of this.fieldUpdate) {
                        fn('update');
                    }
                },
                (error) => {
                    console.error(error); // handle errors
                    this.connected = false;
                }
            );
        } else {
            console.log('websocket is connected');
        }
    }

    strToBuffer(str) {
        const arrayBuffer = new ArrayBuffer(str.length * 1);
        const newUint = new Uint8Array(arrayBuffer);
        newUint.forEach((_, i) => {
            newUint[i] = str.charCodeAt(i);
        });
        return newUint;
    }

    sendmessage(policy: IPolicy, action: string, value: string) {
        /*
        const message = 'send?index=' + policy.index.toString() + '&value=' + value;
        this.socket.subscribe();
        this.socket.next(message);
        console.log({ message: 'Message: ' + message + ' SENT' });*/
        const message: IdebacaFieldCommand = {
            guid: policy.GUID,
            action: 'SET',
            value: parseInt(value, 16)
        };
        this.http.post(this.baseUrl + 'field/command', message, this.getHeader()).subscribe(
            (mess) => {
                this.toastr.success('Comando inviato');
            },
            (error) => {
                this.toastr.error('Errore invio del comando');
            }
        );
    }

    sendMessageToServer(policy: IPolicy, action: string, content: any) {
        /*
        const message = 'send?index=' + policy.index.toString() + '&value=' + value;
        this.socket.subscribe();
        this.socket.next(message);
        console.log({ message: 'Message: ' + message + ' SENT' });*/
        const message = {
            guid: policy.GUID,
            action,
            data: content
        };
        this.http.post(this.baseUrl + 'field/command', message, this.getHeader()).subscribe(
            (mess) => {
                this.toastr.success('Comando inviato');
            },
            (error) => {
                this.toastr.error('Errore invio del comando');
            }
        );
    }

    findCard(code: string) {
        for (const card of this.cards) {
            if (card.code === code) {
                return card;
            }
        }
    }
    findArea(id: string): IArea {
        for (const area of this.areas) {
            if (area.id === id) {
                return area;
            }
        }
        return undefined;
    }

    findAreaByName(name: string): IArea {
        for (const area of this.areas) {
            if (area.name === name) {
                return area;
            }
        }
        return undefined;
    }

    getCardByCode(code: string): ICard {
        const card = this.cards.filter((x) => x.code === code);
        if (card.length > 0) {
            return card[0];
        } else {
            return null;
        }
    }

    decodeAlerts(message: any) {
        if (message.alerts) {
            this.alerts = message.alerts as IServerMessage[];
        }
    }

    decodeScanProgress(message: any) {
        if (message.scan) {
            this.onScanChanged.emit(message.scan);
        }
    }

    decodeUpdates(message: any) {
        if (message.updates) {
            const updates = message.updates as Array<IUpdateMessage>;
            for (const update of updates) {
                if (this.fields) {
                    for (const policy of this.fields) {
                        if (policy.GUID === update.GUID) {
                            if (this.siteConfiguration.alarmEnabled && update.action === 'ALARM' && update.value !== '00000000' && policy.parameters[update.action].value !== update.value) {
                                this.openAlarm(policy);
                            }
                            if (policy.parameters[update.action]) {
                                policy.parameters[update.action].value = update.value;
                                policy.parameters[update.action].message = update.message;
                            } else {
                                policy.parameters[update.action] = {} as IParameter;
                                policy.parameters[update.action].value = update.value;
                                policy.parameters[update.action].message = update.message;
                                policy.parameters[update.action].name = update.action;
                            }

                            if (policy.type === 'doorphone') {
                                this.doorUpdate.emit(update.value);
                            }
                            if (policy.type === 'hotel_reception') {
                                if (update.action === 'CODE') {
                                    if (update.value !== '00000000') {
                                        const card = this.cards.filter((x) => x.code === update.value);
                                        if (card.length > 0) {
                                            this.lastReceptionCard = { ...{}, ...card[0] };
                                        } else {
                                            this.lastReceptionCard = {} as ICard;
                                            this.lastReceptionCard.code = update.value;
                                            this.lastReceptionCard.connectedRooms = {};
                                            /*   this.lastReceptionCard.checkoutTime = '23:59';
                                        this.lastReceptionCard.validTo = moment()
                                            .add(1, 'days')
                                            .startOf('day')
                                            .add(5, 'hour')
                                            .toDate();*/
                                        }
                                        this.lastReceptionCard.isPin = false;
                                        this.receptionchanged.emit(this.lastReceptionCard);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            this.calcSummary();
        }
    }

    decodeUpdateInput(message: any) {
        if (message.updatein) {
            const update = message.updatein as IInputUpdateItem;
            this.deviceInputUpdate.emit(update);
        }
    }

    setLastReceptionCard(card: ICard) {
        this.lastReceptionCard = { ...{}, ...card };
        this.receptionchanged.emit(this.lastReceptionCard);
    }

    calcSummary() {
        this.summary.alarm = 0;
        this.summary.clean = 0;
        this.summary.notdisturb = 0;
        for (const field of this.fields) {
            if (field.parameters.ALARM) {
                if (field.parameters.ALARM.value !== '00000000') {
                    this.summary.alarm++;
                }
            }
            if (field.parameters.FLAGS) {
                // tslint:disable-next-line:no-bitwise
                const indval = parseInt(field.parameters.FLAGS.value, 16) & 0xc0;
                // tslint:disable-next-line:no-bitwise
                if (indval & 0x80) {
                    this.summary.notdisturb++;
                }
                // tslint:disable-next-line:no-bitwise
                if (indval & 0x40) {
                    this.summary.clean++;
                }
            }
        }
    }

    decodeCards(message: any) {
        if (message.cards) {
            this.cards = message.cards;
            for (const policy of this.fields) {
                const cards = this.cards.filter((x) => x.connectedRooms[policy.GUID] !== undefined);
                policy.haveCard = cards.length > 0;
            }
        }
    }

    decodeSchenery(message: any) {
        if (message.scenery) {
            console.log('Update scenery');

            const scen = this.sceneries.find((x) => x.guid === message.scenery.guid);
            if (scen) {
                scen.mode = message.scenery.mode;
            }
        }
    }

    decodeDevice(message: any) {
        if (message.device) {
            const dev = message.device as IMeshDevice;
            if (!this.cpus.includes(dev.root)) {
                this.cpus.push(dev.root);
            }
            let ckdev: IMeshDevice;

            const ckdevs = this.meshDevces.filter((x) => x.device_id === dev.device_id && x.root === dev.root);
            if (dev.deleted && ckdevs.length > 0) {
                const nidx = this.meshDevces.indexOf(ckdevs[0]);
                this.meshDevces.splice(nidx, 1);
            } else {
                if (ckdevs.length === 1) {
                    ckdev = ckdevs[0];
                } else if (ckdevs.length > 1) {
                    console.warn('Duplicate device', ckdevs);
                    for (let i = ckdevs.length - 1; i > 0; i--) {
                        this.meshDevces.splice(this.meshDevces.indexOf(ckdevs[i]), 1);
                    }
                    ckdev = ckdevs[0];
                }

                if (dev.deleted) {
                    this.meshDevces.splice(this.meshDevces.indexOf(ckdev), 1);
                } else {
                    if (ckdev) {
                        ckdev.isOnline = dev.isOnline;
                        ckdev.parent = dev.parent;
                        ckdev.fwVersion = dev.fwVersion;
                        ckdev.lastUpdate = dev.lastUpdate;
                        ckdev.mem8 = dev.mem8;
                        ckdev.status = dev.status;
                        ckdev.signal = dev.signal;
                        ckdev.root = dev.root;
                        ckdev.mac = dev.mac;
                        ckdev.serial_number = dev.serial_number;
                        ckdev.uptime = dev.uptime;
                        ckdev.legacy = dev.legacy;
                        ckdev.deviceType = dev.deviceType;
                        ckdev.communicationType = dev.communicationType;
                        ckdev.ssid = dev.ssid;
                        ckdev.pass = dev.pass;
                    } else {
                        this.meshDevces.push(dev);
                    }
                }
                this.deviceDeleted = 0;
                this.deviceOffline = 0;
                this.deviceOnline = 0;
                for (const device of this.meshDevces) {
                    if (device.deleted) {
                        this.deviceDeleted++;
                    }
                    if (device.isOnline) {
                        this.deviceOnline++;
                    } else {
                        this.deviceOffline++;
                    }
                }
            }
        }
    }

    decodeOtaUpdate(message: any) {
        if (message.otaupdate) {
            const dev = message.otaupdate as IOtaUpdate;
            this.otaprogress = dev.progress;
            console.log('Ota: ', dev);
        }
    }

    decodeRows(message: any) {
        if (message.rows) {
            this.fields = (message as any).rows;
            this.areas = (message as any).areas;
            if (this.cards) {
                for (const policy of this.fields) {
                    const cards = this.cards.filter((x) => x.connectedRooms[policy.GUID] !== undefined);
                    policy.haveCard = cards.length > 0;
                }
            }
            /*    let i = 0;
            for (const anyarea of this.fields) {
                const area = anyarea as IPolicy;
                i++;
                if (area.area !== '') {
                    if (!this.findArea(area.area)) {
                        const newarea = {
                            id: this.newGuid(),
                            name: area.area,
                            order: this.areas.length
                        };
                        this.areas.push(newarea);
                    }
                }
            }
            if (this.areas.length === 0) {
                this.areas.push({
                    id: '0000000000',
                    name: 'Area 51',
                    order: 0
                });
            }
            for (const anyarea of this.fields) {
                const area = anyarea as IPolicy;
                if (area.area === '') {
                    area.area = this.areas[0].id;
                }
            }*/
            this.splittedFields = {};
            for (const area of this.areas) {
                this.splittedFields[area.id] = this.getFields(area.id);
                this.splittedFields[area.id].sort((a, b) => {
                    return a.order - b.order;
                });
            }
            this.calcSummary();
        }
    }

    newGuid() {
        return 'xxxxxxxx-xxxx-'.replace(/[xy]/g, (c) => {
            // tslint:disable-next-line:no-bitwise
            const r = (Math.random() * 16) | 0;
            // tslint:disable-next-line:no-bitwise
            const v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    getFields(area: string): Array<IPolicy | ISceneryConfig> {
        console.log('Getfields');
        const retval: Array<IPolicy | ISceneryConfig> = [];

        if (this.sceneries) {
            for (const scenery of this.sceneries) {
                const fArea = this.areas.find((x) => x.id === scenery.area);
                if (fArea == null || fArea === undefined) {
                    scenery.area = this.areas[0].id;
                }
            }

            for (const scenery of this.sceneries) {
                if (scenery.area === area) {
                    //  if (this.allowedRooms.includes(scenery.type)) {
                    retval.push(scenery);
                    //  }
                }
            }
        }

        if (this.fields) {
            for (const field of this.fields) {
                const fArea = this.areas.find((x) => x.id === field.area);
                if (fArea == null || fArea === undefined) {
                    field.area = this.areas[0].id;
                }
            }
            for (const field of this.fields) {
                if (field.area === area) {
                    //  if (this.allowedRooms.includes(field.type)) {
                    retval.push(field);
                    //  }
                }
            }
        }
        return retval;
    }

    openAlarm(policy: IPolicy) {
        if (!policy.alerted) {
            policy.alerted = true;

            console.log('Open alarm');
            const dialogRef = this.dialog.open(AlarmwinComponent, {
                width: '400px',
                data: policy
            });

            dialogRef.afterClosed().subscribe((result) => {
                console.log('The dialog was closed');
                policy.alerted = false;
            });
        }
    }

    updateCard(card: ICard, policy: string, del: boolean) {
        const data = {
            policy,
            card
        };
        console.log('Update: ', card.code, del);
        if (del) {
            return this.http.post(this.baseUrl + 'card/delete', data, this.getHeader());
        } else {
            return this.http.post(this.baseUrl + 'card/add', data, this.getHeader());
        }
    }

    getHeader() {
        if (this.token) {
            const token = this.token;

            const headersObject = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            });

            const httpOptions = {
                headers: headersObject
            };
            return httpOptions;
        } else {
            return {};
        }
    }

    getTextHeader() {
        if (this.token) {
            const token = this.token;

            const headersObject = new HttpHeaders({
                'Content-Type': 'text/plain',
                Authorization: 'Bearer ' + token
            });

            const httpOptions = {
                headers: headersObject,
                responseType: 'text'
            };
            return httpOptions;
        } else {
            return {};
        }
    }

    addUser(selectedUser: IdebacaUser) {
        return this.http.post(this.baseUrl + 'users', selectedUser, this.getHeader());
    }

    deleteUser(selectedUser: IdebacaUser) {
        return this.http.get(this.baseUrl + 'users/' + selectedUser.idebacaUserId, this.getHeader());
    }

    login(user: IdebacaUser) {
        this.http.post(this.baseUrl + 'users/login', user).subscribe(
            (result) => {
                console.log('result: ', result);
                this.token = (result as any).token;
                localStorage.setItem('token', this.token);
                this.whoAmI();

                this.router.navigate(['/']);
            },
            (error) => {
                console.error(error);
                this.currentUser = null;
                this.userLoggedIn = false;
                this.toastr.error('error', 'Login error');
            }
        );
    }

    logout() {
        this.token = undefined;
        localStorage.setItem('token', this.token);
        this.router.navigate(['/login']);
        this.currentUser = undefined;
    }

    whoAmI() {
        this.http.get(this.baseUrl + 'users/whoami', this.getHeader()).subscribe(
            (user) => {
                this.currentUser = user as IdebacaUser;
                this.toastr.success('Login', 'Benvenuto ' + this.currentUser.fullname);
                this.updateCards();
                this.getMeshDevices();
                const seconds = interval(5000);
                this.startWebsocket();
                //  this.alarmEnabled = localStorage.getItem('alarm') == 'on';
                seconds.pipe(timeout(5500)).subscribe((value) => {
                    this.startWebsocket();
                });
            },
            (error) => {
                this.router.navigate(['/login']);
            }
        );
    }

    getCustomLog(polId: number) {
        return this.http.get(this.baseUrl + 'field/customdata/' + polId, this.getHeader());
    }

    getCards(id: string) {
        return this.http.get(this.baseUrl + 'card/ofroom/' + id, this.getHeader());
    }

    getPermission(logged: boolean, role: number) {
        if (logged === true) {
            if (this.currentUser === undefined || this.currentUser === null) {
                return false;
            }

            return this.currentUser.role >= role;
        }
        if (this.currentUser === undefined) {
            return true;
        }
        return false;
    }

    getAuth(logged: boolean, role: number) {
        if (!this.getPermission(logged, role)) {
            return { display: 'none' };
        }
        return {};
    }

    testLogged() {
        if (undefined === this.currentUser && this.router.url !== '/login') {
            this.router.navigate(['/login']);
        }
    }

    getRoom(id: string) {
        if (this.fields) {
            for (const policy of this.fields) {
                if (policy.GUID === id) {
                    return policy;
                }
            }
        }
        return undefined;
    }

    getUsers() {
        return this.http.get(this.baseUrl + 'users', this.getHeader());
    }

    updateProfile(profile: IUserProfile) {
        this.http.post(this.baseUrl + 'users/profile', profile, this.getHeader()).subscribe(
            () => {
                this.toastr.success('Aggiornamento utente eseguito');
            },
            () => {
                this.toastr.error('Errore durante aggiornamento utente');
            }
        );
    }

    getRole(role: number): string {
        switch (role) {
            case 0:
                return 'Guest';
            case 1:
                return 'User';
            case 2:
                return 'Administrator';
            case 3:
                return 'System administrator';
        }
    }

    makePassepartout(currentCard: ICard, arg1: boolean) {
        if (arg1) {
            return this.http.post(this.baseUrl + 'card/passepartouts/add', currentCard, this.getHeader());
        } else {
            return this.http.post(this.baseUrl + 'card/passepartouts/remove', currentCard, this.getHeader());
        }
    }

    getMeshDevices() {
        this.http.get(this.baseUrl + 'device/mesh', this.getHeader()).subscribe((data) => {
            this.meshDevces = data as Array<IMeshDevice>;
            // console.log('Mesh: ', this.meshDevces);
            for (const device of this.meshDevces) {
                if (!this.cpus.includes(device.root)) {
                    this.cpus.push(device.root);
                }
            }
            this.getMasterDevice();
        });
    }

    deleteDevice(mac: string) {
        this.http.delete(this.baseUrl + 'device/delete/' + mac, this.getHeader()).subscribe((data) => {
            this.getMeshDevices();
        });
    }

    getMasterDevice() {
        for (const dev of this.meshDevces) {
            if (dev.deviceType === 100) {
                this.coordinator = dev;
                return;
            }
        }
    }

    deleteCpu(cpu: string) {
        return this.http.delete(this.baseUrl + 'configuration/cpu/' + cpu, this.getHeader());
    }

    saveFieldConfig(update: boolean) {
        for (let i = 0; i < this.systems.length; i++) {
            if (this.systems[i].polType === 110) {
                const elem = this.systems[i];
                this.systems.splice(i, 1);
                this.systems.unshift(elem);
                break;
            }
        }
        const payloads = {
            policies: this.systems,
            sceneries: this.sceneries
        };
        this.http.post(this.baseUrl + 'configuration', payloads, this.getHeader()).subscribe(
            (data) => {
                this.toastr.success('Salvataggio effettuato correttamente');
            },
            (error) => {
                // tslint:disable-next-line:quotemark
                this.toastr.error("Errore durante il salvataggio dell'impianto");
            }
        );
    }

    getFieldConfig(type: boolean) {
        return this.http.get(this.baseUrl + 'configuration', this.getHeader());
    }

    updateSystemConfg(action: boolean) {
        this.http.get(this.baseUrl + 'configuration/update/' + (action ? '1' : '0'), this.getHeader()).subscribe(
            (x) => {
                this.toastr.success('Configurazione aggiornata');
            },
            (x) => {
                this.toastr.error('Errore nella configurazione del sistema');
            }
        );
    }

    upgradeFirmware(toupdate: IUpgradeMessages) {
        this.http.post(this.baseUrl + 'device/upgrade', toupdate, this.getHeader()).subscribe((data) => {
            this.toastr.success('Avvio aggiornamento firmware');
        });
    }

    blink(deviceId: number) {
        this.http.get(this.baseUrl + 'device/blink/' + deviceId).subscribe((data) => {
            console.log('Blink device ', deviceId);
        });
    }

    enableConfig(deviceId: number) {
        this.http.get(this.baseUrl + 'device/goconf/' + deviceId).subscribe((data) => {
            console.log('Blink device ', deviceId);
        });
    }

    resetDevice(deviceId: number) {
        this.http.get(this.baseUrl + 'device/reset/' + deviceId).subscribe((data) => {
            console.log('Blink device ', deviceId);
        });
    }

    async getfirmwares() {
        this.firmwares = await this.http
            .get<IFirmwareItem[]>('/ota/idebaca/fwlist.json?').toPromise();
    }

    compareFirmware(fva: string, fv2: number) {
        const fv1 = parseInt(fva);
        if (fv1 < fv2) {
            return -1;
        }
        if (fv1 > fv2) {
            return 1;
        }
        return 0;
    }

    getFirmwaresByType(devicetype: number) {
        /*let retval = [];
        for (const item of this.firmwares) {
            if (item.device === devicetype) {
                retval = item.firmwares;
            }
        }*/
        try {
            let retval;
            if (undefined === devicetype) {
                retval = [];
                retval.push('Nessun firmware disponibile');
                return retval;
            }
            retval = this.firmwares.find((x) => x.device === devicetype).firmwares;
            if (retval !== null) {
                if (retval.length === 0) {
                    retval.push('Nessun firmware disponibile');
                }
                /*   if (retval.length > 1) {
                    retval.sort();
                }*/
            } else {
                retval = [];
                retval.push('Nessun firmware disponibile');
            }
            return retval;
        } catch (ex) {
            console.warn('Error in getFirmwaresByType', ex);
            return [];
        }
    }

    getPoliciesDef() {
        return this.http.get(this.baseUrl + 'policiesdef', this.getHeader());
    }

    setPoliciesDef(defs: string) {
        console.log('called!!!');
        this.http.post(this.baseUrl + 'policiesdef', defs, this.getHeader()).subscribe(
            (data) => {
                this.toastr.success('Aggiornamento riuscito');
            },
            (error) => {
                this.toastr.error('Aggiornamento fallito');
            }
        );
    }

    confirm(title: string, buttons: string[]) {
        return new Promise((resolve, reject) => {
            const dialogRef = this.dialog.open(ConfirmdialogComponent, {
                data: { text: title, buttons }
            });

            dialogRef.afterClosed().subscribe((result) => {
                console.log(result);
                resolve(result);
            });
        });
    }

    getSystems() {
        return this.systems.filter((x) => x.root === this.currentRoot || this.currentRoot === 'All');
    }

    setSystem(arg0: IPolicyItem[]) {
        this.systems = arg0;
    }

    getPolycySpec(pol: IPolicyItem): IPolicySpecification {
        return this.policiesDefs.find((x) => x.polType === pol.polType);
    }

    getSiteConfig() {
        this.http.get(this.baseUrl + 'configuration/site', this.getHeader()).subscribe((data) => {
            this.siteConfiguration = data as ISiteConfiguration;
        });
    }

    saveSiteConfiguration() {
        this.http.put(this.baseUrl + 'configuration/site', this.siteConfiguration, this.getHeader()).subscribe(
            () => {
                this.toastr.success('Salvataggio eseguito');
            },
            () => {
                this.toastr.error('Errore durante il salvataggio');
            }
        );
    }

    getPolycyActions(pol: IPolicyItem) {
        return this.http.get<IPolicySpecification[]>(this.baseUrl + 'policiesdef/actions/' + pol.guid, this.getHeader());
    }
    getAllPolycyActions() {
        return this.http.get<{ [id: string]: ISceneryConfigAction[] }>(this.baseUrl + 'policiesdef/actions', this.getHeader()).toPromise();
    }

    getDeviceSepc(type: number): IParameterSpecification {
        return this.deviceDefs.find((x) => x.type === type);
    }

    getCpuInfo() {
        return this.http.get<ICpuinfo[]>(this.baseUrl + 'status');
    }

    getDevPerm(device: IMeshDevice) { }

    getPerm(deviceType: number, commType: number, cat: string, legacy?: boolean): boolean {
        switch (cat) {
            case 'MAC':
                if (legacy) {
                    return false;
                }
                return [1, 2].includes(commType);
            case 'IDX':
                return [0].includes(commType);
            case 'ISMESH':
                return [1].includes(commType);
            case 'RSSI':
                return [1, 2].includes(commType);
            case '485LEV':
                return [0].includes(commType);
        }
        return false;
    }

    getRandom(randLen: number) {
        const strin = 'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890X';
        let strout = '';
        for (let i = 0; i < randLen; i++) {
            strout += strin.charAt(Math.random() * strin.length);
        }
        return strout;
    }

    sendBusScan() {
        this.http.get(this.baseUrl + 'device/scan', this.getHeader()).subscribe((data) => {
            this.toastr.success('Scansione avviata');
        });
    }

    array_move(arr: Array<any>, oldIndex: number, newIndex: number) {
        if (newIndex >= arr.length) {
            let k = newIndex - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
        console.log('Arr: ', arr);
        return arr; // for testing
    }

    getPolicyFromGuid(uid: string) {
        const retval = this.systems.find((x) => x.guid === uid);
        if (retval) {
            return retval.name;
        } else {
            return '--sconosciuta--';
        }
    }

    updateScenery(scenery) {
        this.http.post(this.baseUrl + 'configuration/scenery', scenery, this.getHeader()).subscribe(
            (data) => { },
            (error) => {
                this.toastr.error('Errore nella configurazione dello scenario');
            }
        );
    }

    updateSceneries() {
        this.http.post(this.baseUrl + 'configuration/sceneries', this.sceneries, this.getHeader()).subscribe(
            (data) => {
                this.toastr.success('Aggiornamento scenari eseguito');
            },
            (error) => {
                this.toastr.error('Errore nella configurazione degli scenari');
            }
        );
    }

    isValidDate(d) {
        return d instanceof Date && !isNaN(d.getTime());
    }

    getLOG(key: string) {
        return this.http.get(this.baseUrl + 'configuration/logs/' + key, this.getHeader());
    }

    getActions() {
        return ['NA', 'Allarme', 'Presenza', 'Apriporta', 'Climatizzazione', 'Cortesia'];
    }
}
