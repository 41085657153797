<div class="buttons">
  <div class="buttons__container">
    <div *ngFor="let item of data.data" class="buttons__container__button"
      [ngClass]="{'buttons__container__button--selected': currentItem===item}" (click)="currentItem=item">
      <div class="buttons__container__button__info" *ngIf="item.data">
        Dev: {{item.data.device_id}}<br />In: {{item.data.element[0]}}
      </div>
      <div class="buttons__container__button__label">{{item.name}}</div>
    </div>
  </div>
</div>
