import { Component, OnInit } from '@angular/core';
import { IUserProfile } from 'src/interfaces';
import { DataserviceService } from '../dataservice.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  editedUser: IUserProfile;
  confirmPassword: string;
  constructor(public dataservice: DataserviceService) {
    this.editedUser = {
      username: dataservice.currentUser.username,
      email: dataservice.currentUser.email,
      fullname: dataservice.currentUser.fullname,
      oldpassword: '',
      newpassword: ''

    } as IUserProfile;
  }

  ngOnInit() {
  }

  saveProfile() {
    this.dataservice.updateProfile(this.editedUser);
  }



  validate() {
    console.log('test');
    if (this.editedUser.fullname==undefined || this.editedUser.fullname.trim() === '' || this.editedUser.email.trim() === '') {
        return false;
    }
    if (this.editedUser.oldpassword !== '') {
      if (this.editedUser.newpassword.length < 8) {
        return false;
      }
      if (this.editedUser.newpassword !== this.confirmPassword) {
        return false;
      }
    }

    return true;
  }

}
