import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assigncard',
  templateUrl: './assigncard.component.html',
  styleUrls: ['./assigncard.component.scss']
})
export class AssigncardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
