<div class="roomconfig">
    <div class="rooms"></div>
    <div class="areas">
        <div class="area" *ngFor="let area of dataservice.areas; let i = index">
            <div class="aeraheader">
                <h2 *ngIf="!area.edit">
                    <span>{{ area.name }}</span>
                    <mat-icon class="iconbutton" aria-hidden="false" (click)="area.edit = true">
                        edit
                    </mat-icon>
                </h2>
                <div *ngIf="area.edit">
                    <mat-form-field class="areafield">
                        <input matInput placeholder="Nome area" [(ngModel)]="area.name" />
                    </mat-form-field>
                    <mat-icon class="iconbutton" aria-hidden="false" (click)="updateArea(area)">
                        done
                    </mat-icon>
                </div>
                &nbsp;
                <mat-icon class="iconbutton" aria-hidden="false" (click)="delArea(i)" *ngIf="dataservice.splittedFields[area.id].length == 0 && i !== 0">delete</mat-icon>
            </div>
            <div class="rooms_container_outer">
                <div cdkDropList [cdkDropListData]="dataservice.splittedFields[area.id]" cdkDropListOrientation="horizontal" class="rooms_container" [cdkDropListConnectedTo]="getIds()" (cdkDropListDropped)="drop($event, area)">
                    <div *ngFor="let room of dataservice.splittedFields[area.id]" class="room" [style.order]="room.order" cdkDrag>
                        <div>
                            <b>{{ room.name }}</b>
                        </div>
                        <div>{{ room["root"] }}</div>
                        <div>{{ room.type }}</div>
                    </div>
                </div>
            </div>
        </div>
        <mat-icon class="iconbutton" aria-hidden="false" (click)="addArea()">add_circle</mat-icon>
    </div>
</div>
