import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataserviceService } from 'src/app/dataservice.service';
import { IPolicy } from 'src/interfaces';
import { AlarmpanelComponent } from './alarmpanel/alarmpanel.component';

@Component({
    selector: 'app-riscoalarm',
    templateUrl: './riscoalarm.component.html',
    styleUrls: ['./riscoalarm.component.scss']
})
export class RiscoalarmComponent implements OnInit {
    @Input() policy: IPolicy;
    constructor(public dialog: MatDialog, public dataservice: DataserviceService) {}

    ngOnInit() {}

    openAlarmDialog() {
      const dialogRef = this.dialog.open(AlarmpanelComponent, {
          width: '1024px',
          height: '740px',
          data: { policy: this.policy }
      });

      dialogRef.afterClosed().subscribe((result) => {
          console.log('The dialog was closed');
      });
    }
}
