import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICard, IGraphData, IGraphLogData, IGraphLogDataItem, IPolicy } from 'src/interfaces';
import { DataserviceService } from '../dataservice.service';
import { multi } from './data';

@Component({
    selector: 'app-powerchart',
    templateUrl: './powerchart.component.html',
    styleUrls: ['./powerchart.component.scss']
})
export class PowerchartComponent {
    policy: IPolicy

    multi: any[];
    view: any[] = [700, 300];

    // options
    legend: boolean = false;
    showLabels: boolean = true;
    animations: boolean = true;
    xAxis: boolean = true;
    yAxis: boolean = true;
    showYAxisLabel: boolean = true;
    showXAxisLabel: boolean = true;
    xAxisLabel: string = 'Data';
    yAxisLabel: string = 'Consumo';
    timeline: boolean = false;

    graphDatas: IGraphLogData[] = [];

    colorScheme = {
        domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
    };



    constructor(public dataservice: DataserviceService, public dialogRef: MatDialogRef<PowerchartComponent>, @Inject(MAT_DIALOG_DATA) public data: { policy: IPolicy; datas: IGraphLogData[] }) {
        this.policy = data.policy;
        Object.assign(this, { multi });
        this.graphDatas=data.datas;
    }
    onSelect(event) {
        console.log(event);
    }
}




