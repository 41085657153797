<div class="tabcontainer">
    <h2 class="tabtitle">Dispositivi Wifi</h2>

    <div class="mainmesh" *ngFor="let cpu of dataservice.cpus">
        <mat-divider></mat-divider>
        <div class="nettitle">{{ cpu }}</div>

        <div class="meshcontainer">
            <!--<app-meshdevice [device]="dataservice.coordinator"></app-meshdevice>-->
            <ng-template ngFor let-dev [ngForOf]="dataservice.meshDevces">
                <app-meshdevice *ngIf="dev.deviceType == 100 && getMeshVisibled(dev, cpu)" [device]="dev" (selected)="editDevice($event)"></app-meshdevice>
            </ng-template>
        </div>
        <div class="meshcontainer">
            <ng-template ngFor let-dev [ngForOf]="dataservice.meshDevces">
                <app-meshdevice *ngIf="dev.communicationType == 2 && dev.deviceType != 100 && getMeshVisibled(dev, cpu)" [device]="dev" (selected)="editDevice($event)"></app-meshdevice>
            </ng-template>
        </div>
    </div>
    <div></div>
</div>

<div class="tabcontainer">
    <h2 class="tabtitle">Dispositivi Mesh</h2>
    <div class="firmdownload" *ngIf="dataservice.otaprogress > 0">Download immagine firmware {{ (dataservice.otaprogress / 1000).toFixed(0) }}Kb</div>
    <div class="mainmesh" *ngFor="let cpu of dataservice.cpus">
        <mat-divider></mat-divider>
        <div class="nettitle">{{ cpu }}</div>

        <div class="meshcontainer">
            <!--<app-meshdevice [device]="dataservice.coordinator"></app-meshdevice>-->
            <ng-template ngFor let-dev [ngForOf]="dataservice.meshDevces">
                <app-meshdevice *ngIf="dev.deviceType == 100 && getMeshVisibled(dev, cpu)" [device]="dev" (selected)="editDevice($event)"></app-meshdevice>
            </ng-template>
        </div>
        <div class="meshcontainer">
            <ng-template ngFor let-dev [ngForOf]="dataservice.meshDevces">
                <app-meshdevice *ngIf="dev.communicationType === 1 && dev.deviceType != 100 && getMeshVisibled(dev, cpu)" [device]="dev" (selected)="editDevice($event)"></app-meshdevice>
            </ng-template>
        </div>
    </div>
    <div></div>
</div>
<div class="tabcontainer">
    <h2 class="tabtitle">Dispositivi su BUS</h2>
    <div class="mainmesh" *ngFor="let cpu of dataservice.cpus">
        <mat-divider></mat-divider>
        <div class="nettitle">{{ cpu }}</div>
        <div class="buscontainer">
            <!--<app-meshdevice [device]="dataservice.coordinator"></app-meshdevice>-->
            <ng-template ngFor let-dev [ngForOf]="dataservice.meshDevces">
                <app-meshdevice *ngIf="dev.deviceType == 100 && getMeshVisibled(dev, cpu)" [device]="dev" (selected)="editDevice($event)"></app-meshdevice>
            </ng-template>
            <ng-template ngFor let-dev [ngForOf]="dataservice.meshDevces">
                <app-meshdevice *ngIf="dev.communicationType == 0 && getMeshVisibled(dev, cpu)" [device]="dev" (selected)="editDevice($event)"></app-meshdevice>
            </ng-template>
        </div>
    </div>
</div>
<div *ngIf="scanprogress.progress > 0" class="scanprogress">
    <div class="scanprogress__progress fx-layout-row fx-align--center-x fx-align--x-center">
        <div class="canprogress__progress__label">
            Scan ID: <b>{{ scanprogress.progress }}</b>
        </div>
        <div class="canprogress__progress__label">
            Device: <b>{{ scanprogress.found }}</b>
        </div>
        <div class="canprogress__progress__label">
            Nuovi: <b>{{ scanprogress.fnew }}</b>
        </div>
        <mat-progress-bar mode="determinate" [value]="scanprogress.progress / 2.55"></mat-progress-bar>
        <button mat-raised-button *ngIf="scanprogress.progress > 250" (click)="scanprogress.progress = 0">Chiudi</button>
    </div>
</div>
<div class="tabcontainer">
    <div class="summary">
        <app-idebutt class="custbutton" (click)="ViewMap()" text="SINOTTICO" icon="scatter_plot"></app-idebutt>
        <app-idebutt class="custbutton" (click)="showdel = !showdel" [checked]="!showdel" text="NASCONDI CANCELLATI" icon="delete_outline"></app-idebutt>
        <app-idebutt class="custbutton" (click)="showoffline = !showoffline" [checked]="!showoffline" text="NASCONDI OFFLINE" icon="signal_wifi_off"></app-idebutt>
        <app-idebutt class="custbutton" (click)="BusScan()" text="SCAN BUS" icon="find_replace"></app-idebutt>
        <app-idebutt class="custbutton" (click)="AddDevice()" text="AGGIUNGI" icon="add"></app-idebutt>

        <div>
            Online: <b>{{ dataservice.deviceOnline }}</b>
        </div>
        <div>
            Offline: <b>{{ dataservice.deviceOffline }}</b>
        </div>
        <div>
            Cancellati: <b>{{ dataservice.deviceDeleted }}</b>
        </div>
        <div>
            Totali: <b>{{ dataservice.meshDevces.length }}</b>
        </div>
    </div>
</div>
<div class="multidevice" [ngClass]="{ visibled: getVisibled() }">
    <mat-icon class="upgrade" aria-hidden="false" (click)="upgradeFirmware()">backup</mat-icon>
    <mat-icon class="upgrade" aria-hidden="false" (click)="openSettings()">settings</mat-icon>
    <mat-form-field class="fieldclass">
        <mat-label>Versione firmware </mat-label>
        <mat-select [(ngModel)]="currentFirmware">
            <mat-option *ngFor="let fw of dataservice.getFirmwaresByType(currentDevice)" [value]="fw"> 1.{{ fw }} </mat-option>
        </mat-select>
    </mat-form-field>
</div>
