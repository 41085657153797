import { ImportcardsComponent } from './importcards/importcards.component';
import { LogsComponent } from './logs/logs.component';
import { SiteconfigComponent } from './siteconfig/siteconfig.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CardsComponent } from './cards/cards.component';
import { GuestsComponent } from './guests/guests.component';
import { LoginComponent } from './login/login.component';
import { RoomconfigComponent } from './roomconfig/roomconfig.component';
import { UsermanagerComponent } from './usermanager/usermanager.component';
import { MeshdeviceComponent } from './mesh/meshdevice/meshdevice.component';
import { MeshdeviceconfigComponent } from './mesh/meshdeviceconfig/meshdeviceconfig.component';
import { PolicyconfigComponent } from './mesh/policyconfig/policyconfig.component';
import { EditpolicyconfComponent } from './editpolicyconf/editpolicyconf.component';
import { SysmonComponent } from './sysmon/sysmon.component';
import { DomoticComponent } from './domotic/domotic.component';
import { SceneryComponent } from './scenery/scenery.component';
import { ProfileComponent } from './profile/profile.component';
import { AlertsComponent } from './alerts/alerts.component';
import { DomoticlogicalComponent } from './domoticlogical/domoticlogical.component';


const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'cards', component: CardsComponent },
  { path: 'guests', component: GuestsComponent },
  { path: 'login', component: LoginComponent },
  { path: 'structure', component: RoomconfigComponent },
  { path: 'users', component: UsermanagerComponent },
  { path: 'meshdevice', component: MeshdeviceconfigComponent },
  { path: 'policyconfig', component: PolicyconfigComponent },
  { path: 'policydefs', component: EditpolicyconfComponent },
  { path: 'sysmon', component: SysmonComponent },
  { path: 'scenery', component: SceneryComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'alerts', component: AlertsComponent },
  { path: 'domotic', component: DomoticlogicalComponent },
  { path: 'siteconfig', component: SiteconfigComponent },
  { path: 'logs', component: LogsComponent },
  { path: 'import', component: ImportcardsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
