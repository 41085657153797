<div class="tabcontainer">
    <h2>Gestione tessere</h2>
    <div class="headercard">
        <div class="fakecard">
            <div class="cardcode">
                {{ getReceptionCards() }}
            </div>
            <div class="cardname">{{ currentCard?.cardName || '--------' }} <br /></div>
        </div>
        <div class="card_details" *ngIf="currentCard !== undefined">
            <mat-form-field class="field-full-width">
                <input matInput placeholder="Nome tessera" [(ngModel)]="currentCard.cardName" />
            </mat-form-field>

            <br />
            <mat-form-field *ngIf="currentCard?.isPassepartout && !currentCard.alwaiseValid">
                <input matInput [matDatepicker]="picker" placeholder="Data fine validità passepartout" [(ngModel)]="currentCard.validTo" />

                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            &nbsp;
            <mat-form-field *ngIf="currentCard?.isPassepartout && !currentCard.alwaiseValid">
                <input matInput placeholder="Ora fine validità passepartout" [(ngModel)]="currentCard.checkoutTime" />
            </mat-form-field>
            &nbsp;
            <mat-checkbox [(ngModel)]="currentCard.alwaiseValid">Senza scadenza</mat-checkbox>
        </div>
        <div class="buttons">
            <!--<app-idebutt text="SALVA ANAGRAFICA" icon="save"></app-idebutt>-->
            <app-idebutt text="AGGIORNA" (click)="updateCard()" icon="account_balance_wallet"></app-idebutt>
            <app-idebutt text="PASSEPARTOUT" (click)="makePassepartout()" icon="account_balance_wallet" [checked]="currentCard?.isPassepartout"></app-idebutt>
            <app-idebutt text="ELIMINA TESSERA" (click)="removeCard()" icon="delete_forever"></app-idebutt>
            <app-idebutt *ngIf="!currentCard?.isPassepartout" text="AGGIUNGI" icon="meeting_room" (click)="addRoom(currentCard)"></app-idebutt>
        </div>
    </div>
    <div class="searchfilter">
        <div class="searchfilter__caption">Filtra:</div>
        <mat-checkbox class="searchfilter__cb" [(ngModel)]="filterPass">Passepartouts</mat-checkbox>
        <mat-checkbox class="searchfilter__cb" [(ngModel)]="filterNorm">Tessere normali</mat-checkbox>
        <mat-form-field   appearance="outline">
            <input matInput placeholder="Cerca" [(ngModel)]="filterFind" />
        </mat-form-field>
        <button mat-icon-button (click)="filterFind=''"><mat-icon>highlight_off</mat-icon></button>
    </div>
    <table mat-table [dataSource]="getFiltered()" class="mat-elevation-z8">
       <ng-container matColumnDef="typ">
            <th mat-header-cell *matHeaderCellDef>Tipo</th>
            <td mat-cell *matCellDef="let element">
              <mat-icon aria-label="false" *ngIf="element.isPin">pin</mat-icon>
              <mat-icon aria-label="false" *ngIf="!element.isPin">credit_card</mat-icon>
              </td>
        </ng-container>
        <ng-container matColumnDef="card">
            <th mat-header-cell *matHeaderCellDef>Codice</th>
            <td mat-cell *matCellDef="let element">{{ element.code }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Nome tessera</th>
            <td mat-cell *matCellDef="let element">
                {{ element.cardName }}
            </td>
        </ng-container>
        <!--  <ng-container matColumnDef="guest">
            <th mat-header-cell *matHeaderCellDef>Ospite</th>
            <td mat-cell *matCellDef="let element">
                {{ getGuest(element) }}
            </td>
        </ng-container>-->

        <ng-container matColumnDef="valid">
            <th mat-header-cell *matHeaderCellDef>Passepartout</th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="!element.alwaiseValid && element.isPassepartout">
                    Attivo fino a {{ element.validTo | date: 'dd/MM/yyyy' }}<br />
                    {{ element.checkoutTime }}
                </span>
                <span *ngIf="element.alwaiseValid && element.isPassepartout">Senza scadenza</span>
                <span *ngIf="!element.isPassepartout">NO</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Stanze associate</th>
            <td mat-cell *matCellDef="let element">
                <div class="roomcontainer">
                    <ng-template ngFor let-room [ngForOf]="element.connectedRooms | keyvalue">
                        <app-room *ngIf="dataservice.getRoom(room.key) !== undefined" [policy]="dataservice.getRoom(room.key)"></app-room>
                    </ng-template>
                </div>
                <div class="passepartout" *ngIf="element.isPassepartout">
                    <span>Passepartout</span>
                </div>
                <div class="ghost_card" *ngIf="!element.isPassepartout && element.connectedRooms.length == 0">
                    <span></span>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="select(row)" [ngClass]="{ row_selected: row.code == currentCard?.code }"></tr>
    </table>
</div>
