import { Component, OnInit } from '@angular/core';
import { IdebacaUser } from 'src/interfaces';
import { DataserviceService } from "../dataservice.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  user: IdebacaUser = { username: '', password: '', role: 1, fullname: '' } as IdebacaUser;
  constructor(public dataservice: DataserviceService) { }


  ngOnInit() {
  }

  login() {
    console.log("User: ", this.user);
    this.dataservice.login(this.user);
  }

}
