import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { IMeshDevice } from 'src/interfaces';
import { DataserviceService } from 'src/app/dataservice.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-deviceconfig',
    templateUrl: './deviceconfig.component.html',
    styleUrls: ['./deviceconfig.component.scss']
})
export class DeviceconfigComponent implements OnInit {
    device: IMeshDevice;
    devId: string;
    customData: number[];

    constructor(public dataservice: DataserviceService, public dialogRef: MatDialogRef<DeviceconfigComponent>, @Inject(MAT_DIALOG_DATA) public data: IMeshDevice) {
        this.device = {
            communicationType: data.communicationType,
            name: data.name,
            deviceType: data.deviceType,
            mac: data.mac,
            device_id: data.device_id,
            ssid: data.ssid,
            pass: data.pass,
            root: data.root
        } as IMeshDevice;
        this.devId = this.device.device_id.toString(16);
    }
    changeId() {
        try {
            this.device.device_id = parseInt(this.devId, 16);
        } catch {}
    }
    ngOnInit() {}

    getCustomConfig() {
        this.dataservice.getDeviceCustomConfig(this.device, 0x54, 33).subscribe(
            (data) => {
                this.customData = data as number[];
                console.log('Custom data', this.data);
            },
            () => this.dataservice.toastr.error("Errore durante l'esecuzione del comando")
        );
    }

    import() {
      this.device.ssid = this.dataservice.siteConfiguration.ssid;
      this.device.pass = this.dataservice.siteConfiguration.pass;
    }

    perm(cat: string): boolean {
        if (this.device) {
            return this.dataservice.getPerm(this.device.deviceType, this.device.communicationType, cat);
        }
        return false;
    }

    confirm() {
        this.data.communicationType = this.device.communicationType;
        this.data.name = this.device.name;
        this.data.legacy = this.device.legacy;

        this.data.deviceType = this.device.deviceType;
        this.data.mac = this.device.mac;
        this.data.device_id = this.device.device_id;
        this.data.ssid = this.device.ssid;
        this.data.pass = this.device.pass;
        this.dataservice.updateMeshDevice(this.data);
        this.dialogRef.close();
    }

    abort() {
        this.dialogRef.close();
    }
}
