import { Component, OnInit } from '@angular/core';
import { DataserviceService } from '../dataservice.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { IArea } from 'src/interfaces';

@Component({
    selector: 'app-roomconfig',
    templateUrl: './roomconfig.component.html',
    styleUrls: ['./roomconfig.component.scss']
})
export class RoomconfigComponent implements OnInit {
    LIST_IDS = [];
    constructor(public dataservice: DataserviceService) { }

    ngOnInit() {
        /*for (let i = 0; i < this.dataservice.areas.length; i++) {
            const fields = this.dataservice.getFields(this.dataservice.areas[i]);
            for (let j = 0; j < fields.length; j++) {
                fields
            }

        }*/
    }
    drop(event: CdkDragDrop<string[]>, newarea) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
        console.log('updateField');
        this.rebuildIndex();
        this.dataservice.updateField();
    }

    rebuildIndex() {
        for (const area of this.dataservice.areas) {
            for (let i = 0; i < this.dataservice.splittedFields[area.id].length; i++) {
                this.dataservice.splittedFields[area.id][i].order = i;
                this.dataservice.splittedFields[area.id][i].area = area.id;
            }
        }
    }

    addArea() {
        for (let i = 1; i < 100; i++) {
            if (undefined === this.dataservice.findAreaByName('Nuova area ' + i.toString())) {
                const newArea = {
                    id: this.dataservice.newGuid(),
                    name: 'Nuova area ' + i.toString(),
                    order: this.dataservice.areas.length
                } as IArea;
                this.dataservice.areas.push(newArea);
                this.dataservice.splittedFields[newArea.id] = [];
                break;
            }
        }
    }

    delArea(index: number) {
        this.dataservice.areas.splice(index, 1);
        this.dataservice.updateField();
    }

    addId(i) {
        console.log('Added id: ', i);
        if (!this.LIST_IDS.includes('cdk-drop-list-' + i)) {
            this.LIST_IDS.push('cdk-drop-list-' + i);
        }
        console.log(this.LIST_IDS);
        return 'cdk-drop-list-' + i;
    }
    getIds() {
        const retval = [];
        for (let i = 0; i < this.dataservice.areas.length; i++) {
            retval.push('cdk-drop-list-' + i.toString());
        }
        console.log("Getids");
        return retval;
    }

    updateArea(area) {
        area.edit = false;
        this.dataservice.updateField();
    }
}
