<mat-drawer-container class="main-container">
  <mat-drawer mode="side" [opened]="getisopened()">
    <app-navmenu (click)="isopened=false"></app-navmenu>
  </mat-drawer>

  <mat-drawer-content>

    <app-toolbar (click)="isopened=!isopened"></app-toolbar>

    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
