import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';

import { IPolicySpecification, IPolicyItem, IParameterInfo, IInputUpdateItem, IPolicy, IPolicyScenery, ISceneryConfigAction } from 'src/interfaces.js';
import { ModuleaddComponent } from '../moduleadd/moduleadd.component';
import { MatDialog } from '@angular/material/dialog';
import { DataserviceService } from 'src/app/dataservice.service';

@Component({
    selector: 'app-policyconfig',
    templateUrl: './policyconfig.component.html',
    styleUrls: ['./policyconfig.component.scss']
})
export class PolicyconfigComponent implements OnInit {
    policyAdd: IPolicySpecification;
    polSceneryAdd: IPolicyItem;
    currentPolicy: IPolicyItem;
    currentPolicySpec: IPolicySpecification;
    nameedit = false;
    invocationedit = false;
    currentAcquire: number;
    actions: { [id: string]: ISceneryConfigAction[] };

    constructor(public dialog: MatDialog, public dataservice: DataserviceService) { }

    async ngOnInit() {
        this.actions = await this.dataservice.getAllPolycyActions();
        this.dataservice.currentRoot = this.dataservice.cpus[0];
        if (this.dataservice.systems.length > 0) {
            this.selectPol(this.dataservice.systems[0]);
        }
        console.log('Init', this.dataservice.systems);

        this.dataservice.deviceInputUpdate.subscribe((input) => {
            if (this.currentAcquire !== undefined) {
                const inp = input as IInputUpdateItem;
                console.log(inp);
                const index = this.currentAcquire;
                if (inp.inputCode === this.currentPolicySpec.params[index].required) {
                    if (undefined === this.currentPolicy.params[index]) {
                        this.currentPolicy.params[index] = {
                            devices: [],
                            inoulogic: [],
                            code: this.currentPolicySpec.params[index].code
                        };
                    }
                    this.currentPolicy.params[index].code = this.currentPolicySpec.params[index].code;
                    if (undefined === this.currentPolicy.params[index].devices) {
                        this.currentPolicy.params[index].devices = [];
                    }
                    let devnew = this.currentPolicy.params[index].devices.find((x) => x.device_id === inp.device_id);
                    if (undefined === devnew) {
                        devnew = {} as IParameterInfo;
                        devnew.device_id = inp.device_id;
                        devnew.element = [];
                        this.currentPolicy.params[index].devices.push(devnew);
                    }
                    if (!devnew.element.includes(inp.mask)) {
                        devnew.element.push(inp.mask);
                    }
                }
            }
        });
    }

    getRoots() {
        /*  const roots = [];
        for (const root of this.dataservice.meshDevces) {
            if (root.deviceType === 100) {
                roots.push(root.root);
            }
        }
        return roots;*/
        for (const pol of this.dataservice.systems) {
            if (this.dataservice.cpus.indexOf(pol.root) === -1) {
                this.dataservice.cpus.push(pol.root);
            }
        }
        return this.dataservice.cpus;
    }
    modelCahange() {
        console.log(this.modelCahange);
    }

    genPolScenery(policy: IPolicySpecification): { [id: string]: ISceneryConfigAction[] } {
        const retval: { [id: string]: ISceneryConfigAction[] } = {};
        if (policy.events) {
            for (const scac of policy.events) {
                retval[scac.action + scac.value.toString()] = [];
            }
        }
        return retval;
    }

    addPolicy() {
        if (this.policyAdd !== undefined) {
            let ticon = '';

            if (this.policyAdd.icons !== undefined && this.policyAdd.icons.length > 0) {
                ticon = this.policyAdd.icons[0];
            }
            const pol: IPolicyItem = {
                policyName: this.policyAdd.name,
                name: 'policy ' + (this.dataservice.systems.length + 1).toFixed(0),
                invocation: '',
                index: this.getFirstIndex(),
                polType: this.policyAdd.polType,
                polclass: this.policyAdd.polclass,
                code: this.policyAdd.code,
                params: [],
                guid: this.dataservice.getRandom(15),
                configurations: [],
                virtuals: [],
                icon: ticon,
                root: this.dataservice.currentRoot,
                scenery: this.genPolScenery(this.policyAdd)
            };
            if (this.policyAdd.configurations) {
                for (const conf of this.policyAdd.configurations) {
                    pol.configurations.push(conf.default_val);
                    pol.virtuals.push(conf.isVirtual);
                }
            }
            console.log('Add new policy: ', pol);
            this.dataservice.systems.push(pol);
            this.currentPolicy = pol;
            this.currentPolicySpec = this.policyAdd;
        }
    }
    getFirstIndex(): number {
        for (let i = 0; i < 1000; i++) {
            const idx = this.dataservice.systems.filter((x) => x.index === i);
            if (idx.length === 0) {
                return i;
            }
        }
        return -1;
    }
    delPol(item: IPolicyItem) {
        this.dataservice.systems.splice(this.dataservice.systems.indexOf(item), 1);
        this.currentPolicy = undefined;
    }

    getStatus(item: IPolicyItem) {
        let parcount = 0;
        for (const para of item.params) {
            if (null != para) {
                for (const dev of para.devices) {
                    const device = this.dataservice.meshDevces.find((x) => x.device_id === dev.device_id && x.root === item.root);

                    if (device !== undefined) {
                        parcount++;
                        if (!device.isOnline) {
                            return { borderLeftColor: 'red' };
                        }
                    }
                }
            }
        }
        if (parcount === 0) {
            return { borderLeftColor: 'gray' };
        }
        return { borderLeftColor: 'green' };
    }

    selectPol(item: IPolicyItem) {
        this.nameedit = false;
        this.currentPolicy = item;
        item.virtuals = [];
        this.currentPolicySpec = this.dataservice.getPolycySpec(this.currentPolicy);

        if (this.currentPolicySpec.configurations) {
            for (const conf of this.currentPolicySpec.configurations) {
                item.virtuals.push(conf.isVirtual);
            }
        }

        for (let i = 0; i < this.currentPolicySpec.params.length; i++) {
            if (this.currentPolicySpec.params[i].inoutlogic) {
                for (let j = 0; j < this.currentPolicySpec.params[i].inoutlogic.length; j++) {
                    if (this.currentPolicy.params[i].inoulogic === undefined) {
                        this.currentPolicy.params[i].inoulogic[j] = this.getInOutRelationDefault(i, j);
                    }
                }
            }
        }
    }

    getInOutRelationName(i: number, j: number): string {
        return this.currentPolicySpec.params[i].inoutlogic[j].split(':')[0];
    }
    getInOutRelationDefault(i: number, j: number): number {
        console.log('default');
        return parseInt(this.currentPolicySpec.params[i].inoutlogic[j].split(':')[1], 10);
    }

    updatePolicy() {
        this.nameedit = false;
        this.invocationedit = false;
    }

    addDevice(index: number) {
        const dialogRef = this.dialog.open(ModuleaddComponent, {
            width: '1024px',
            height: '740px',
            data: { param: this.currentPolicySpec.params[index], root: this.currentPolicy.root }
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log('The dialog was closed', result);
            const devnew = {} as IParameterInfo;
            devnew.device_id = result.device_id;
            devnew.element = Object.assign([], this.currentPolicySpec.params[index].default);
            console.log('-------------');
            console.log(this.currentPolicySpec);
            console.log(this.dataservice.getPolycySpec(this.currentPolicy));
            if (undefined === this.currentPolicy.params[index]) {
                this.currentPolicy.params[index] = {
                    devices: [],
                    inoulogic: [],
                    code: this.currentPolicySpec.params[index].code
                };
            }
            this.currentPolicy.params[index].code = this.currentPolicySpec.params[index].code;
            if (undefined === this.currentPolicy.params[index].devices) {
                this.currentPolicy.params[index].devices = [];
            }
            this.currentPolicy.params[index].devices.push(devnew);
        });
    }

    erase() {
        this.dataservice.confirm('Elimina la CPU ' + this.dataservice.currentRoot + '?', ['si', 'no']).then((data) => {
            if (data === 0) {
                console.log('deleting cpu...');
                for (let i = this.dataservice.systems.length - 1; i >= 0; i--) {
                    const pol = this.dataservice.systems[i];
                    if (pol.root === this.dataservice.currentRoot) {
                        this.dataservice.systems.splice(i, 1);
                    }
                }

                for (let i = this.dataservice.meshDevces.length - 1; i >= 0; i--) {
                    const dev = this.dataservice.meshDevces[i];
                    if (dev.root === this.dataservice.currentRoot) {
                        this.dataservice.meshDevces.splice(i, 1);
                    }
                }
                this.dataservice.cpus.splice(this.dataservice.cpus.indexOf(this.dataservice.currentRoot), 1);
                this.dataservice.currentRoot = this.dataservice.cpus[0];
            }
        });
        this.dataservice.deleteCpu(this.dataservice.currentRoot).subscribe(() => {
            this.dataservice.saveFieldConfig(false);
        });
    }

    update() {
        this.dataservice.saveFieldConfig(false);
    }

    testSystem() {
        this.dataservice.updateSystemConfg(false);
    }
    updateSystem() {
        this.dataservice.updateSystemConfg(true);
    }

    ondelete(item: number, pol: Array<IParameterInfo>) {
        console.log('Ondelete: ', item, pol);
        pol.splice(item, 1);
    }

    readDevice(item: number) {
        if (this.currentAcquire === item) {
            this.currentAcquire = undefined;
        } else {
            this.currentAcquire = item;
        }
    }

    getPolicyIcon(policy: IPolicySpecification) {
        if (undefined !== policy.icons) {
            return 'assets/icons/' + policy.icons[0] + '.svg';
        }
        return 'assets/icons/default.svg';
    }

    deletePolicy(item, num: number) {
        this.currentPolicy.scenery[item.action + item.value.toString()].splice(num, 1);
    }

    getScenery(item) {
        if (undefined === this.currentPolicy.scenery || null == this.currentPolicy.scenery) {
            this.currentPolicy.scenery = this.genPolScenery(this.currentPolicySpec);
        }
        return this.currentPolicy.scenery[item.action + item.value.toString()];
    }

    addPolicyScenery(item) {
        if (this.polSceneryAdd !== undefined) {
            const act: ISceneryConfigAction = {
                policy: this.polSceneryAdd.guid
            } as ISceneryConfigAction;

            this.currentPolicy.scenery[item.action + item.value.toString()].splice(0, 0, act);
        }
    }

    selAction(item, action) {
        console.log(item, action);
        item.value = action.value;
        item.action = action.action;
    }
}
