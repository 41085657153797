import { Component } from '@angular/core';
import { ILogicalElement } from 'src/interfaces';
import { DataserviceService } from '../dataservice.service';

@Component({
  selector: 'app-domoticlogical',
  templateUrl: './domoticlogical.component.html',
  styleUrls: ['./domoticlogical.component.scss']
})
export class DomoticlogicalComponent {
  constructor(public dataservice: DataserviceService) { }
  addButton() {
    const item: ILogicalElement = {
      name: 'device1',
      guid: this.dataservice.newGuid(),
      elementType: 'BUTTONS',
      inNumber: 3,
      data: []
    };
    for (let i = 0; i < item.inNumber; i++) {
      item.data.push({ name: (i + 1).toString(), data: undefined });
    }
    this.dataservice.logicalElements.push(item);
  }
}
