export var multi =
    [{
        name: "Consumo",
        series: [
            { "name": "2022-12-31T11:34:05.9792773+01:00", "value": 433.12015 },
            { "name": "2022-12-31T11:39:10.2632487+01:00", "value": 381.03403 },
            { "name": "2022-12-31T11:44:14.5515773+01:00", "value": 356.98004 }]
    }
    ];

