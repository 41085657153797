<div class="mainpage">
    <div class="tabcontainer">
        <h2 class="tabtitle">Avvisi</h2>
        <table class="maintable">
            <tr>
                <th>Level</th>
                <th>Device</th>
                <th>Sistema</th>
                <th>Tipo</th>
            </tr>
            <tr *ngFor="let item of dataservice.alerts">
                <td>
                    <mat-icon aria-hidden="false"> {{ getLevel(item) }}</mat-icon>
                </td>
                <td>{{ item.deviceId }}</td>
                <td>{{ item.cpu }}</td>
                <td>{{ getMessage(item) }}</td>
            </tr>
        </table>
    </div>
</div>
