import { Component, OnInit } from '@angular/core';
import { ICronoTime } from 'src/interfaces';

@Component({
    selector: 'app-tprog',
    templateUrl: './tprog.component.html',
    styleUrls: ['./tprog.component.scss']
})
export class TprogComponent implements OnInit {
    timespan: ICronoTime[] = [];
    constructor() {
        for (let i = 0; i < 48; i++) {
            const ctime: ICronoTime = {
                hh: Math.floor(i / 2),
                mm: i % 2 ? 30 : 0,
                value: 0
            };
            this.timespan.push(ctime);
        }
    }

    selT(item: ICronoTime, v: number) {
        if (item.value != v) {
            item.value = v;
        } else {
            item.value--;
        }
    }

    ngOnInit(): void {}
}
