<ul class="nav navbar-nav">
    <img class="logo" src="assets/logo_ipnetgreen.png" />
    <hr />
    <li [ngStyle]="dataservice.getAuth(true, 0)" [routerLink]="['/']" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">home</mat-icon> Home</li>
    <li *ngFor="let area of dataservice.areas; let i = index" class="areas" [routerLink]="['/domotic/', area.id]" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
        <h2>{{ area.name }}</h2>
    </li>
    <li [ngStyle]="dataservice.getAuth(true, 0)" [routerLink]="['/profile']" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">face</mat-icon>Profilo</li>

    <li [ngStyle]="dataservice.getAuth(false, 0)" [routerLink]="['/login']" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">how_to_reg</mat-icon>Login</li>

    <li [ngStyle]="dataservice.getAuth(true, 1)" [routerLink]="['/cards']" [routerLinkActive]="['link-active']" class="desktop" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">credit_card</mat-icon>Tessere</li>
    <!--<li [ngStyle]="dataservice.getAuth(true, 2)" [routerLink]="['/guests']" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">face</mat-icon>Ospiti</li>-->

    <li [ngStyle]="dataservice.getAuth(true, 2)" [routerLink]="['/users']" [routerLinkActive]="['link-active']" class="desktop" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">account_box</mat-icon>Utenti</li>

    <li [ngStyle]="dataservice.getAuth(true, 2)" [routerLink]="['/structure']" [routerLinkActive]="['link-active']" class="desktop" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">sort_by_alpha</mat-icon>Ordina elementi</li>
    <li [ngStyle]="dataservice.getAuth(true, 2)" [routerLink]="['/meshdevice']" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">dialpad</mat-icon>Dispositivi</li>

    <li [ngStyle]="dataservice.getAuth(true, 2)" [routerLink]="['/policyconfig']" [routerLinkActive]="['link-active']" class="desktop" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">widgets</mat-icon>Configura impianto</li>
    <li [ngStyle]="dataservice.getAuth(true, 2)" [routerLink]="['/siteconfig']" [routerLinkActive]="['link-active']" class="desktop" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">storage</mat-icon>Impostazioni Sito</li>
    <li [ngStyle]="dataservice.getAuth(true, 2)" [routerLink]="['/sysmon']" [routerLinkActive]="['link-active']" class="desktop" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">dns</mat-icon>System status</li>
    <li [ngStyle]="dataservice.getAuth(true, 2)" [routerLink]="['/domotic']" [routerLinkActive]="['link-active']" class="desktop" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">roofing</mat-icon>Domotica</li>
    <li [ngStyle]="dataservice.getAuth(true, 2)" [routerLink]="['/scenery']" [routerLinkActive]="['link-active']" class="desktop" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">room_preferences</mat-icon>Scenari</li>
    <li [ngStyle]="dataservice.getAuth(true, 1)" [routerLink]="['/logs']" [routerLinkActive]="['link-active']" class="desktop" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">view_list</mat-icon>Log eventi</li>
    <li [ngStyle]="dataservice.getAuth(true, 2)" [routerLink]="['/alerts']" [routerLinkActive]="['link-active']" class="desktop" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">notification_important</mat-icon>Avvisi/Allerte</li>
    <li [ngStyle]="dataservice.getAuth(true, 2)" [routerLink]="['/import']" [routerLinkActive]="['link-active']" class="desktop" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">import_export</mat-icon>Importa/Esporta</li>
    <li [ngStyle]="dataservice.getAuth(true, 0)" (click)="logout()"><mat-icon aria-hidden="false">exit_to_app</mat-icon>Logout</li>
</ul>
