<div class="tabcontainer">
    <h2 class="tabtitle">{{ getTitle() }}</h2>
    <div class="itemcontainer">
        <ng-template ngFor let-policy [ngForOf]="dataservice.splittedFields[currentId]">
            <app-onoff [policy]="policy" *ngIf="policy.type == 'light'"></app-onoff>
            <app-updown [policy]="policy" *ngIf="policy.type == 'window'"></app-updown>
            <app-riscoalarm [policy]="policy" *ngIf="policy.type == 'alarm_risco'"></app-riscoalarm>
            <app-scenitem [policy]="policy" *ngIf="policy.type == 'scenery'"></app-scenitem>
            <app-door-phone [policy]="policy" *ngIf="policy.type == 'doorphone'"></app-door-phone>
        </ng-template>
    </div>
</div>
