import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataserviceService } from 'src/app/dataservice.service';
import { RoomdetailsComponent } from 'src/app/room/roomdetails/roomdetails.component';
import { SceneryschedulerComponent } from 'src/app/sceneryscheduler/sceneryscheduler.component';
import { IPolicy, ISceneryConfig } from 'src/interfaces';

@Component({
    selector: 'app-scenitem',
    templateUrl: './scenitem.component.html',
    styleUrls: ['./scenitem.component.scss']
})
export class ScenitemComponent implements OnInit {
    constructor(public dialog: MatDialog, public dataservice: DataserviceService) {}
    @Input() policy: ISceneryConfig;
    ngOnInit() {}

    getstyle() {
        return { backgroundColor: '#00000010' };
    }

    statusclick() {
        //  console.log('Policy', this.policy);
        // this.dataservice.sendmessage({ GUID: this.policy.guid } as IPolicy, 'SET', 'ff');

        const dialogRef = this.dialog.open(SceneryschedulerComponent, {
            maxWidth: '800px',

            data: { scenery: this.policy }
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log('The dialog was closed');
        });
    }
}
