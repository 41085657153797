import { DataserviceService } from './../dataservice.service';
import { Component, OnInit } from '@angular/core';
import { IImportCard } from 'src/interfaces';

@Component({
    selector: 'app-importcards',
    templateUrl: './importcards.component.html',
    styleUrls: ['./importcards.component.scss']
})
export class ImportcardsComponent implements OnInit {
    cardslist = '';
    constructor(public dataservice:DataserviceService) {}
    invert = false;
    ngOnInit(): void {}
    import() {
        const data: IImportCard[] = [];
        const jdata = JSON.parse(this.cardslist);
        for (const row of jdata.rows) {
            const room: IImportCard = {} as IImportCard;
            room.name = row.name;
            room.cards = [];
            for (const key in row.rawdata) {
                room.cards.push(row.rawdata[key]);
            }
            data.push(room);
        }
        console.log(data);
        this.dataservice.importCards(data, this.invert);

    }
}
