<h1 mat-dialog-title>Stanza {{ room.name }}</h1>
<div mat-dialog-content style="height: calc(100% -127px); max-height: unset">
    <mat-divider></mat-divider>
    <mat-tab-group>
        <mat-tab label="Accesso">
            <div class="cards" *ngIf="!assignCard && getPermission('CARD')">
                <div class="boxheader">Tessere associate</div>
                <h4>Passa la tessera sul lettore per associare una nuova tessera</h4>
                <table mat-table [dataSource]="getCards()" class="mat-elevation-z8">
                    <ng-container matColumnDef="card">
                        <th mat-header-cell *matHeaderCellDef>Codice tessera</th>
                        <td mat-cell *matCellDef="let element">{{ element.code }}</td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Nome tessera</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.cardName }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="guest">
                        <th mat-header-cell *matHeaderCellDef>Ospite</th>
                        <td mat-cell *matCellDef="let element">
                            {{ getGuest(element) || '-' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="valid">
                        <th mat-header-cell *matHeaderCellDef>Validità</th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="!element.connectedRooms[room.GUID].alwaiseValid">
                                {{ element.connectedRooms[room.GUID].validTo | date: 'dd/MM/yyyy' }}
                                {{ element.connectedRooms[room.GUID].checkoutTime }}
                            </span>
                            <span *ngIf="element.connectedRooms[room.GUID].alwaiseValid">Senza scadenza</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>Azione</th>
                        <td mat-cell *matCellDef="let element">
                            <app-idebutt class="ideb" text="CHECKOUT" img="assets/check-out.svg" (click)="remove(element, $event)"></app-idebutt>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row class="trow" *matRowDef="let row; columns: displayedColumns" (click)="edit(row)"></tr>
                </table>
            </div>
            <div class="cardsassign" *ngIf="assignCard && getPermission('CARD')">
                <div *ngIf="dataservice.lastReceptionCard.code !== '00000000'">
                    <h4 class="boxheader">{{getPinLabel(dataservice.lastReceptionCard)}} {{ dataservice.lastReceptionCard.code }}</h4>

                    <mat-form-field class="field-full-width">
                        <input matInput placeholder="Salva la tessera con un nome (facoltativo)" [(ngModel)]="dataservice.lastReceptionCard.cardName" />
                    </mat-form-field>
                    <mat-form-field class="field-full-width">
                        <input matInput placeholder="Nome cliente (facoltativo)" [(ngModel)]="details.guestName" />
                    </mat-form-field>
                    <div class="field-full-width">
                        <mat-checkbox [(ngModel)]="details.alwaiseValid">Senza scadenza</mat-checkbox>
                    </div>
                    <br />
                    <mat-form-field *ngIf="!currentCard?.isPassepartout && !details.alwaiseValid">
                        <input matInput [matDatepicker]="picker" placeholder="Data fine validità" [(ngModel)]="details.validTo" />

                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field *ngIf="!currentCard?.isPassepartout && !details.alwaiseValid">
                        <input matInput placeholder="Ora fine validità" [(ngModel)]="details.checkoutTime" />
                    </mat-form-field>

                    <h4 class="error_message" *ngIf="isInRoom()">La tessera è associata a questa stanza</h4>
                    <h4 class="error_message" *ngIf="currentCardsRooms.length > 0 && !isInRoom()">
                        <div>La tessera è già associata alle stanze:</div>
                        <div *ngFor="let room of currentCardsRooms">{{ room.name }}</div>
                    </h4>
                    <h4 class="error_message" *ngIf="currentCard?.isPassepartout">La tessera è un passepartout, non può essere associata ad una stanza</h4>
                </div>
                <br />

                <div class="flexbuttons flex-left">
                    <app-idebutt style="margin-right: 20px" *ngIf="canUpdate()" class="confirm" text="ASSOCIA" img="assets/card.svg" (click)="confirmCard()"></app-idebutt>
                    <app-idebutt style="margin-right: 10px" class="confirm" text="ANNULLA" icon="cancel" (click)="abortCard()"></app-idebutt>
                    <app-idebutt *ngIf="isInRoom()" class="ideb" text="CHECKOUT" img="assets/check-out.svg" (click)="remove(currentCard, $event)"></app-idebutt>
                </div>
            </div>
        </mat-tab>
        <mat-tab *ngIf="dataservice.siteConfiguration.enableThermo" label="Climatizzazione">
            <table>
                <tr>
                    <td class="tlabel">Stanza</td>
                    <td class="thermo">{{ getTemp('T1') }}°C</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td class="tlabel">Bagno</td>
                    <td class="thermo">{{ getTemp('T2') }}°C</td>
                    <td>&nbsp;</td>
                </tr>
            </table>
            <mat-divider></mat-divider>
            <app-tprog></app-tprog>
        </mat-tab>
        <mat-tab label="Impostazioni">
            <div class="configItem">
                <label>Alimentazione stanza</label>
                <app-toggle3state [value]="getPowerValue()" (valueChanged)="setPowerValue($event)"></app-toggle3state>
            </div>
            <div class="configItem" *ngIf="linkedRoomName!==''">
                <label>Collega a stanza</label>
              <!--  <mat-form-field>
                    <mat-label>Scegli la stanza</mat-label>
                    <mat-select [ngModel]="getlinkedRoom()" (selectionChange)="linkedRoomChanged($event)">
                        <mat-option *ngFor="let item of getRooms()" [value]="item">
                            {{ item.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>-->
                {{linkedRoomName}}
                <mat-slide-toggle class="configItem__toggle" [ngModel]="getlinkedEnabled()" (change)="linkedRoomChanged($event)"></mat-slide-toggle>
            </div>
             <div class="configItem" *ngIf="linkedRoomName==='' && linkedRoom!==''">
              <button mat-raised-button (click)="clearLink()">Elimina link</button>
             </div>
           <!-- link {{room.parameters.LINK?.message}} -->
        </mat-tab>
    </mat-tab-group>
</div>
<div mat-dialog-actions class="flexbuttons">
    <app-idebutt class="ideb" [checked]="getDoor()" text="APRI PORTA" img="assets/key_black.svg" (click)="openDoor()"></app-idebutt>
    <app-idebutt *ngIf="dataservice.siteConfiguration.enableCode" class="ideb" text="CODICE" icon="pin" (click)="addCode()"></app-idebutt>
    <div></div>
    <app-idebutt class="ideb" [checked]="getClean()" text="PULIZIA" img="assets/scoop_black.svg" (click)="setClean()"></app-idebutt>
    <app-idebutt class="ideb" [checked]="getNotDisturb()" text="NON DISTURBARE" img="assets/do-not-disturb_black.svg" (click)="setNotDisturb()"></app-idebutt>

    <app-idebutt class="ideb" *ngIf="getIsAlarm()" [checked]="true" text="ALLARME" icon="notification_important" (click)="alarmOff()"></app-idebutt>

    <div [ngStyle]="dataservice.getAuth(true, 3)">{{ getFlag() }}</div>

    <!-- <app-idebutt class="ideb" text="ASSOCIA TESSERA" img="assets/card.svg" (click)="startAssignCard()"></app-idebutt>-->
</div>
