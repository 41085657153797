<app-cpugraph *ngFor="let item of cpus" [data]="item" [dataChange]="dataChange"></app-cpugraph>
<div>
    <mat-form-field >
        <input matInput type="number" placeholder="Old Id" [(ngModel)]="oldId" />
    </mat-form-field>
    <mat-form-field >
        <input matInput type="number" placeholder="New Id" [(ngModel)]="newId" />
    </mat-form-field>
    <mat-form-field >
        <input matInput placeholder="CPU" [(ngModel)]="cpu" />
    </mat-form-field>
    <button mat-raised-button (click)="send()">Invia</button>
</div>
