<div class="pagecontainer">
    <div >
        <div *ngFor="let area of dataservice.areas; let i = index" class="areas">
            <h2>{{ area.name }}xx</h2>
            <div class="roomscontainer">
                <div  *ngFor="let room of dataservice.splittedFields[area.id]">
                <app-room *ngIf="dataservice.allowedRooms.includes(room.type)"
                   
                    [policy]="room" [card]="card"
                >
                </app-room>
                </div>
            </div>
        </div>
    </div>
</div>
