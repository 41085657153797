import { Component, OnInit, Inject } from '@angular/core';
import { DataserviceService } from '../dataservice.service';
import { ICard } from 'src/interfaces';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-roomlist',
    templateUrl: './roomlist.component.html',
    styleUrls: ['./roomlist.component.scss']
})
export class RoomlistComponent implements OnInit {
    card: ICard;
    constructor(public dataservice: DataserviceService, public dialogRef: MatDialogRef<RoomlistComponent>, @Inject(MAT_DIALOG_DATA) public data: ICard) {
        this.card = data;
        console.log('Opened card: ', this.card);
    }

    ngOnInit() {}
}
