import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataserviceService } from '../dataservice.service';

@Component({
    selector: 'app-domotic',
    templateUrl: './domotic.component.html',
    styleUrls: ['./domotic.component.scss']
})
export class DomoticComponent implements OnInit {
    currentId: string;
    constructor(private route: ActivatedRoute, public dataservice: DataserviceService, private router: Router) {
        this.route.paramMap.subscribe(async (map) => {
            console.log('Pmap; ', map.get('id'));
            this.currentId = map.get('id');
            await this.ngOnInit();
        });
    }

    getTitle() {
        const area = this.dataservice.areas.find((x) => x.id === this.currentId);
        if (area) {
            return area.name;
        }
        return '---';
    }

    ngOnInit() {
        console.log(this.dataservice.areas);
    }
}
