<div class="room" [ngStyle]="getStyle()" [ngClass]="{ 'room-free': policy?.haveCard }" (click)="openDetail()">
    <div class="roomname roomname__link" *ngIf="getLinkEnabled(policy)">
        <span>{{ policy.name }}</span>
        <mat-icon aria-hidden="false">link</mat-icon>
        <span>{{ getLink(policy) }}</span>
    </div>

    <div *ngIf="!getLinkEnabled(policy)" class="roomname">{{ policy.name }}</div>

    <div *ngIf="policy?.haveCard" class="cardbadge">
        <div class="carditem" *ngIf="getCardNum() > 0"><mat-icon aria-hidden="false">hourglass_bottom</mat-icon>{{ getCardNum() }}</div>
        <div class="carditem"><mat-icon aria-hidden="false">credit_card</mat-icon>{{ getTotalCardNum() }}</div>
    </div>

    <!--<mat-icon aria-hidden="false" class="config_icon">build</mat-icon>-->
    <!-- <div class="container">-->
    <!-- {{this.policy.parameters.FLAGS.value}}-->
    <mat-icon class="roomicon roomicon__inroom" aria-hidden="false" [ngStyle]="inroomstyle()">man</mat-icon>
    <mat-icon class="roomicon roomicon__winopen" aria-hidden="false" [ngStyle]="winstyle()">balcony</mat-icon>
    <img src="assets/do-not-disturb_red.svg" class="bullet roomicon roomicon__notdist" [ngStyle]="disturbstyle()" />
    <img src="assets/scoop_red.svg" class="bullet roomicon roomicon__clean" [ngStyle]="cleanstyle()" />
    <mat-icon class="color_red roomicon roomicon__error" [ngStyle]="hwerror()" *ngIf="dataservice.currentUser.role > 1">error</mat-icon>
    <!--</div>-->
    <img src="assets/key_black.svg" class="bullet key" [ngStyle]="keystyle()" *ngIf="dataservice.currentUser.role > 1" />
    <mat-icon class="alarm_anim" aria-hidden="false" [ngStyle]="alarmstyle()">notification_important</mat-icon>
</div>
