import { Component, OnInit } from '@angular/core';
import { DataserviceService } from '../dataservice.service';
import { IdebacaUser } from 'src/interfaces';

@Component({
    selector: 'app-usermanager',
    templateUrl: './usermanager.component.html',
    styleUrls: ['./usermanager.component.scss']
})
export class UsermanagerComponent implements OnInit {
    users: Array<IdebacaUser>;
    selectedUser: IdebacaUser = {} as IdebacaUser;

    roles = [
        { value: 1, viewValue: 'User' },
        { value: 2, viewValue: 'Administrator' }
    ];

    displayedColumns: string[] = [
        'username',
        'name',
        'email',
        'password',
        'role',
        'action'
    ];

    constructor(public dataservice: DataserviceService) { }

    ngOnInit() {
        this.dataservice.getUsers().subscribe(data => {
            this.users = data as Array<IdebacaUser>;
        });
        /*  if (this.dataservice.currentUser.role === 3) {
            this.roles.push({ value: 3, viewValue: 'SYS Admin' });
        }*/
    }

    select(user: IdebacaUser) { }

    adduser() {
        console.log(this.selectedUser);
        if (
            this.selectedUser.username &&
            this.selectedUser.password &&
            this.selectedUser.email
        ) {
            this.dataservice.addUser(this.selectedUser).subscribe(() => {
                this.dataservice.toastr.success('Utente aggiunto');
                this.selectedUser = {} as IdebacaUser;
                this.dataservice.getUsers().subscribe(data => {
                    this.users = data as Array<IdebacaUser>;
                });
            });
        }
    }
    remove(user: IdebacaUser) {
        this.dataservice.deleteUser(user).subscribe(() => {
            this.dataservice.toastr.success('Utente aggiunto');
            this.selectedUser = {} as IdebacaUser;
            this.dataservice.getUsers().subscribe(data => {
                this.users = data as Array<IdebacaUser>;
            });
        });
    }
}
