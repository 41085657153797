<div class="mainpage">
    <div class="tabcontainer" *ngIf="dataservice.siteConfiguration">
        <h2 class="tabtitle">Configurazione Impianto</h2>
        <mat-form-field class="field-full-width">
            <mat-label>OTA SSID</mat-label>
            <input matInput placeholder="OTA SSID" [(ngModel)]="dataservice.siteConfiguration.ssid" />
        </mat-form-field>
        <mat-form-field class="field-full-width">
            <mat-label>OTA PASS</mat-label>

            <input matInput placeholder="OTA PASS" [(ngModel)]="dataservice.siteConfiguration.pass" />
        </mat-form-field>
        <mat-form-field class="field-full-width">
            <mat-label>OTA Base URL</mat-label>

            <input matInput placeholder="OTA BASE URL" [(ngModel)]="dataservice.siteConfiguration.ota_url" />
        </mat-form-field>
        <mat-form-field class="field-full-width">
            <mat-label>Nome sito</mat-label>

            <input matInput placeholder="Nome sito" [(ngModel)]="dataservice.siteConfiguration.site_name" />
        </mat-form-field>

        <mat-form-field class="field-full-width">
            <mat-label>Lunghezza codice [4-8]</mat-label>

            <input matInput placeholder="Lunghezza codice" type="number" [(ngModel)]="dataservice.siteConfiguration.codeLen" />
        </mat-form-field>

        <mat-form-field class="field-full-width">
            <mat-label>Licenza</mat-label>

            <input matInput placeholder="Licenza" [(ngModel)]="dataservice.siteConfiguration.license" />
        </mat-form-field>
        <mat-checkbox [(ngModel)]="dataservice.siteConfiguration.alarmEnabled">Abilita allarmi</mat-checkbox>

        <mat-checkbox [(ngModel)]="dataservice.siteConfiguration.cardAlwaiseValid">Tessere senza scadenza</mat-checkbox>
        <mat-checkbox [(ngModel)]="dataservice.siteConfiguration.enableCode">Abilita inserimento codice</mat-checkbox>
        <mat-checkbox [(ngModel)]="dataservice.siteConfiguration.enableThermo">Abilita climatizzazione stanza
        </mat-checkbox>
        <app-idebutt class="btpol" text="SALVA Impostazioni" icon="save" (click)="update()"></app-idebutt>
    </div>
</div>