<div class="mainpage">
    <div class="tabcontainer">
        <h2 class="tabtitle">Scenari</h2>
        <app-idebutt class="btpol" text="SALVA SCENARI" icon="save" (click)="update()"></app-idebutt>
    </div>
    <section class="sect">
        <div class="pollist">
            <button mat-icon-button aria-hidden="true" (click)="addScenery()">
                <mat-icon>add</mat-icon>
            </button>
            <div class="policycontainer">
                <div *ngFor="let item of dataservice.sceneries" class="pitem"
                    [ngClass]="{ 'pitem-sel': currentScenery === item }" (click)="selectScen(item)">
                    <div class="name">{{ item.name }}</div>
                </div>
            </div>
        </div>

        <div class="polcontent" *ngIf="currentScenery">
            <div class="paramcontainer">
                <div *ngIf="!nameedit" class="polhead">
                    <h2 class="tabtitle">{{ currentScenery.name }}</h2>
                    <button mat-icon-button aria-hidden="false" (click)="nameedit = true">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button aria-hidden="false" (click)="delete()">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button mat-icon-button aria-hidden="false" (click)="clone()">
                        <mat-icon>content_copy</mat-icon>
                    </button>
                </div>

                <div *ngIf="nameedit" class="polhead">
                    <mat-form-field class="areafield">
                        <input matInput placeholder="Nome elemento" [(ngModel)]="currentScenery.name" />
                    </mat-form-field>
                    <mat-icon class="iconbutton" aria-hidden="false" (click)="updateScenery()">done</mat-icon>
                </div>

                <div class="param">
                    <div class="polparheader">
                        <div>Attivazione</div>
                        <button mat-icon-button aria-hidden="false" (click)="addDevice(undefined)">
                            <mat-icon>library_add</mat-icon>
                        </button>
                        <button mat-icon-button aria-hidden="false" (click)="readDevice()"
                            [ngClass]="{ 'aquire-enabled': currentAcquire }">
                            <mat-icon>flip_camera_android</mat-icon>&nbsp;Acquisisci
                        </button>
                    </div>
                    <div class="paramcontainer">
                        <app-policyparam *ngFor="let item of currentScenery.activators; let j = index"
                            (delete)="ondelete(j)" [device]="item" [deviceParamType]="{ required: 'DIN32' }">
                        </app-policyparam>
                    </div>
                </div>
                <div class="param">
                    <div class="polparheader">
                        <div>Aggiungi policy:&nbsp;</div>
                        <mat-form-field>
                            <mat-label>sistema</mat-label>
                            <mat-select [(ngModel)]="policyAdd">
                                <mat-option *ngFor="let policy of dataservice.systems" [value]="policy">
                                    <div [ngClass]="{'policynotused': isUsed(policy)}">
                                        <img class="iconpol" [src]="'assets/icons/' + policy.icon + '.svg'" />
                                        {{ policy.name }}</div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button mat-icon-button aria-hidden="false" (click)="addPolicy()">
                            <mat-icon>library_add</mat-icon>
                        </button>
                    </div>
                    <div class="paramcontainer">
                        <table>
                            <tr>
                                <th>Policy</th>
                                <th>Azione</th>
                                <th>Elimina</th>
                            </tr>
                            <tr class = "polspacing" *ngFor="let item of currentScenery.actions; let j = index">
                                <!-- (delete)="onActionDelete(j)"-->
                                <td><img class="iconpol" [src]="'assets/icons/' + getPolicyIconFromGuid(item.policy) + '.svg'" />{{ getPolicyFromGuid(item.policy) }}</td>
                                <td>
                                    <span class="actionitem" *ngFor="let action of actions[item.policy]"
                                        (click)="item.value = action.value; item.action = action.action"
                                        [ngClass]="{ 'action-selected': item.value === action.value && item.action === action.action }">
                                        {{ action.label }}
                                    </span>
                                    <!--
                                    <mat-form-field *ngIf="selectedPolicy===j">
                                        <mat-label>Azione</mat-label>
                                        <mat-select [(ngModel)]="item.action">
                                            <mat-option *ngFor="let action of getSpecsActions(item.policy)"
                                                [value]="action">
                                                {{ action.label }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>-->
                                </td>
                                <td>
                                    <mat-icon aria-hidden="false" (click)="deletePolicy(j)" class="actiondelete">delete
                                    </mat-icon>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>