import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IMeshDevice, IFirmwareItem } from 'src/interfaces';
import { DeviceconfigComponent } from '../deviceconfig/deviceconfig.component';
import { MatDialog } from '@angular/material/dialog';
import { DataserviceService } from 'src/app/dataservice.service';

@Component({
    selector: 'app-meshdevice',
    templateUrl: './meshdevice.component.html',
    styleUrls: ['./meshdevice.component.scss']
})
export class MeshdeviceComponent implements OnInit {
    @Input() device: IMeshDevice;
    @Input() forbid: boolean;
    @Output() selected = new EventEmitter<IMeshDevice>();
    firmwares: number[] = [];
    constructor(public dialog: MatDialog, public dataservice: DataserviceService) {}
    toolTip = '';
    toolTipCount = 0;

    ngOnInit() {
        this.firmwares = this.dataservice.getFirmwaresByType(this.device.deviceType);
        this.getUsed();
    }

    getType(id: number) {
        if (id === 0) {
            return 'LEGACY';
        }
        const dev = this.dataservice.getDeviceSepc(id);

        if (dev) {
            return dev.name + ' ' + this.dataservice.commTypes[this.device.communicationType];
        }
        return 'unknown';
    }
    getPerm(cat) {
        return this.dataservice.getPerm(this.device.deviceType, this.device.communicationType, cat);
    }

    getUp() {
        const days = Math.floor(this.device.uptime / 86400);
        const hours = Math.floor(this.device.uptime / 3600) % 24;
        const minutes = Math.floor(this.device.uptime / 60) % 60;
        const seconds = this.device.uptime % 60;
        if (days > 0) {
            return days.toFixed() + 'd ' + hours.toFixed() + 'h ' + minutes.toFixed() + 'min ' + seconds.toFixed() + 'sec';
        }

        if (hours > 0) {
            return hours.toFixed() + 'h ' + minutes.toFixed() + 'min ' + seconds.toFixed() + 'sec';
        }
        if (minutes > 0) {
            return minutes.toFixed() + 'min ' + seconds.toFixed() + 'sec';
        }
        return seconds.toFixed() + 'sec';
    }

    getFwClass() {
        if (this.firmwares.length > 0) {
            let maxfw;
            if (this.firmwares.length > 1) {
                maxfw = Math.max(...this.firmwares);
            } else if (this.firmwares.length > 0) {
                maxfw = this.firmwares[0];
            } else {
                return 'fw_bullet fw_undefined';
            }
            switch (this.dataservice.compareFirmware(this.device.fwVersion, maxfw)) {
                case -1:
                    return 'fw_bullet fw_minus';
                case 0:
                    return 'fw_bullet fw_ok';
                case 1:
                    return 'fw_bullet fw_undefined';
            }
        }
    }

    getIcon() {
        if (this.device.deviceType === 100) {
            return 'device_hub';
        }
        if (this.device.deviceType === 150) {
            return 'leak_add';
        }
        switch (this.device.communicationType) {
            case 0:
                return 'linear_scale';
            case 1:
                return 'wifi_tethering';
            case 2:
                return 'wifi';
        }
    }

    editClick() {
        const dialogRef = this.dialog.open(DeviceconfigComponent, {
            width: '1024px',
            height: '740px',
            data: this.device
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log('The dialog was closed');
        });
    }

    GetMStyle() {
        const retval = {} as any;
        retval.textStyle = 'bold';
        if (this.device.mem8 > 60000) {
            retval.color = 'green';
        } else if (this.device.mem8 > 40000) {
            retval.color = 'yellow';
        } else {
            retval.color = 'red';
        }
        return retval;
    }

    seldevice() {
        console.log('Click select');
        this.selected.emit(this.device);
    }

    getSelectable() {
        if (this.dataservice.deviceFilter) {
            if (this.device.deviceType != this.dataservice.deviceFilter.deviceType) {
                return false;
            }
            if (this.device.communicationType != this.dataservice.deviceFilter.communicationType) {
                return false;
            }
        }
        return true;
    }

    deleteClick() {
        if (this.device.deleted) {
            this.device.deleted = false;
            this.dataservice.updateMeshDevice(this.device);
        } else {
            let found = 0;
            for (const pol of this.dataservice.systems) {
                if (pol.root === this.device.root) {
                    for (const para of pol.params) {
                        if (para != null) {
                            const devs = para.devices.filter((x) => x.device_id === this.device.device_id);
                            if (devs.length > 0) {
                                found++;
                                console.log('Found in ' + pol.name);
                                break;
                            }
                        }
                    }
                }
            }
            if (this.device.isOnline) {
                this.dataservice.confirm('Non puoi eliminare un dispositivo online', ['OK']).then((success) => {
                    console.log('Return: ', success);
                });
            } else if (found > 0) {
                this.dataservice.confirm('Il dispositivo è utilizzato in ' + found + ' policy, eliminarlo ugualmente?', undefined).then((success) => {
                    if (success === 0) {
                        for (const pol of this.dataservice.systems) {
                            for (const para of pol.params) {
                                if (para != null) {
                                    for (let i = para.devices.length - 1; i >= 0; i--) {
                                        if (para.devices[i].device_id === this.device.device_id) {
                                            para.devices.splice(i, 1);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    this.device.deleted = true;
                    this.dataservice.updateMeshDevice(this.device);
                });
            } else {
                this.dataservice.confirm('Eliminare il dispositivo ' + this.device.device_id + '?', undefined).then((success) => {
                    console.log('Return: ', success);
                    if (success === 0) {
                        this.device.deleted = true;
                        this.dataservice.updateMeshDevice(this.device);
                    }
                });
            }
        }
    }
    getUsed() {
        this.toolTipCount = 0;
        this.toolTip = '';
        for (const pol of this.dataservice.systems) {
            if (pol.root === this.device.root) {
                for (const para of pol.params) {
                    if (para != null) {
                        const devs = para.devices.filter((x) => x.device_id === this.device.device_id);
                        if (devs.length > 0) {
                            this.toolTip += pol.name + '\n';
                            this.toolTipCount++;
                            break;
                        }
                    }
                }
            }
        }
    }
    getSignalClass() {
        if (this.device.communicationType != 0) {
            const rssi = this.device.signal - 256;
            if (rssi < -80) {
                return 'rssi rssi_undefined';
            }
            if (rssi < -60) {
                return 'rssi rssi_minus';
            }
            return 'rssi rssi_ok';
        } else {
            const rssi = this.device.signal / 10;
            if (rssi < 80) {
                return 'rssi rssi_undefined';
            }
            if (rssi < 90) {
                return 'rssi rssi_minus';
            }
            return 'rssi rssi_ok';
        }
    }

    goconf() {
        this.dataservice.enableConfig(this.device.device_id);
    }

    reset() {
        this.dataservice.resetDevice(this.device.device_id);
    }

    blink() {
        console.log(this.device);
        this.dataservice.blink(this.device.device_id);
    }

    getDevIcon() {
        switch (this.device.deviceType) {
            case 160:
                return 'assets/icons/device-icons-rfid.png';
            case 190:
                return 'assets/icons/device-icons-room.png';
            case 100:
                return 'assets/icons/device-icons-cpu.png';
            case 210:
                return 'assets/icons/device-icons-in.png';
            case 220:
                return 'assets/icons/device-icons-out.png';
            case 230:
                return 'assets/icons/device-icons-rgb.png';
        }
        return 'assets/icons/device-icons-na.png';
    }
}
