<div class="mainpage">
    <div class="tabcontainer">
        <h2 class="tabtitle">Edit definizioni Policy</h2>

        <app-idebutt class="size_100" text="SALVA" icon="save" (click)="saveConfig()"></app-idebutt>
    </div>
    <div class="poldefs">
        <textarea [(ngModel)]="polspec" placeholder="Policy definition" class="txt"></textarea>
    </div>
</div>
