<h4>{{ device?.mac }} - {{ device?.serial_number }}</h4>

<div class="bulletcontainer">
    <div class="bullet bullet--ConnectedToCloud" [class]="getClass('ConnectedToCloud')">ConnectedToCloud</div>
    <div class="bullet bullet--LowNoBatterytrouble" [class]="getClass('LowNoBatterytrouble')">LowNoBatterytrouble</div>
    <div class="bullet bullet--ACtrouble" [class]="getClass('ACtrouble')">ACtrouble</div>
    <div class="bullet bullet--Phonelinetrouble" [class]="getClass('Phonelinetrouble')">Phonelinetrouble</div>
    <div class="bullet bullet--Clocktrouble" [class]="getClass('Clocktrouble')">Clocktrouble</div>
    <div class="bullet bullet--Defaultswitch" [class]="getClass('Defaultswitch')">Defaultswitch</div>
    <div class="bullet bullet--MS1reporttrouble" [class]="getClass('MS1reporttrouble')">MS1reporttrouble</div>
    <div class="bullet bullet--MS2reporttrouble" [class]="getClass('MS2reporttrouble')">MS2reporttrouble</div>
    <div class="bullet bullet--MS3reporttrouble" [class]="getClass('MS3reporttrouble')">MS3reporttrouble</div>
    <div class="bullet bullet--BoxorBacktamper" [class]="getClass('BoxorBacktamper')">BoxorBacktamper</div>
    <div class="bullet bullet--Programmingmode" [class]="getClass('Programmingmode')">Programmingmode</div>
    <div class="bullet bullet--Min3Bypass" [class]="getClass('Min3Bypass')">Min3Bypass</div>
    <div class="bullet bullet--WalkTest" [class]="getClass('WalkTest')">WalkTest</div>
    <div class="bullet bullet--AUXtrouble" [class]="getClass('AUXtrouble')">AUXtrouble</div>
    <div class="bullet bullet--BellSwitchLSSwitch" [class]="getClass('BellSwitchLSSwitch')">BellSwitchLSSwitch</div>
    <div class="bullet bullet--BELLtrouble" [class]="getClass('BELLtrouble')">BELLtrouble</div>
    <div class="bullet bullet--ServiceExpired" [class]="getClass('ServiceExpired')">ServiceExpired</div>
    <div class="bullet bullet--PaymentExpired" [class]="getClass('PaymentExpired')">PaymentExpired</div>
    <div class="bullet bullet--SysteminServicemode" [class]="getClass('SysteminServicemode')">SysteminServicemode</div>
    <div class="bullet bullet--DualPath" [class]="getClass('DualPath')">DualPath</div>
</div>

<div class="button_cnt">
    <button *ngIf="parameter.message?.ConnectedToCloud" mat-raised-button color="warn" (click)="disconnect()">Disconnetti da centrale</button>
    <button *ngIf="!parameter.message?.ConnectedToCloud" mat-raised-button color="primary" (click)="connect()">Connetti alla centrale</button>
    <button mat-raised-button color="accent" (click)="reset()">Reset centrale</button>
</div>
