import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { DataserviceService } from '../dataservice.service';
import { ICpuinfo } from 'src/interfaces';
import { interval } from 'rxjs';

@Component({
    selector: 'app-sysmon',
    templateUrl: './sysmon.component.html',
    styleUrls: ['./sysmon.component.scss']
})
export class SysmonComponent implements OnInit {
    constructor(public dataservice: DataserviceService) {}
    cpus: ICpuinfo[] = [];
    sizes: { [id: string]: number };
    newId = 0;
    oldId = 0;
    cpu = "123456";
    @Output() dataChange: EventEmitter<any> = new EventEmitter();
    ngOnInit() {
        const seconds = interval(10000);
        this.dataservice.getCpuInfo().subscribe(data => {
            this.cpus = data as ICpuinfo[];
            for (const cpu of this.cpus) {
                if (this.sizes[cpu.tag] !== cpu.mem32.length) {
                    this.sizes[cpu.tag] = cpu.mem32.length;
                    console.log('Changed!!!');
                    this.dataChange.emit();
                }
            }
        });
        seconds.pipe().subscribe(() => {
            this.dataservice.getCpuInfo().subscribe(data => {
                this.cpus = data as ICpuinfo[];
            });
        });
    }
    send() {
      this.dataservice.setDeviceId(this.oldId, this.newId, this.cpu);
    }
}
