import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataserviceService } from 'src/app/dataservice.service';
import { IPolicy } from 'src/interfaces';
import { DoorPhoneWindowComponent } from './door-phone-window/door-phone-window.component';

@Component({
    selector: 'app-door-phone',
    templateUrl: './door-phone.component.html',
    styleUrls: ['./door-phone.component.scss']
})
export class DoorPhoneComponent implements OnInit {
    constructor(public dialog: MatDialog, public dataservice: DataserviceService) {}
    @Input() policy: IPolicy;

    ngOnInit(): void {
        this.dataservice.doorUpdate.subscribe((data) => {
            console.log(data);
            this.statusclick();
        });
    }

    getstyle() {}
    statusclick() {
        const dialogRef = this.dialog.open(DoorPhoneWindowComponent, {
            data: this.policy
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log('The dialog was closed');
        });
    }
}
