<div class="meshparam">
    <div class="mac">[{{ device.device_id }}]&nbsp;sn:<b>{{realDevice?.serial_number}}</b>

    <span class="led" [ngClass]="{'led--on': realDevice?.isOnline}"></span></div>
    <b>{{ deviceSpec?.name || 'Dispositivo sconosciuto' }}</b>
    <div class="configurations">
        <div class="element" *ngIf="activeParam">
            <div class="element__name">{{ activeParam.label }}</div>
            <div class="element__sub" *ngFor="let item of activeParam.placeholders; let j = index" [ngClass]="{ 'element__sub--selected': device.element?.includes(j), 'element__sub--inverted': getInverted(j) }" (click)="SelIO(j)">{{ item }}</div>
        </div>
    </div>
    <app-idebutt class="btremove" text="REMOVE" img="assets/check-out.svg" (click)="remove()"></app-idebutt>
</div>
