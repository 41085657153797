import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataserviceService } from '../dataservice.service';
import { ISceneryConfig, IInputUpdateItem, IParameterInfo, ISceneryActivator, IPolicy, ISceneryConfigAction, IPolicySpecification, IPolicyItem } from 'src/interfaces';
import { ModuleaddComponent } from '../mesh/moduleadd/moduleadd.component';

@Component({
    selector: 'app-scenery',
    templateUrl: './scenery.component.html',
    styleUrls: ['./scenery.component.scss']
})
export class SceneryComponent implements OnInit {
    constructor(public dialog: MatDialog, public dataservice: DataserviceService) {}

    sceneryAdd: ISceneryConfig;
    currentScenery: ISceneryConfig;
    nameedit = false;
    currentAcquire = false;
    policyAdd: IPolicyItem;
    selectedPolicy: number;
    actions: { [id: string]: ISceneryConfigAction[] };
    async ngOnInit() {
        this.actions = await this.dataservice.getAllPolycyActions();

        if (this.dataservice.sceneries.length > 0) {
            this.currentScenery = this.dataservice.sceneries[0];
        }
        this.dataservice.deviceInputUpdate.subscribe((input) => {
            console.log('Change detected');

            if (this.currentAcquire && this.currentScenery !== undefined) {
                const inp = input as IInputUpdateItem;
                console.log(inp);
                const index = this.currentAcquire;
                if (inp.inputCode === 'DIN32') {
                    if (undefined === this.currentScenery.activators) {
                        this.currentScenery.activators = [];
                    }

                    let newdev = this.currentScenery.activators.find((x) => x.device_id === inp.device_id);
                    if (newdev === undefined) {
                        newdev = {} as ISceneryActivator;
                        newdev.device_id = inp.device_id;
                        newdev.element = [];
                        newdev.element.push(inp.mask);
                        this.currentScenery.activators.push(newdev);
                    }

                    if (!newdev.element.includes(inp.mask)) {
                        newdev.element.push(inp.mask);
                    }
                }
            }
        });
    }

    updateScenery() {
        this.nameedit = false;
    }

    addScenery() {
        const pol: ISceneryConfig = {
            name: 'scenario ' + (this.dataservice.sceneries.length + 1).toFixed(0),
            activation: '',
            order: 0,
            area: this.dataservice.areas[0].id,
            guid: this.dataservice.getRandom(15),
            activators: [],
            actions: []
        } as ISceneryConfig;

        console.log('Add new scenery: ', pol);
        this.dataservice.sceneries.push(pol);
    }

    selectScen(item: ISceneryConfig) {
        this.nameedit = false;
        this.currentScenery = item;
        if (this.currentScenery.actions === undefined) {
            this.currentScenery.actions = [];
        }
        if (this.currentScenery.activators === undefined) {
            this.currentScenery.activators = [];
        }
        // this.currentPolicySpec = this.dataservice.getPolycySpec(this.currentPolicy);
    }

    addDevice(index: number) {
        const dialogRef = this.dialog.open(ModuleaddComponent, {
            width: '1024px',
            height: '740px',
            data: { param: { required: 'DIN32' }, root: undefined }
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log('The dialog was closed', result);
            if (result) {
                const devnew = {} as ISceneryActivator;
                devnew.device_id = result.device_id;
                devnew.element = Object.assign([], 0);
                console.log('-------------');
                if (undefined === this.currentScenery.activation) {
                    this.currentScenery.activators = [];
                }

                this.currentScenery.activators.push(devnew);
            }
        });
    }

    readDevice() {
        this.currentAcquire = !this.currentAcquire;
    }

    addPolicy() {
        console.log('Add policy action: ', this.policyAdd);
        if (this.policyAdd !== undefined) {
            const act = { ...this.actions[this.policyAdd.guid][0] };
            act.policy = this.policyAdd.guid;

            this.currentScenery.actions.splice(0, 0, act);
        }
    }

    getPolicyFromGuid(uid: string) {
        const retval = this.dataservice.systems.find((x) => x.guid === uid);
        if (retval) {
            return retval.name;
        } else {
            return '--sconosciuta--';
        }
    }

    getPolicyIconFromGuid(uid: string) {
        const retval = this.dataservice.systems.find((x) => x.guid === uid);
        if (retval) {
            return retval.icon;
        } else {
            return '';
        }
    }

    getSpecsActions(uid: string) {
        const pol = this.dataservice.systems.find((x) => x.guid === uid);
        const polact = this.dataservice.policiesDefs.find((x) => x.polType === pol.polType);
        return polact.commands;
    }

    update() {
        this.dataservice.updateSceneries();
    }

    updateSystem() {
        this.dataservice.updateSystemConfg(true);
    }

    ondelete(num) {
        this.currentScenery.activators.splice(num, 1);
    }

    deletePolicy(num: number) {
        this.currentScenery.actions.splice(num, 1);
    }

    clone() {
        const newscen = JSON.parse(JSON.stringify(this.currentScenery));
        newscen.name += 'copia ' + (this.dataservice.sceneries.length + 1).toFixed(0);
        newscen.guid = this.dataservice.getRandom(15);
        newscen.activators = [];
        this.dataservice.sceneries.push(newscen);
        this.selectScen(newscen);
    }

    delete() {
        this.dataservice.confirm('Eliminare scenario ' + this.currentScenery.name + '?', ['SI', 'NO']).then((data) => {
            console.log('Response', data);
            if (data === 0) {
                const index = this.dataservice.sceneries.indexOf(this.currentScenery);
                if (index !== -1) {
                    this.dataservice.sceneries.splice(index, 1);
                    if (this.dataservice.sceneries.length > 0) {
                        this.currentScenery = this.dataservice.sceneries[0];
                    } else {
                        this.currentScenery = undefined;
                    }
                }
            }
        });
    }

    isUsed(policy) {
        const pol = this.currentScenery.actions.find((x) => x.policy === policy.guid);
        console.log('passato');
        if (pol) {
            return false;
        }
        return true;
    }
}
