import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataserviceService } from 'src/app/dataservice.service';
import { PowerchartComponent } from 'src/app/powerchart/powerchart.component';
import { IGraphLogDataItem, IPolicy } from 'src/interfaces';

@Component({
  selector: 'app-powermeter',
  templateUrl: './powermeter.component.html',
  styleUrls: ['./powermeter.component.scss']
})
export class PowermeterComponent implements OnInit {
  constructor(public dialog: MatDialog, public dataservice: DataserviceService) { }
  @Input() policy: IPolicy;

  ngOnInit(): void { }
  getFloat(num, size) {
    return this.dataservice.FromFloatSafe(parseInt(num, 16)).toFixed(size);
  }
  getstyle() {
    let value = 0.2 + this.dataservice.FromFloatSafe(parseInt(this.policy.parameters.WATT.value, 16)) / 3000;
    if (value > 1) {
      value = 1;
    }
    return { color: this.dataservice.getLinearColor(value) };
  }
  openDetail() {
    this.dataservice.getCustomLog(this.policy.index).subscribe(data => {
      const graphDatas = [];
      for (const ser of data as IGraphLogDataItem[]) {
        const dt=new Date(ser.name);
       // ser.name = dt.getHours() + ':' + dt.getMinutes();
        ser.name = dt.toLocaleTimeString('it-IT', {
          // en-US can be set to 'default' to use user's browser settings
          hour: '2-digit',
          minute: '2-digit',
        });
      }
      graphDatas.push({
        name: "Consumo",
        series: data as IGraphLogDataItem[]
      });
      
      const dialogRef = this.dialog.open(PowerchartComponent, {
        width: '1024px',
        height: '740px',
        data: { policy: this.policy, datas: graphDatas }
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log('The dialog was closed');
      });

    });

  }
}
