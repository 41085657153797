import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IPolicy } from 'src/interfaces';

@Component({
    selector: 'app-alarmwin',
    templateUrl: './alarmwin.component.html',
    styleUrls: ['./alarmwin.component.scss']
})
export class AlarmwinComponent implements OnInit {
    room: IPolicy;

    constructor(
        public dialogRef: MatDialogRef<AlarmwinComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IPolicy
    ) {
        this.room = data;
    }
    ngOnInit() {}

    close() {
      this.dialogRef.close();
    }
}
