import { IScanData } from './../../../interfaces';
import { DeviceotasettingsComponent } from './../../deviceotasettings/deviceotasettings.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataserviceService } from 'src/app/dataservice.service';
import { IMeshDevice, IUpgradeMessages, IFirmwareItem } from 'src/interfaces';
import { DeviceconfigComponent } from '../deviceconfig/deviceconfig.component';
import { MeshgraphComponent } from '../meshgraph/meshgraph.component';
import { AdddeviceComponent } from 'src/app/adddevice/adddevice.component';

@Component({
    selector: 'app-meshdeviceconfig',
    templateUrl: './meshdeviceconfig.component.html',
    styleUrls: ['./meshdeviceconfig.component.scss']
})
export class MeshdeviceconfigComponent implements OnInit {
    currentDevice: number;
    currentFirmware = '';
    currentType: number;
    showdel = true;
    showoffline = true;
    scanprogress: IScanData = {
        progress: 0,
        found: 0,
        fnew: 0
    };

    constructor(public dialog: MatDialog, public dataservice: DataserviceService) {
        this.dataservice.onScanChanged.subscribe((data) => {
            this.scanprogress = data as IScanData;
        });
    }

    ngOnInit() {}

    editDevice(device: IMeshDevice) {
        console.log('Selected');
        device.selected = !device.selected;
        if (device.selected) {
            this.currentDevice = device.deviceType;
            if (this.dataservice.deviceFilter === undefined) {
                this.dataservice.deviceFilter = device;
            }
        } else {
            let empty = true;
            for (const dev of this.dataservice.meshDevces) {
                if (dev.selected) {
                    empty = false;
                }
            }
            if (empty) {
                this.dataservice.deviceFilter = undefined;
            }
        }
    }

    openSettings() {
        const dialogRef = this.dialog.open(DeviceotasettingsComponent, {
            width: '1024px',
            height: '740px'
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log('The dialog was closed');
        });
    }

    getVisibled() {
        for (const dev of this.dataservice.meshDevces) {
            if (dev.selected) {
                return true;
            }
        }
        return false;
    }

    upgradeFirmware() {
        if (this.currentFirmware) {
            const toupdate: IUpgradeMessages = {
                ids: [],
                deviceType: this.currentDevice,
                fwVersion: this.currentFirmware,
                ssid: '',
                pass: ''
            };
            for (const dev of this.dataservice.meshDevces) {
                if (dev.selected) {
                    toupdate.ids.push(dev.device_id);
                    dev.selected = false;
                }
            }
            console.log('Upgrade: ', toupdate);
            this.dataservice.upgradeFirmware(toupdate);
        }
    }

    ViewMap() {
        const dialogRef = this.dialog.open(MeshgraphComponent, {
            width: '1024px',
            height: '740px'
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log('The dialog was closed');
        });
    }

    getMeshVisibled(device: IMeshDevice, cpu: string): boolean {
        return (this.showoffline || device.isOnline) && (this.showdel || !device.deleted) && device.root === cpu;
    }

    BusScan() {
        this.dataservice.sendBusScan();
    }
    AddDevice() {
        const dialogRef = this.dialog.open(AdddeviceComponent, {
            width: '1024px',
            height: '740px'
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log('The dialog was closed');
        });
    }
}
