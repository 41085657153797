import { Component, Input, OnInit } from '@angular/core';
import { DataserviceService } from 'src/app/dataservice.service';
import { IMeshDevice, IParameter, IPolicy, IRiscoPartition } from 'src/interfaces';

@Component({
    selector: 'app-partition',
    templateUrl: './partition.component.html',
    styleUrls: ['./partition.component.scss']
})
export class PartitionComponent implements OnInit {
    @Input() parameter: IParameter;
    @Input() policy: IPolicy;

    device: IMeshDevice;
    constructor(public dataservice: DataserviceService) {}

    ngOnInit(): void {
        const id = parseInt(this.parameter.code.split('_')[1], 10);
        const device = this.dataservice.meshDevces.find((x) => x.device_id === id);
        this.device = device;
        console.log('Device: ', device);
    }
    getClass(key: string) {
        if (this.parameter.message) {
            return this.parameter.message[key] ? '' : 'bullet--disabled';
        }
        return 'bullet--disabled';
    }

    getInsert() {
        if (this.parameter.message) {
            return !this.parameter.message.Arm;
        }
        return false;
    }
    getUnInsert() {
        if (this.parameter.message) {
            return this.parameter.message.Arm;
        }
        return false;
    }

    btInsert(action: string) {
        const data = {
            value: this.parameter.message.did,
            action
        };
        this.dataservice.sendMessageToServer(this.policy, 'ARMPART', data);
    }
    btDisable() {
      if (this.parameter.message) {
          return !this.parameter.message.ReadytoArm;
      }
      return true;
    }
}
