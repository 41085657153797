<div class="tabcontainer">
    <h2 class="tabtitle">Gestione utenti</h2>

    <table mat-table [dataSource]="users" class="mat-elevation-z8">
        <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef>Username</th>
            <td mat-cell *matCellDef="let element">{{ element.username }}</td>
            <td mat-cell *matFooterCellDef>
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Nome utente"
                        [(ngModel)]="selectedUser.username"
                    />
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Nome</th>
            <td mat-cell *matCellDef="let element">
                {{ element.fullname }}
            </td>
            <td mat-cell *matFooterCellDef>
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Nome completo"
                        [(ngModel)]="selectedUser.fullname"
                    />
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let element">
                {{ element.email }}
            </td>
            <td mat-cell *matFooterCellDef>
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Email"
                        [(ngModel)]="selectedUser.email"
                    />
                </mat-form-field>
            </td>
        </ng-container>
        <ng-container matColumnDef="password">
            <th mat-header-cell *matHeaderCellDef>Password</th>
            <td mat-cell *matCellDef="let element">
                Reset
            </td>
            <td mat-cell *matFooterCellDef>
                <mat-form-field>
                    <input
                        matInput type="password"
                        placeholder="Password"
                        [(ngModel)]="selectedUser.password"
                    />
                </mat-form-field>
            </td>
        </ng-container>
        <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>Ruolo</th>
            <td mat-cell *matCellDef="let element">
                {{ dataservice.getRole(element.role) }}
            </td>
            <td mat-cell *matFooterCellDef>
                <mat-form-field>
                    <mat-label>Seleziona il ruolo</mat-label>
                    <mat-select [(value)]="selectedUser.role">
                        <mat-option
                            *ngFor="let role of roles"
                            [value]="role.value"
                        >
                            {{ role.viewValue }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button
                    *ngIf="element.role < dataservice.currentUser.role"
                    class="button-remove"
                    mat-raised-button
                    (click)="remove(element)"
                >
                    Elimina
                </button>
            </td>
            <td mat-cell *matFooterCellDef>
                <button mat-raised-button (click)="adduser()">Aggiungi</button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="select(row)"
            [ngClass]="{
                row_selected: row.idebacaUserId == selectedUser.idebacaUserId
            }"
        ></tr>

        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
</div>
