<h1 mat-dialog-title>Configura dispositivo</h1>

<div mat-dialog-content class="fields">
    <mat-tab-group>
        <mat-tab label="Configurazione generale">
            <mat-form-field class="fieldclass">
                <input matInput placeholder="Nome" [(ngModel)]="device.name" />
            </mat-form-field>

            <mat-checkbox [(ngModel)]="device.legacy">Legacy</mat-checkbox>
            <mat-form-field class="fieldclass">
                <mat-label>Tipo dispositivo</mat-label>
                <mat-select [(ngModel)]="device.deviceType">
                    <mat-option *ngFor="let dev of dataservice.deviceDefs" [value]="dev.type">
                        {{ dev.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="fieldclass" *ngIf="perm('MAC')">
                <input matInput placeholder="MAC Address" [(ngModel)]="device.mac" />
            </mat-form-field>
            <mat-form-field class="fieldclass small" *ngIf="perm('IDX')">
                <input matInput placeholder="Indirizzo" [(ngModel)]="devId" (change)="changeId()" />
            </mat-form-field>
            <mat-form-field class="field-full-width">
                <input matInput placeholder="OTA SSID" [(ngModel)]="device.ssid" />
            </mat-form-field>
            <mat-form-field class="field-full-width">
                <input matInput placeholder="OTA PASS" [(ngModel)]="device.pass" />
            </mat-form-field>
            <button mat-raised-button (click)="import()"><mat-icon>upload</mat-icon>Importa</button>
        </mat-tab>
        <mat-tab label="Configurazione dispositivo">
            <div class="devconf">
                <button mat-icon-button (click)="getCustomConfig()"><mat-icon>refresh</mat-icon></button>
                <app-dev190config *ngIf="device.deviceType === 190" [device]="device" [data]="customData"></app-dev190config>
            </div>
        </mat-tab>
        <mat-tab label="Dispositivi slave">
            <div class="devconf">
                <app-subdevices [device]="device"></app-subdevices>
            </div>
        </mat-tab>
    </mat-tab-group>
    <mat-divider></mat-divider>
    <div mat-dialog-footer>
        <button mat-raised-button (click)="confirm()">Aggiorna</button>
        <button mat-raised-button (click)="abort()">Annulla</button>
    </div>
</div>
