<mat-toolbar color="primary">
    <mat-toolbar-row class="toolbar">
        <div class="buttonlist">
            <div [matBadge]="dataservice.summary.clean" [matBadgeHidden]="dataservice.summary.clean === 0"
                matBadgeColor="warn" matBadgePosition="above after"><img class="icons" src="assets/scoop_white.svg" />
            </div>
            <div [matBadge]="dataservice.summary.notdisturb" [matBadgeHidden]="dataservice.summary.notdisturb === 0"
                matBadgeColor="warn" matBadgePosition="above after"><img class="icons"
                    src="assets/do-not-disturb_white.svg" /></div>
            <div [matBadge]="dataservice.summary.alarm" [matBadgeHidden]="dataservice.summary.alarm === 0"
                matBadgeColor="warn" matBadgePosition="above after"><mat-icon aria-hidden="false">notification_important</mat-icon>
            </div>

            <div class="alerticon" [matBadge]="dataservice.alerts.length" [matBadgeHidden]="dataservice.alerts.length === 0"
                matBadgeColor="warn" matBadgePosition="above after"><mat-icon aria-hidden="false">sms</mat-icon>
            </div>

        </div>
        <mat-icon aria-hidden="false" class="menubutton">menu</mat-icon>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <img *ngIf="dataservice.currentUser !== undefined" class="icons" src="assets/profile_white.svg" />
        <div>&nbsp;{{ dataservice.currentUser?.fullname }}</div>
        <div [ngClass]="getRoleStyle()">{{ getRoleName() }}</div>
        <div class="bullet" [ngClass]="{ 'bullet--good': dataservice.connected }"></div>
        <div class="connstat" *ngIf="dataservice.connected">Connesso al server</div>
        <div class="connstat" *ngIf="!dataservice.connected">Disconnesso dal server</div>
    </mat-toolbar-row>
</mat-toolbar>
