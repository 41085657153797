<div class="maincontainer solidborder" >
    <div class="maincontainer">
        <button class="insertbutton" (click)="btInsert('ARM')" [disabled]="btDisable()" mat-raised-button color="primary" *ngIf="getInsert()">Inserisci</button>
        <button class="insertbutton"(click)="btInsert('DISARM')" mat-raised-button color="warn" *ngIf="getUnInsert()">Disinserisci</button>
        <h4 class="partname">{{ device?.mac }} - {{ device?.serial_number }}</h4>
    </div>
    <div class="bulletcontainer">
        <!--<h4 class="alarm-label">{{ device?.mac }} - {{ device?.serial_number }}</h4>-->
        <div class="bullet bullet--Arm" [class]="getClass('Arm')">Arm</div>
        <div class="bullet bullet--Alarm" [class]="getClass('Alarm')">Alarm</div>
        <div class="bullet bullet--Trouble" [class]="getClass('Trouble')">Trouble</div>
        <div class="bullet bullet--Duress" [class]="getClass('Duress')">Duress</div>
        <div class="bullet bullet--FalseCoed" [class]="getClass('FalseCoed')">FalseCoed</div>
        <div class="bullet bullet--Fire" [class]="getClass('Fire')">Fire</div>
        <div class="bullet bullet--Panic" [class]="getClass('Panic')">Panic</div>
        <div class="bullet bullet--Medic" [class]="getClass('Medic')">Medic</div>
        <div class="bullet bullet--HomeStay" [class]="getClass('HomeStay')">HomeStay</div>
        <div class="bullet bullet--ReadytoArm" [class]="getClass('ReadytoArm')">ReadytoArm</div>
        <div class="bullet bullet--ExitOpenzoneisopen" [class]="getClass('ExitOpenzoneisopen')">ExitOpenzoneisopen</div>
        <div class="bullet bullet--Resetrequired" [class]="getClass('Resetrequired')">Resetrequired</div>
    </div>
</div>
