<table>
    <tr>
        <td class="txlabel">T3</td>
        <td class="txvalue" [ngClass]="{ txvalue__selected: item.value > 2 }" (click)="selT(item,3)" *ngFor="let item of timespan"></td>
    </tr>
    <tr>
        <td class="txlabel">T2</td>
        <td class="txvalue" [ngClass]="{ txvalue__selected: item.value > 1 }" (click)="selT(item,2)" *ngFor="let item of timespan"></td>
    </tr>
    <tr>
        <td class="txlabel">T1</td>
        <td class="txvalue" [ngClass]="{ txvalue__selected: item.value > 0 }" (click)="selT(item,1)" *ngFor="let item of timespan"></td>
    </tr>
    <tr>
        <td class="txlabel"><mat-icon aria-hidden="false">history_toggle_off</mat-icon></td>
        <td class="txlabel" *ngFor="let item of timespan">
            <div class="txlabel__hh" *ngIf="item.mm === 0">{{ item.hh }}</div>
            <div class="txlabel__mm" *ngIf="item.mm === 30">{{ 30 }}</div>
        </td>
    </tr>
</table>
