import { Component } from '@angular/core';
import { DataserviceService } from './dataservice.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'ipnetgreen-frontend';
    isopened = true;
    constructor(private dataservice: DataserviceService) {}

    getisopened() {
     //   console.log('innerw: ', window.innerWidth);
        if (window.innerWidth < 1000) {
            return this.isopened;
        }
        return true;
    }
}
