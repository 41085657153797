import { Component, OnInit, Input } from '@angular/core';
import { IPolicy, ICard } from 'src/interfaces';
import { RoomdetailsComponent } from './roomdetails/roomdetails.component';
import { MatDialog } from '@angular/material/dialog';
import { DataserviceService } from '../dataservice.service';

@Component({
    selector: 'app-room',
    templateUrl: './room.component.html',
    styleUrls: ['./room.component.scss']
})
export class RoomComponent implements OnInit {
    @Input() policy: IPolicy;
    @Input() card: ICard;
    constructor(public dialog: MatDialog, public dataservice: DataserviceService) {}

    ngOnInit() {}

    getStyle() {
        const retval = {} as any;
        if (this.policy.parameters && this.policy.parameters.INTCOD) {
            if (this.policy.parameters.INTCOD.value !== '00000000') {
                retval.borderTop = '10px solid #ccc';
                const card = this.dataservice.getCardByCode(this.policy.parameters.INTCOD.value);
                if (card.isPassepartout) {
                    retval.borderTop = '10px solid red';
                } else if (card.connectedRooms[this.policy.GUID] !== undefined) {
                    retval.borderTop = '10px solid lightblue';
                } else {
                    retval.borderTop = '10px solid orange';
                }
            }
        }

        return retval;
    }

    inroomstyle() {
        try {
            if (this.policy.parameters && this.policy.parameters.FLAGS) {
                const val = parseInt(this.policy.parameters.FLAGS.value, 16) & 0b110;
                switch (val) {
                    case 0:
                        return { display: 'none' };

                    case 2:
                        return { color: 'blue' };
                    case 4:
                        return { color: 'red' };
                }
            } else {
                return { display: 'none' };
            }
            return {};
        } catch (e) {
            console.log('Error: ', e);
        }
    }

    getLink(policy: IPolicy) {
        if (policy.parameters.LINK && policy.parameters.LINK.message) {
            const pol = this.dataservice.fields.find((x) => x.GUID == policy.parameters.LINK.message);
            return pol.name;
        }
    }
    getLinkEnabled(policy: IPolicy) {
        if (policy.parameters.LINK) {
            return policy.parameters.LINK.message != '';
        }
        return false;
    }

    winstyle() {
        try {
            if (this.policy.parameters && this.policy.parameters.FLAGS) {
                const val = parseInt(this.policy.parameters.FLAGS.value, 16) & 0b100000000000;
                switch (val) {
                    case 0:
                        return { display: 'none' };

                    case 2:
                        return { color: 'blue' };
                    case 4:
                        return { color: 'red' };
                }
            } else {
                return { display: 'none' };
            }
            return {};
        } catch (e) {
            console.log('Error: ', e);
        }
    }

    disturbstyle() {
        try {
            if (this.policy.parameters && this.policy.parameters.FLAGS) {
                const val = parseInt(this.policy.parameters.FLAGS.value, 16) & 0x80;
                // tslint:disable-next-line: no-bitwise
                if (val === 0) {
                    return { display: 'none' };
                }
            } else {
                return { display: 'none' };
            }
            return {};
        } catch (e) {
            console.log('Error: ', e);
        }
    }
    hwerror() {
        if (this.dataservice.meshDevces) {
            let offline = false;
            const pol = this.dataservice.systems.find((x) => x.guid === this.policy.GUID);
            if (pol) {
                if (pol.params) {
                    for (const para of pol.params) {
                        if (para) {
                            for (const dev of para.devices) {
                                const device = this.dataservice.meshDevces.find((x) => x.device_id === dev.device_id && x.root === pol.root);
                                if (device === undefined || !device.isOnline) {
                                    offline = true;
                                }
                            }
                        }
                    }
                } else {
                    console.warn('Policy without parameters', pol);
                }
            }
            if (!offline) {
                return { display: 'none' };
            }
        }
    }

    alarmstyle() {
        if (!this.dataservice.siteConfiguration.alarmEnabled) {
            return { display: 'none' };
        }

        try {
            if ((this.policy.parameters.ALARM && parseInt(this.policy.parameters.ALARM.value, 16)) === 0) {
                return { display: 'none' };
            }
            return { color: 'red' };
        } catch (e) {
            console.log('Error: ', e);
            return { display: 'none' };
        }
    }

    cleanstyle() {
        try {
            if (this.policy.parameters && this.policy.parameters.FLAGS) {
                const val = parseInt(this.policy.parameters.FLAGS.value, 16) & 0x40;
                // tslint:disable-next-line: no-bitwise
                if (val === 0) {
                    return { display: 'none' };
                }
            } else {
                return { display: 'none' };
            }
            return {};
        } catch (e) {
            console.log('Error: ', e);
        }
    }

    keystyle() {
        try {
            if (this.policy.parameters) {
                // tslint:disable-next-line: no-bitwise
                if (undefined === this.policy.parameters.FLAGS || (parseInt(this.policy.parameters.FLAGS.value, 16) & 0x20) === 0) {
                    return { display: 'none' };
                }
            } else {
                return { display: 'none' };
            }
            return {};
        } catch (e) {
            console.log('Error: ', e);
        }
    }

    openDetail() {
        const dialogRef = this.dialog.open(RoomdetailsComponent, {
            width: '1024px',
            height: '740px',
            data: { policy: this.policy, card: this.card }
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log('The dialog was closed');
        });
    }

    getCardNum() {
        const cards = this.dataservice.cards.filter((x) => x.connectedRooms[this.policy.GUID] !== undefined);
        if (cards) {
            let cardexit = 0;
            for (const card of cards) {
                const date = new Date(card.connectedRooms[this.policy.GUID].validTo);
                date.setHours(0, 0, 0, 0);
                const dtnow = new Date();
                dtnow.setHours(0, 0, 0, 0);
                if (date.getTime() === dtnow.getTime()) {
                    cardexit++;
                }
            }

            return cardexit;
        }
    }

    getTotalCardNum() {
        const cards = this.dataservice.cards.filter((x) => x.connectedRooms[this.policy.GUID] !== undefined);
        if (cards) {
            return cards.length.toString();
        }
    }
}
