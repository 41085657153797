import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, EventEmitter } from '@angular/core';
import { ICpuinfo } from 'src/interfaces';

@Component({
    selector: 'app-cpugraph',
    templateUrl: './cpugraph.component.html',
    styleUrls: ['./cpugraph.component.scss']
})
export class CpugraphComponent implements OnInit, AfterViewInit {
    @ViewChild('graph') el: ElementRef;
    @Input() data: ICpuinfo;
    @Input() dataChange: EventEmitter<any> = new EventEmitter();
    last32: number;
    last8: number;
    size32: number;
    size8: number;
    sizeint: number;
    lastint: number;
    ctx: any;
    constructor() {}

    ngOnInit() {}
    ngAfterViewInit() {
        this.ctx = this.el.nativeElement.getContext('2d');

        this.drawgraph();
        this.dataChange.subscribe(() => {
            this.drawgraph();
            console.log('Fired');
        });
    }

    drawgraph() {
        console.log('Data: ', this.data);
        this.ctx.clearRect(0, 0, 1024, 512);
        this.last32 = this.data.mem32[this.data.mem32.length - 1];
        this.last8 = this.data.mem8[this.data.mem8.length - 1];
        this.lastint = this.data.memint[this.data.memint.length - 1];
        this.size32 = this.data.mem32.length;
        this.size8 = this.data.mem8.length;
        this.sizeint = this.data.memint.length;
        let max32 = 0;
        let min32 = 10000000;
        let max8 = 0;
        let min8 = 10000000;
        let maxint = 0;
        let minint = 10000000;
        for (let i = 0; i < this.data.mem32.length; i++) {
            max32 = Math.max(max32, this.data.mem32[i]);
            max8 = Math.max(max8, this.data.mem8[i]);
            maxint = Math.max(maxint, this.data.memint[i]);

            min32 = Math.min(min32, this.data.mem32[i]);
            min8 = Math.min(min8, this.data.mem8[i]);
            minint = Math.min(minint, this.data.memint[i]);
        }
        const deltay8 = 400 / (max8 - min8 + 1);
        const deltay32 = 400 / (max32 - min32 + 1);
        const deltayint = 400 / (maxint - minint + 1);
        const position32 = (max32 + min32) / 2;
        const position8 = (max8 + min8) / 2;
        const positionint = (maxint + minint) / 2;
        this.ctx.beginPath();
        this.ctx.moveTo(0, this.data.mem32[0] / 20000);
        const delta = 1000 / this.data.mem32.length;
        let i = 0;
        this.ctx.strokeStyle = '#ff0000';
        this.ctx.lineWidth  = 5;
        for (const val of this.data.mem32) {
            this.ctx.lineTo(i * delta, 256 - (val - position32) * deltay32);
            i++;
        }
        this.ctx.stroke();

        this.ctx.beginPath();
        this.ctx.moveTo(0, this.data.mem8[0] / 20000);
        i = 0;
        this.ctx.strokeStyle = '#00ff00';
        this.ctx.lineWidth  = 3;

        for (const val of this.data.mem8) {
            this.ctx.lineTo(i * delta, 256 - (val - position8) * deltay8);

            i++;
        }
        this.ctx.stroke();

        this.ctx.beginPath();
        this.ctx.moveTo(0, this.data.memint[0] / 20000);
        i = 0;
        this.ctx.strokeStyle = '#ffff00';
        this.ctx.lineWidth  = 1;

        for (const val of this.data.memint) {
            this.ctx.lineTo(i * delta, 256 - (val - positionint) * deltayint);

            i++;
        }
        this.ctx.stroke();
    }
}
