import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { CardsComponent } from './cards/cards.component';
import { GuestsComponent } from './guests/guests.component';
import { LoginComponent } from './login/login.component';
import { EventlogsComponent } from './eventlogs/eventlogs.component';
import { NavmenuComponent } from './navmenu/navmenu.component';
import { MaterialModule } from './material-module';
import { RoomComponent } from './room/room.component';
import { RoomconfigComponent } from './roomconfig/roomconfig.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { RoomdetailsComponent } from './room/roomdetails/roomdetails.component';
import { AlarmwinComponent } from './alarmwin/alarmwin.component';
import { IdebuttComponent } from './idebutt/idebutt.component';
import { AssigncardComponent } from './assigncard/assigncard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// import { FlexLayoutModule } from '@angular/flex-layout';
import { ToastrModule } from 'ngx-toastr';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MomentDateAdapter, MOMENT_DATE_FORMATS } from './moment-date-adapter';
import { UsermanagerComponent } from './usermanager/usermanager.component';
import { MeshdeviceComponent } from './mesh/meshdevice/meshdevice.component';
import { MeshdeviceconfigComponent } from './mesh/meshdeviceconfig/meshdeviceconfig.component';
import { PolicyconfigComponent } from './mesh/policyconfig/policyconfig.component';
import { ModuleaddComponent } from './mesh/moduleadd/moduleadd.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PolicyparamComponent } from './mesh/policyparam/policyparam.component';
import { DeviceconfigComponent } from './mesh/deviceconfig/deviceconfig.component';
import { EditpolicyconfComponent } from './editpolicyconf/editpolicyconf.component';
import { ConfirmdialogComponent } from './confirmdialog/confirmdialog.component';
import { MeshgraphComponent } from './mesh/meshgraph/meshgraph.component';
import { RoomlistComponent } from './roomlist/roomlist.component';
import { LogsComponent } from './logs/logs.component';
import { SysmonComponent } from './sysmon/sysmon.component';
import { CpugraphComponent } from './sysmon/cpugraph/cpugraph.component';
import { DomoticComponent } from './domotic/domotic.component';
import { OnoffComponent } from './domotic/onoff/onoff.component';
import { UpdownComponent } from './domotic/updown/updown.component';
import { SceneryComponent } from './scenery/scenery.component';
import { ProfileComponent } from './profile/profile.component';
import { AdddeviceComponent } from './adddevice/adddevice.component';
import { RiscoalarmComponent } from './domotic/riscoalarm/riscoalarm.component';
import { AlarmpanelComponent } from './domotic/riscoalarm/alarmpanel/alarmpanel.component';
import { ZoneComponent } from './domotic/riscoalarm/alarmpanel/zone/zone.component';
import { PartitionComponent } from './domotic/riscoalarm/alarmpanel/partition/partition.component';
import { SystemComponent } from './domotic/riscoalarm/alarmpanel/system/system.component';
import { ScenitemComponent } from './domotic/scenitem/scenitem.component';
import { SceneryschedulerComponent } from './sceneryscheduler/sceneryscheduler.component';
import { AlertsComponent } from './alerts/alerts.component';
import { AppconfigurationComponent } from './appconfiguration/appconfiguration.component';
import { DoorPhoneComponent } from './domotic/door-phone/door-phone.component';
import { DoorPhoneWindowComponent } from './domotic/door-phone/door-phone-window/door-phone-window.component';
import { TprogComponent } from './tprog/tprog.component';
import { Config190Component } from './config190/config190.component';
import { Toggle3stateComponent } from './toggle3state/toggle3state.component';
import { SiteconfigComponent } from './siteconfig/siteconfig.component';
import { DeviceotasettingsComponent } from './deviceotasettings/deviceotasettings.component';
import { ImportcardsComponent } from './importcards/importcards.component';
import { Dev190configComponent } from './dev190config/dev190config.component';
import { FunctioncomboComponent } from './functioncombo/functioncombo.component';
import { SubdevicesComponent } from './mesh/subdevices/subdevices.component';
import { PowermeterComponent } from './domotic/powermeter/powermeter.component';
import { PowerchartComponent } from './powerchart/powerchart.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { InputbuilderComponent } from './inputbuilder/inputbuilder.component';
import { DomoticlogicalComponent } from './domoticlogical/domoticlogical.component';
import { WallbuttonsComponent } from './domoticlogical/wallbuttons/wallbuttons.component';
import { BtconfigComponent } from './btconfig/btconfig.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
const appearance: MatFormFieldDefaultOptions = {
    appearance: 'outline'
};
@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        CardsComponent,
        GuestsComponent,
        LoginComponent,
        EventlogsComponent,
        NavmenuComponent,
        RoomComponent,
        RoomconfigComponent,
        ToolbarComponent,
        RoomdetailsComponent,
        AlarmwinComponent,
        IdebuttComponent,
        AssigncardComponent,
        UsermanagerComponent,
        MeshdeviceComponent,
        MeshdeviceconfigComponent,
        PolicyconfigComponent,
        ModuleaddComponent,
        PolicyparamComponent,
        DeviceconfigComponent,
        EditpolicyconfComponent,
        ConfirmdialogComponent,
        MeshgraphComponent,
        RoomlistComponent,
        LogsComponent,
        SysmonComponent,
        CpugraphComponent,
        DomoticComponent,
        OnoffComponent,
        UpdownComponent,
        SceneryComponent,
        ProfileComponent,
        AdddeviceComponent,
        AdddeviceComponent,
        RiscoalarmComponent,
        AlarmpanelComponent,
        ZoneComponent,
        PartitionComponent,
        SystemComponent,
        ScenitemComponent,
        SceneryschedulerComponent,
        AlertsComponent,
        AppconfigurationComponent,
        DoorPhoneComponent,
        DoorPhoneWindowComponent,
        TprogComponent,
        Config190Component,
        Toggle3stateComponent,
        SiteconfigComponent,
        DeviceotasettingsComponent,
        ImportcardsComponent,
        Dev190configComponent,
        FunctioncomboComponent,
        SubdevicesComponent,
        PowermeterComponent,
        PowerchartComponent,
        InputbuilderComponent,
        DomoticlogicalComponent,
        WallbuttonsComponent,
        BtconfigComponent
    ],
    imports: [
        FormsModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        HttpClientModule,
        //  FlexLayoutModule,
        ReactiveFormsModule,
        ToastrModule.forRoot(),
        NgxMaterialTimepickerModule,
        NgxChartsModule,
    ],
    providers: [
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: [] },
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        { provide: MAT_DATE_FORMATS, useValue: MOMENT_DATE_FORMATS },
        { provide: DateAdapter, useClass: MomentDateAdapter },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: appearance
        }
    ],
    bootstrap: [AppComponent],
 //   entryComponents: [SceneryschedulerComponent, AlarmpanelComponent, RoomdetailsComponent, AlarmwinComponent, AssigncardComponent, ModuleaddComponent, DeviceconfigComponent, ConfirmdialogComponent, MeshgraphComponent, RoomlistComponent, AdddeviceComponent]
})
export class AppModule {}
