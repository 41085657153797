<div class="polcontainer poltappa">
    <h4 class="title">{{policy.name}}</h4>
    <div class="tappbutt" [ngClass]="getClass('UP')" (click)="butclick('1')">
        <mat-icon class="tappicos" aria-hidden="false">vertical_align_top</mat-icon>
    </div>
    <div class="stopbutt"
        (click)="butclick('0')">
        <mat-icon class="tappicos" aria-hidden="false">stop</mat-icon>
    </div>
    <div class="tappbutt" [ngClass]="getClass('DOWN')" (click)="butclick('2')">
        <mat-icon class="tappicos" aria-hidden="false">vertical_align_bottom</mat-icon>
    </div>
</div>