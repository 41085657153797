<div class="dialog-content">
    <h1 mat-dialog-title>{{ policy.name }}</h1>
    <div mat-dialog-content>
        <mat-tab-group>
            <mat-tab label="Centrale">
                <div *ngFor="let param of getParams('SYSTEM')">
                    <app-system [parameter]="param" [policy]="policy"></app-system>

                </div>
            </mat-tab>
            <mat-tab label="Partizioni">
                <div *ngFor="let param of getParams('PART', 'Exist')">
                    <app-partition [parameter]="param" [policy]="policy"></app-partition>
                </div>
            </mat-tab>
            <mat-tab label="Zone">
                <hr />

                <div *ngFor="let param of getParams('ZONE', 'Notused', true)">
                    <app-zone [parameter]="param" [policy]="policy"></app-zone>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div class="spacer"></div>
    <div mat-dialog-actions>
        <button mat-raised-button (click)="close()" cdkFocusInitial>Chiudi</button>
        <div class="spacer"></div>

        <button mat-raised-button color="primary" (click)="insertAll()">Inserisci totale</button>
        <button mat-raised-button color="warn" (click)="excludeAll()">Disinserisci totale</button>
    </div>
</div>
