import { DataserviceService } from 'src/app/dataservice.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deviceotasettings',
  templateUrl: './deviceotasettings.component.html',
  styleUrls: ['./deviceotasettings.component.scss']
})
export class DeviceotasettingsComponent implements OnInit {

  constructor(public dataservice: DataserviceService) { }

  ngOnInit(): void {
   // this.dataservice.deviceFilter.pass = this.dataservice.
  }

}
