import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-toggle3state',
    templateUrl: './toggle3state.component.html',
    styleUrls: ['./toggle3state.component.scss']
})
export class Toggle3stateComponent implements OnInit {
    @Input() value: number;
    @Output() valueChanged = new EventEmitter<number>();
    constructor() {}
    setValue(value) {
      this.valueChanged.emit(value);
    }
    ngOnInit(): void {}
}
