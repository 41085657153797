import { Component, OnInit } from '@angular/core';
import { ErrorMessageLevel, IServerMessage, MessageType } from 'src/interfaces';
import { DataserviceService } from '../dataservice.service';

@Component({
    selector: 'app-alerts',
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {
    constructor(public dataservice: DataserviceService) {}

    ngOnInit(): void {}
    getLevel(item: IServerMessage) {
        switch (item.level) {
            case ErrorMessageLevel.WARNING:
                return 'notification_important';
            case ErrorMessageLevel.ERROR:
                return 'error_outline';
            case ErrorMessageLevel.CRITICAL:
                return 'error';
            default:
                break;
        }
    }
    getMessage(item: IServerMessage) {
        switch (item.messageType) {
            case MessageType.CPUCONFIGURATION:
                return 'La CPU ' + item.deviceId.toString() + ' [' + item.cpu + '] ha una configurazione errata';
                break;

            default:
                break;
        }
    }
}
