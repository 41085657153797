<h1 mat-dialog-title>Aggiungi dispositivo</h1>
<div mat-dialog-content>
    <mat-form-field class="fieldclass">
        <mat-label>CPU</mat-label>
        <mat-select [(ngModel)]="device.root">
            <mat-option *ngFor="let root of dataservice.cpus" [value]="root">
                {{ root }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-divider></mat-divider>
    <mat-form-field class="fieldclass">
        <mat-label>Elemento di sistema</mat-label>
        <mat-select [(ngModel)]="device.deviceType">
            <mat-option *ngFor="let device of dataservice.deviceDefs" [value]="device.type">
                {{ device.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="fieldclass">
        <mat-label>Tipo di connessione</mat-label>
        <mat-select [(ngModel)]="device.communicationType">
            <mat-option *ngFor="let comm of dataservice.commTypes; let i = index" [value]="i">
                {{ comm }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-checkbox *ngIf="device.communicationType === 0" [(ngModel)]="device.legacy">Legacy</mat-checkbox>
    <mat-form-field class="fieldclass">
        <input matInput placeholder="MAC Address" [(ngModel)]="device.mac" />
    </mat-form-field>
    <mat-form-field class="fieldclass small">
        <input  matInput placeholder="Indirizzo" [(ngModel)]="device.device_id" />
    </mat-form-field>
     <mat-checkbox [(ngModel)]="convhex">HEX</mat-checkbox>

    <mat-divider></mat-divider>
</div>
<div mat-dialog-actions class="flexbuttons">
    <button mat-raised-button (click)="addDevice()">Aggiungi</button>
    <button mat-raised-button (click)="cancel()">Annulla</button>

</div>

