<div class="mainpage">
    <div class="tabcontainer">
        <h2 class="tabtitle">Configurazione sistema</h2>
        <mat-form-field  appearance="outline">
            <mat-label>Root CPU</mat-label>
            <mat-select [(ngModel)]="dataservice.currentRoot" (ngModelChange)="modelCahange()">
                <mat-option *ngFor="let root of getRoots()" [value]="root">
                    {{ root }}
                </mat-option>
                <mat-option value="All">Mostra tutti</mat-option>
            </mat-select>
        </mat-form-field>
        <app-idebutt class="btpol" text="ELIMINA" icon="delete" (click)="erase()"></app-idebutt>
        <app-idebutt class="btpol" text="SALVA" icon="save" (click)="update()"></app-idebutt>
        <app-idebutt class="btpol" text="TEST" icon="bug_report" (click)="testSystem()"></app-idebutt>
        <app-idebutt class="btpol" text="AGGIORNA" icon="save_alt" (click)="updateSystem()"></app-idebutt>
    </div>
    <section class="sect">
        <div class="pollist">
            <mat-form-field  appearance="outline">
                <mat-label>Elemento di sistema</mat-label>
                <mat-select [(ngModel)]="policyAdd">
                    <mat-option *ngFor="let policy of dataservice.policiesDefs" [value]="policy"> <img class="iconpol" [src]="getPolicyIcon(policy)" /> {{ policy.name }} </mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-icon-button aria-hidden="true" (click)="addPolicy()">
                <mat-icon>add</mat-icon>
            </button>
            <div class="policycontainer">
                <div *ngFor="let item of dataservice.getSystems()" class="pitem" [ngClass]="{ 'pitem-sel': currentPolicy === item }" [ngStyle]="getStatus(item)" (click)="selectPol(item)">
                    <div class="name"><img class="iconpol" [src]="'assets/icons/' + item.icon + '.svg'" />{{ item.name }}</div>
                    <div class="pname">{{ item.policyName }}</div>
                    <div class="pname">{{ item.root }}</div>
                </div>
            </div>
        </div>
        <div class="polcontent" *ngIf="currentPolicy">
            <div class="paramcontainer">
                <div *ngIf="!nameedit && !invocationedit" class="polhead">
                    <h2 class="tabtitle">{{ currentPolicy.name }}</h2>
                    <button mat-icon-button aria-hidden="false" (click)="nameedit = true">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <h2 class="tabinvocation">[{{ currentPolicy.invocation }}]</h2>
                    <button mat-icon-button aria-hidden="false" (click)="invocationedit = true">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button aria-hidden="false" (click)="delPol(currentPolicy)">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <mat-form-field  appearance="outline">
                        <mat-label>Root CPU</mat-label>
                        <mat-select [(ngModel)]="currentPolicy.root">
                            <mat-option *ngFor="let root of getRoots()" [value]="root">
                                {{ root }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="iconsdiv">
                        <img class="iconpol" *ngFor="let icon of currentPolicySpec.icons" [ngClass]="{ 'iconpol-selected': currentPolicy.icon === icon }" (click)="currentPolicy.icon = icon" [src]="'assets/icons/' + icon + '.svg'" />
                    </div>
                </div>

                <div *ngIf="nameedit" class="polhead">
                    <mat-form-field class="areafield">
                        <input matInput placeholder="Nome elemento" [(ngModel)]="currentPolicy.name" />
                    </mat-form-field>
                    <button mat-icon-button aria-hidden="false" (click)="updatePolicy()">
                        <mat-icon>done</mat-icon>
                    </button>
                </div>

                <div *ngIf="invocationedit" class="polhead">
                    <mat-form-field class="areafield">
                        <input matInput placeholder="Nome SmartHome" [(ngModel)]="currentPolicy.invocation" />
                    </mat-form-field>
                    <button mat-icon-button aria-hidden="false" (click)="updatePolicy()">
                        <mat-icon>done</mat-icon>
                    </button>
                </div>
                <div class="configurations">
                    <div *ngFor="let config of currentPolicySpec.configurations; let ci = index">
                        <span>{{ config.label }}</span>
                        <mat-form-field  appearance="outline" class="params_input">
                            <input matInput type="text" [placeholder]="config.label" [(ngModel)]="currentPolicy.configurations[config.index]" />
                        </mat-form-field>
                        <span>{{ config.label_scale }}</span>
                    </div>
                </div>
                <mat-tab-group>
                    <div class="param" *ngFor="let item of currentPolicySpec.params; let i = index">
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <mat-icon class="tab-icon">view_quilt</mat-icon>
                                {{ item.name }}
                            </ng-template>
                            <div class="polparheader">
                                <div>{{ item.name }}</div>
                                <button mat-icon-button aria-hidden="false" (click)="addDevice(i)">
                                    <mat-icon>library_add</mat-icon>
                                </button>
                                <button mat-button aria-hidden="false" (click)="readDevice(i)" [ngClass]="{ 'aquire-enabled': currentAcquire === i }"><mat-icon>flip_camera_android</mat-icon>
                                  &nbsp;Acquisisci</button>
                                <!-- <div *ngFor="let conf of item.inoutlogic; let j = index">
                            <mat-form-field *ngIf="getInOutRelationName(i, j) === 'ANDOR'">
                                <mat-label>Relazione ingressi</mat-label>
                                <mat-select [(value)]="currentPolicy.params[i].inoulogic[j]">
                                    <mat-option [value]="0">OR</mat-option>
                                    <mat-option [value]="1">AND</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>-->
                            </div>
                            <div class="paramcontainer">
                                <app-policyparam *ngFor="let item of currentPolicy.params[i]?.devices; let j = index" [policy]="currentPolicy" (delete)="ondelete(j, currentPolicy.params[i].devices)" [device]="item" [deviceParamType]="dataservice.getPolycySpec(currentPolicy).params[i]"></app-policyparam>
                            </div>
                        </mat-tab>
                    </div>
                    <div class="param" *ngFor="let item of currentPolicySpec.events; let i = index">
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <mat-icon class="tab-icon">room_preferences</mat-icon>
                                {{ item.Label }}
                            </ng-template>

                            <!-- policy Scenery -->
                            <div class="param">
                                <div class="polparheader">
                                    <div>Azioni&nbsp;</div>
                                    <mat-form-field  appearance="outline">
                                        <mat-label>Policy di sistema</mat-label>
                                        <mat-select [(ngModel)]="polSceneryAdd">
                                            <mat-option *ngFor="let policy of dataservice.systems" [value]="policy">
                                                {{ policy.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <button mat-icon-button aria-hidden="false" (click)="addPolicyScenery(item)">
                                        <mat-icon>library_add</mat-icon>
                                    </button>
                                </div>
                                <div class="paramcontainer">
                                    <hr />
                                    <table class="poltable">
                                        <tr>
                                            <th>Policy</th>
                                            <th>Ritardo attivazione</th>
                                            <th>Azione</th>
                                            <th>Elimina</th>
                                        </tr>
                                        <tr *ngFor="let sitem of getScenery(item); let j = index">
                                            <!-- (delete)="onActionDelete(j)"-->
                                            <td>{{ dataservice.getPolicyFromGuid(sitem.policy) }}</td>
                                            <td>
                                                <mat-form-field  appearance="outline">
                                                    <input matInput placeholder="Ritardo" [(ngModel)]="sitem.delay" />
                                                </mat-form-field>
                                            </td>
                                            <td>
                                                <span class="actionitem" *ngFor="let action of actions[sitem.policy]" (click)="selAction(sitem, action)" [ngClass]="{ 'action-selected': sitem.value === action.value && sitem.action === action.action }">
                                                    {{ action.label }}
                                                </span>
                                            </td>
                                            <td>
                                                <mat-icon aria-hidden="false" (click)="deletePolicy(item, j)" class="actiondelete">delete</mat-icon>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </mat-tab>
                    </div>
                </mat-tab-group>
            </div>
        </div>
    </section>
</div>
