import { Component, Input, OnInit } from '@angular/core';
import { DataserviceService } from 'src/app/dataservice.service';
import { IMeshDevice, IParameter, IPolicy } from 'src/interfaces';

@Component({
    selector: 'app-zone',
    templateUrl: './zone.component.html',
    styleUrls: ['./zone.component.scss']
})
export class ZoneComponent implements OnInit {
    @Input() parameter: IParameter;
    @Input() policy: IPolicy;

    device: IMeshDevice;

    constructor(public dataservice: DataserviceService) {}

    ngOnInit(): void {
        const id = parseInt(this.parameter.code.split('_')[1], 10);
        const device = this.dataservice.meshDevces.find((x) => x.device_id === id);
        this.device = device;
    }

    getClass(key: string) {
        if (this.parameter.message) {
            return this.parameter.message[key] ? '' : 'bullet--disabled';
        }
        return 'bullet--disabled';
    }
    getExcluded() {
        return true;
    }

    getIncluded() {
        return true;
    }

    btInclude(action: string) {
        const data = {
            value: this.parameter.message.did,
            action
        };
        console.log('Zone bypass');
        this.dataservice.sendMessageToServer(this.policy, 'INCZONE', data);
    }

    btDisable() {
        if (this.parameter.message) {
            return this.parameter.message.Bypass;
        }
        return false;
    }
}
