import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-functioncombo',
    templateUrl: './functioncombo.component.html',
    styleUrls: ['./functioncombo.component.scss']
})
export class FunctioncomboComponent implements OnInit {
    @Input() items: string[];
    @Input() value: number;
    @Input() label: string;
    @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();

    constructor() {}

    ngOnInit() {}

    onChange(event) {
        this.valueChange.emit(this.value);
    }
}
