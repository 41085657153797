import { DataserviceService } from 'src/app/dataservice.service';
import { Component, OnInit } from '@angular/core';
import { ISysLog } from 'src/interfaces';

@Component({
    selector: 'app-logs',
    templateUrl: './logs.component.html',
    styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {
    logs: ISysLog[];
    key: string;
    displayedColumns = ['data', 'element', 'description'];
    showspin = false;
    constructor(public dataservice: DataserviceService) {}

    ngOnInit() {}

    selLog(key: string) {
        this.logs = [];
        this.showspin = true;
        this.dataservice.getLOG(key).subscribe(
            (data) => {
                this.key = key;
                this.logs = data as ISysLog[];
                this.showspin = false;
            },
            () => {
                this.showspin = false;
                this.dataservice.toastr.error('Errore durante la ricezione dei LOG');
            }
        );
    }

    getPolicy(guid: string) {
        return this.dataservice.getPolicyFromGuid(guid);
    }
}
