import { DataserviceService } from 'src/app/dataservice.service';
import { IMeshDevice, IRoomConfig } from 'src/interfaces';
import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-dev190config',
    templateUrl: './dev190config.component.html',
    styleUrls: ['./dev190config.component.scss']
})
export class Dev190configComponent implements OnInit, OnChanges {
    @Input() device: IMeshDevice;
    @Input() data: number[];

    config: IRoomConfig;
    constructor(public dataservice: DataserviceService, private zone: NgZone) {
        this.config = {
            din: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            dout: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            intr: false,
            extr: false,
            espin: false,
            espout: false,
            keyb: false
        };
    }
    ngOnChanges(changes: SimpleChanges) {
        console.log('Change: ', changes);
        if (changes.data !== undefined) {
            console.log('Custom data evt', changes.data.currentValue);

            for (let i = 0; i < 16; i++) {
                this.config.din[i] = changes.data.currentValue[i];
                this.config.dout[i] = changes.data.currentValue[i + 16];
            }

            this.config.intr = (changes.data.currentValue[32] & 1) != 0;
            this.config.extr = (changes.data.currentValue[32] & 2) != 0;
            this.config.espin = (changes.data.currentValue[32] & 4) != 0;
            this.config.espout = (changes.data.currentValue[32] & 8) != 0;
            this.config.keyb = (changes.data.currentValue[32] & 16) != 0;
        }
    }
    ngOnInit() {}

    saveCustom() {
        const data: number[] = [];
        for (let i = 0; i < 16; i++) {
            data.push(this.config.din[i]);
        }
        for (let i = 0; i < 16; i++) {
            data.push(this.config.dout[i]);
        }
        let flag = 0;
        flag |= this.config.intr ? 1 : 0;
        flag |= this.config.extr ? 2 : 0;
        flag |= this.config.espin ? 4 : 0;
        flag |= this.config.espout ? 8 : 0;
        flag |= this.config.keyb ? 16 : 0;
        data.push(flag);

        console.log('Todev:', data);
        this.dataservice.setDeviceCustomConfig(this.device, 0x54, data).subscribe(
            () => {
                this.dataservice.toastr.success('Salvataggio eseguito');
            },
            () => {
                this.dataservice.toastr.error('Errore durante il salvataggio');
            }
        );
    }

    saveCustomAll() {
        const data: number[] = [];
        for (let i = 0; i < 16; i++) {
            data.push(this.config.din[i]);
        }
        for (let i = 0; i < 16; i++) {
            data.push(this.config.dout[i]);
        }
        let flag = 0;
        flag |= this.config.intr ? 1 : 0;
        flag |= this.config.extr ? 2 : 0;
        flag |= this.config.espin ? 4 : 0;
        flag |= this.config.espout ? 8 : 0;
        flag |= this.config.keyb ? 16 : 0;
        data.push(flag);

        console.log('Todev:', data);
        this.dataservice.setDeviceCustomConfigMultiple(this.device, 0x54, data).subscribe(
            () => {
                this.dataservice.toastr.success('Salvataggio eseguito');
            },
            () => {
                this.dataservice.toastr.error('Errore durante il salvataggio');
            }
        );
    }
}
