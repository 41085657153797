import { Component, OnInit, Input } from '@angular/core';
import { IPolicy } from 'src/interfaces';
import { DataserviceService } from "src/app/dataservice.service";

@Component({
  selector: 'app-onoff',
  templateUrl: './onoff.component.html',
  styleUrls: ['./onoff.component.scss']
})
export class OnoffComponent implements OnInit {

  constructor(public dataservice: DataserviceService) { }
  @Input() policy: IPolicy;
  ngOnInit() {
  }

  getstyle() {
    if (this.isOn()) {
      return { backgroundColor: '#ffff0020', borderColor: 'yellow' , color: 'gold'};
    } else {
      return { backgroundColor: '#00000010' };
    }
  }

  isOn() {
    return this.policy.parameters.OUT.value !== '00000000' && this.policy.parameters.OUT.value !== 0;
  }

  statusclick() {
    if (this.isOn()) {
      this.dataservice.sendmessage(this.policy, 'SET', '00');

    } else {
      this.dataservice.sendmessage(this.policy, 'SET', 'ff');

    }
  }

}
