<div class="tabcontainer">
    <h2 class="tabtitle">Profilo</h2>
    <hr />
    <mat-form-field class="field-full-width">
        <input matInput placeholder="Nome utente"  [(ngModel)]="editedUser.fullname"  />
    </mat-form-field>
    <mat-form-field class="field-full-width">
        <input matInput placeholder="Email" [(ngModel)]="editedUser.email" />
    </mat-form-field>
    <mat-form-field class="field-full-width">
        <input matInput placeholder="Password corrente" type="password" [(ngModel)]="editedUser.oldpassword" />
    </mat-form-field>
    <mat-form-field class="field-full-width">
        <input matInput placeholder="Nuova password" type="password" [(ngModel)]="editedUser.newpassword" />
    </mat-form-field>
    <mat-form-field class="field-full-width">
        <input matInput placeholder="Conferma nuova password" type="password" [(ngModel)]="confirmPassword" />
    </mat-form-field>
    <app-idebutt [disabled]="!validate()" class="ideb" text="SAVE"  icon="account_balance_wallet" (click)="saveProfile()">
    </app-idebutt>
</div>