<h1>Scenario <b>{{ scenery.name }}</b></h1>
<app-idebutt style="margin-right: 20px" class="confirm" text="ESEGUI SUBITO" icon="send" (click)="sendNow()"></app-idebutt>
<app-idebutt  [checked]="schedulerData(1)" style="margin-right: 20px" class="confirm" text="UNA VOLTA" icon="schedule" (click)="schedulerSet(1)"></app-idebutt>
<app-idebutt  [checked]="schedulerData(2)" style="margin-right: 20px" class="confirm" text="RIPETI" icon="schedule" (click)="schedulerSet(2)"></app-idebutt>
<div class="daytab">
    <app-idebutt [checked]="getDay('1')" class="confirm" text="LUN" icon="schedule" (click)="toggleDay('1')"></app-idebutt>
    <app-idebutt [checked]="getDay('2')" class="confirm" text="MAR" icon="schedule" (click)="toggleDay('2')"></app-idebutt>
    <app-idebutt [checked]="getDay('3')" class="confirm" text="MER" icon="schedule" (click)="toggleDay('3')"></app-idebutt>
    <app-idebutt [checked]="getDay('4')" class="confirm" text="GIO" icon="schedule" (click)="toggleDay('4')"></app-idebutt>
    <app-idebutt [checked]="getDay('5')" class="confirm" text="VEN" icon="schedule" (click)="toggleDay('5')"></app-idebutt>
    <app-idebutt [checked]="getDay('6')" class="confirm" text="SAB" icon="schedule" (click)="toggleDay('6')"></app-idebutt>
    <app-idebutt [checked]="getDay('0')" class="confirm" text="DOM" icon="schedule" (click)="toggleDay('0')"></app-idebutt>
</div>
<div class="timeblock">
    <span>Alle ore: </span>
    <input [ngxTimepicker]="picker" [value]="this.scenery?.time.split('_')[0]" [format]="24"  readonly/>
    <ngx-material-timepicker #picker (timeChanged)="timeChanged($event)"></ngx-material-timepicker>
</div>
<div class="daytab">
    <button mat-raised-button class="confirm"  (click)="close()">Chiudi</button>
    <button mat-raised-button class="confirm"  (click)="update()">Aggiorna</button>
</div>
