<div class="mainpage">
    <div class="tabcontainer">
        <h2 class="tabtitle">LOG Eventi</h2>
        <div class="buttheader">
            <button mat-raised-button [color]="key==='ALARM'?'primary':''" (click)="selLog('ALARM')">Allarmi</button>
            <button mat-raised-button [color]="key==='ACCESS'?'primary':''" (click)="selLog('ACCESS')">Ingressi</button>
        </div>
        <div class="spinner" *ngIf="showspin">
          <mat-spinner></mat-spinner>
        </div>
        <table mat-table [dataSource]="logs" class="mat-elevation-z8">
            <ng-container matColumnDef="data">
                <th mat-header-cell *matHeaderCellDef>Data</th>
                <td mat-cell *matCellDef="let element">{{ element.timestamp | date: 'dd/MM/yyy HH:mm:ss' }}</td>
            </ng-container>

            <ng-container matColumnDef="element">
                <th mat-header-cell *matHeaderCellDef>Ambito</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.eventPol }}
                </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>Descrizione</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.eventDescription }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
</div>
