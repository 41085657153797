import { IMeshDevice } from 'src/interfaces';
import { DataserviceService } from 'src/app/dataservice.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-subdevices',
    templateUrl: './subdevices.component.html',
    styleUrls: ['./subdevices.component.scss']
})
export class SubdevicesComponent implements OnInit {
    @Input() device: IMeshDevice;
    devices: IMeshDevice[] = [];
    loading = false;
    constructor(public dataservice: DataserviceService) {}

    ngOnInit(): void {}
    getCustomSubConfig() {
        this.loading = true;

        const ids = [0xc0, 0xc1, 0xc2, 0xc3, 0xc4, 0xc5, 0xc6];
        this.dataservice.getDeviceCustomConfigMultiReg(this.device, ids, 13).subscribe(
            (data) => {
                this.loading = false;

                this.devices = [];
                console.log('rec data: ', data);

                for (const key in data) {
                    let mac = '';
                    for (let i = 0; i < 6; i++) {
                        if (data[key][i] < 16) {
                            mac += '0';
                        }
                        mac += data[key][i].toString(16);
                    }
                    const dev: IMeshDevice = {
                        device_id: data[key][7],
                        deviceType: data[key][9],
                        isOnline: (data[key][10] & 1) === 0,
                        deleted: (data[key][10] & 2) !== 0,
                        fwVersion: data[key][11] + (data[key][12] << 8),
                        mac: mac.toUpperCase()
                    } as IMeshDevice;
                    console.log('New device', dev);
                    if (!dev.deleted) {
                        this.devices.push(dev);
                    }
                }
            },
            () => {
                this.loading = false;
                this.dataservice.toastr.error('Errore');
            }
        );
    }

    getSubDevices() {
        return this.devices;
    }

    getFw(item: IMeshDevice) {
        const fws = this.dataservice.firmwares.find((x) => x.device == item.deviceType);
        return '1.' + fws.firmwares[fws.firmwares.length - 1].toString();
    }

    onUpgrade(item: IMeshDevice) {
        console.log('Start upgrade');
        const fws = this.dataservice.firmwares.find((x) => x.device == item.deviceType);
        const fw = fws.firmwares[fws.firmwares.length - 1];
        this.dataservice.setDeviceCustomConfig(this.device, 0x41, [item.device_id, fw & 0xff, (fw >> 8) & 0xff]).subscribe(
            () => {
                this.dataservice.toastr.success('Comando inviato');
                this.loading = false;
            },
            () => {
                this.dataservice.toastr.error('Errore');
            }
        );
    }

    getType(id: number) {
        if (id === 0) {
            return 'LEGACY';
        }
        const dev = this.dataservice.getDeviceSepc(id);

        if (dev) {
            return dev.name + ' ' + this.dataservice.commTypes[this.device.communicationType];
        }
        return 'unknown';
    }
}
