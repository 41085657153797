import { Component, OnInit, Inject } from '@angular/core';
import { DataserviceService } from 'src/app/dataservice.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IParameterSpecification, IMeshDevice, IParameterParamType } from 'src/interfaces';

@Component({
    selector: 'app-moduleadd',
    templateUrl: './moduleadd.component.html',
    styleUrls: ['./moduleadd.component.scss']
})
export class ModuleaddComponent implements OnInit {
    devmac: string;
    deviceAdd: IParameterSpecification;
    deviceComm = 0;
    deviceId = 255;
    root: string;
    filter = '';

    constructor(public dataservice: DataserviceService, public dialogRef: MatDialogRef<ModuleaddComponent>, @Inject(MAT_DIALOG_DATA) public data: { param: IParameterParamType; root: string }) {
        // this.policyType = this.dataservice.getPolycySpec(data);
        this.root = this.data.root;
    }
    addPolicy() {
        if (this.deviceAdd) {
            try {
                const dev = this.dataservice.meshDevces.find((x) => x.device_id === this.deviceId);
                if (dev) {
                    this.dataservice.toastr.error('Dispositivo già presente');
                    return;
                }
                const device = {} as IMeshDevice;
                device.mac = '';
                device.device_id = this.deviceId;
                device.deviceType = this.deviceAdd.type;
                device.communicationType = this.deviceComm;
                device.fwVersion = '???';
                device.parent = '------';
                device.isOnline = false;
                this.dataservice.addMeshDevice(device);
            } catch {
                this.dataservice.toastr.error('Impossibile inserire il dispositivo');
            }
        }
    }
    ngOnInit() {}
    isForbid(device: IMeshDevice) {
        const devinfo = this.dataservice.getDeviceSepc(device.deviceType);
        if (undefined === devinfo) {
            return false;
        }
        const forbid = !devinfo.features.includes(this.data.param.required);
        return forbid;
    }

    addModule(dev: IMeshDevice) {
        this.dialogRef.close(dev);
    }

    perm(cat: string): boolean {
        if (this.deviceAdd) {
            return this.dataservice.getPerm(this.deviceAdd.type, this.deviceComm, cat);
        }
        return false;
    }

    getFilter(dev: IMeshDevice) {
        if (this.filter.trim() === '') {
            return true;
        }
        if (undefined !== dev.serial_number && dev.serial_number.indexOf(this.filter) !== -1) {
            return true;
        }
        if (undefined !== dev.mac && dev.mac.indexOf(this.filter) !== -1) {
            return true;
        }
        if (dev.device_id === parseInt(this.filter, 10)) {
            return true;
        }

        if (dev.device_id === parseInt(this.filter, 16)) {
            return true;
        }
        return false;
    }
}
