import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IParameterInfo, IMeshDevice, IParameterSpecification, IParameterParamType, IParameterSpecificationFeature, IPolicy } from 'src/interfaces';
import { DataserviceService } from 'src/app/dataservice.service';

@Component({
    selector: 'app-policyparam',
    templateUrl: './policyparam.component.html',
    styleUrls: ['./policyparam.component.scss']
})
export class PolicyparamComponent implements OnInit {
    constructor(private dataservice: DataserviceService) {}
    @Output() delete = new EventEmitter();

    @Input() device: IParameterInfo;
    @Input() deviceParamType: IParameterParamType;
    @Input() policy: IPolicy;
    arr = Array;
    realDevice: IMeshDevice;
    deviceSpec: IParameterSpecification;
    activeParam: IParameterSpecificationFeature;

    ngOnInit() {
        if (undefined === this.policy) {
            this.realDevice = this.dataservice.meshDevces.find((x) => x.device_id === this.device.device_id);
        } else {
            this.realDevice = this.dataservice.meshDevces.find((x) => x.device_id === this.device.device_id && x.root === this.policy.root);
        }
        if (this.realDevice) {
            this.deviceSpec = this.dataservice.getDeviceSepc(this.realDevice.deviceType);
            this.activeParam = this.deviceSpec.features_config.find((x) => x.key === this.deviceParamType.required);
            for (let i = this.device.element.length - 1; i >= 0; i--) {
                if (this.activeParam.placeholders.length <= this.device.element[i]) {
                    this.device.element.splice(i, 1);

                    console.log('Removed parameter: ', this.device.element);
                }
            }
        } else {
            // this.delete.emit();
        }
        console.log('Init');
    }

    getInverted(index: number) {
        if (undefined === this.device.element_cfg) {
            this.device.element_cfg = {};
        }
        return this.device.element_cfg[index] === 1;
    }

    SelIO(index: number) {
        console.log('SeiIO');
        if (undefined === this.device.element_cfg) {
            this.device.element_cfg = [];
        }
        if (this.device.element.includes(index)) {
            if (this.device.element_cfg[index] === 0) {
                this.device.element_cfg[index] = 1;
            } else {
                this.device.element.splice(this.device.element.indexOf(index), 1);
                this.device.element_cfg[index] = undefined;
            }
        } else {
            this.device.element.push(index);
            this.device.element_cfg[index] = 0;
        }
        console.log(this.device.element);
    }

    remove() {
        console.log('Emit delete');
        this.delete.emit();
    }
}
