import { EventEmitter } from 'events';

export interface IPolicy {
    alerted: boolean;
    GUID: string;
    index: number;
    class: string;
    name: string;
    configurations: string[];
    rawdata: any;
    type: string;
    area: string;
    ambient: string;
    parameters: { [id: string]: IParameter };
    order: number;
    haveCard: boolean;
    root: string;
}

export interface IParameter {
    customData: string;
    type: string;
    index: string;
    value: any;
    dataType: string;
    name: string;
    code: string;
    message: any;
}

export interface IUpdateMessage {
    GUID: string;
    action: string;
    value: string;
    index: number;
    datatype: string;
    customData: string;
    message: any;
}

export interface ICard {
    code: string;
    alwaiseValid: boolean;
    // validFrom: Date;
    validTo: Date;
    isPassepartout: boolean;
    connectedRooms: { [id: string]: IConnectedRoomsDetails };
    cardName: string;
    isPin: boolean;
    checkoutTime: string;
}

export interface IConnectedRoomsDetails {
    alwaiseValid: boolean;
    validFrom: Date;
    validTo: Date;
    checkoutTime: string;
    timeSlot: ITimeSlot[];
    guestName: string;
}

export interface ITimeSlot {
    from: string;
    to: string;
}

export interface IdebacaUser {
    idebacaUserId: number;
    username: string;
    password: string;
    fullname: string;
    role: number;
    email: string;
}

export interface IUserProfile {
    username: string;
    fullname: string;
    email: string;
    oldpassword: string;
    newpassword: string;
}

export interface IArea {
    id: string;
    name: string;
    order: number;
    edit: boolean;
}

export interface IMeshDevice {
    meshDeviceId: number;
    device_id: number;
    communicationType: number;
    typeViolation: boolean;
    deviceType: number;
    fwVersion: string;
    isOnline: boolean;
    lastUpdate: Date;
    mac: string;
    parent: string;
    name: string;
    selected: boolean;
    error: number;
    status: number;
    signal: number;
    deleted: boolean;
    root: string;
    serial_number: string;
    uptime: number;
    legacy: boolean;
    mem8: number;
    ssid: string;
    pass: string;
}

/********************************************** */
export interface IPolicyItem {
    name: string;
    icon: string;
    invocation: string;
    policyName: string;
    polclass: string;
    guid: string;
    code: string;
    polType: number;
    index: number;
    params: IPolicyParamsItem[];
    configurations: string[];
    virtuals: boolean[];
    root: string;
    scenery: { [id: string]: ISceneryConfigAction[] };

    // configs: string[];
}

export interface IPolicyScenery {
    action: string;
    value: string;
    actions: ISceneryConfigAction[];
}

export interface IPolicyParamsItem {
    code: string;
    devices: IParameterInfo[];
    inoulogic: number[];
}

export interface IParameterInfo {
    device_id: number;
    element: number[];
    element_cfg: { [id: number]: number };
}

/************************************************* */

export interface IParameterParamType {
    name: string;
    required: string;
    default: number[];
    inoutlogic: string[];
    code: string;
}

export interface IPolicySpecification {
    name: string;
    icons: string[];
    polclass: string;
    polType: number;
    index: number;
    category: string;
    code: string;
    params: IParameterParamType[];
    configurations: IPolicySpecificationConfiguration[];
    commands: IPolicyCommand;
    events: IPolicyCommand[];
}

export interface IPolicyCommand {
    Label: string;
    action: string;
    value: number;
}

export interface IPolicySpecificationConfiguration {
    label: string;
    minvalue: number;
    maxvalue: number;
    scale: number;
    label_scale: string;
    default_val: string;
    index: string;
    isVirtual: boolean;
    valType: string;
}

export interface IParameterSpecification {
    name: string;
    type: number;
    category: string;
    features: string[];
    features_config: IParameterSpecificationFeature[];
}

export interface IParameterSpecificationFeature {
    key: string;
    label: string;
    placeholders: string[];
}

/************************** */

export interface IdebacaFieldCommand {
    guid: string;
    action: string;
    value: number;
}

export interface IUpgradeMessages {
    ids: number[];
    deviceType: number;
    fwVersion: string;
    ssid: string;
    pass: string;
}

export interface IFirmwareItem {
    device: number;
    firmwares: number[];
}

export interface IOtaUpdate {
    progress: number;
}

export interface IGraphData {
    mac: string;
    device_id: number;
    name: string;
    children?: IGraphData[];
}

export interface ISystem {
    key: string;
    name: string;
    configuration: IPolicyItem[];
}

export interface ICpuinfo {
    mem8: number[];
    mem32: number[];
    memint: number[];
    tag: string;
    lastmessage: Date;
}

export interface IDirectMessages {
    device_id: number;
    message: number[];
    message_len: number;
    message_id: number;
    reg: number;
    status: number;
    messageString: number;
}

export interface IInputUpdateItem {
    device_id: number;
    value: number;
    shadow: number;
    inputCode: string;
    mask: number;
}

export interface ISceneryConfig {
    name: string;
    activation: string;
    guid: string;
    area: string;
    order: number;
    type: string;
    activators: ISceneryActivator[];
    actions: ISceneryConfigAction[];

    time: string;
    days: string;
    mode: number;
}

export interface ISceneryActivator {
    device_id: number;
    code: string;
    element: number[];
}
export interface ISceneryConfigAction {
    policy: string;
    label: string;
    action: string;
    value: number;
    delay: number;
}

export interface IRiscoZone {
    id: number;
    did: number;
    OpenTrip: boolean;
    Arm: boolean;
    Alarm: boolean;
    Tamper: boolean;
    Trouble: boolean;
    Lost: boolean;
    LowBatterytrouble: boolean;
    Bypass: boolean;
    Communicationtrouble: boolean;
    SoakTesttrouble: boolean;
    hourzone24: boolean;
    Notused: boolean;
    name: string;
}

export interface IRiscoPartition {
    id: number;
    did: number;
    name: string;

    Alarm: boolean;
    Duress: boolean;
    FalseCoed: boolean;
    Fire: boolean;
    Panic: boolean;
    Medic: boolean;
    Arm: boolean;
    HomeStay: boolean;
    ReadytoArm: boolean;
    ExitOpenzoneisopen: boolean;
    Exist: boolean;
    Resetrequired: boolean;
    NoActivityAlert: boolean;
    Group_A_Arm: boolean;
    Group_B_Arm: boolean;
    Group_C_Arm: boolean;
    Group_D_Arm: boolean;
    Trouble: boolean;
}

export interface IRiscoSystem {
    id: number;

    did: number;
    name: string;
    ConnectedToCloud: boolean;
    LowNoBatterytrouble: boolean;
    ACtrouble: boolean;
    Phonelinetrouble: boolean;
    Clocktrouble: boolean;
    Defaultswitch: boolean;
    MS1reporttrouble: boolean;
    MS2reporttrouble: boolean;
    MS3reporttrouble: boolean;
    BoxorBacktamper: boolean;
    Programmingmode: boolean;
    Min3Bypass: boolean;
    WalkTest: boolean;
    AUXtrouble: boolean;
    RS485Bustrouble: boolean;
    BellSwitchLSSwitch: boolean;
    BELLtrouble: boolean;
    BELLtamper: boolean;
    ServiceExpired: boolean;
    PaymentExpired: boolean;
    SysteminServicemode: boolean;
    DualPath: boolean;
}

export interface IServerMessage {
    level: ErrorMessageLevel;
    deviceId: number;
    cpu: string;
    messageKey: string;
    messageType: MessageType;
    timestamp: Date;
}

export enum ErrorMessageLevel {
    DEBUG = 0,
    WARNING = 1,
    ERROR = 2,
    CRITICAL = 3
}
export enum MessageType {
    OTHER = 0,
    CPUCONFIGURATION = 1
}

export interface ICronoTime {
    hh: number;
    mm: number;
    value: number;
}

export interface ISiteConfiguration {
    ssid: string;
    pass: string;
    ota_url: string;
    site_name: string;
    license: string;
    cardAlwaiseValid: boolean;
    alarmEnabled: boolean;
    enableCode: boolean;
    enableThermo: boolean;
    codeLen: number;
}

export interface ISysLog {
    logItemId: number;
    timestamp: Date;
    project: number;
    user: string;
    eventItem: number;
    eventDescription: string;
    eventPol: string;
    errorLevel: number;
    key: string;
    system: string;
}

export interface IImportCard {
    name: string;
    cards: string[];
}

export interface IRoomConfig {
    din: number[];
    dout: number[];
    intr: boolean;
    extr: boolean;
    espin: boolean;
    espout: boolean;
    keyb: boolean;
}

export interface IGraphLogDataItem {
    name: any;
    value: number;
}

export interface IGraphLogData {
    name: string;
    series: IGraphLogDataItem[];
}

export interface ILogicalElementItem {
    name: string;
    data: IParameterInfo;
}

export interface ILogicalElement {
    guid: string;
    elementType: string;
    name: string;
    inNumber: number;
    data: ILogicalElementItem[];
}

export interface IScanData {
    progress: number;
    found: number;
    fnew: number;
}


export interface ICode  {
    code: string;
}