import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import * as d3 from 'd3';
import { DataserviceService } from 'src/app/dataservice.service';
import { IMeshDevice, IGraphData } from 'src/interfaces';

@Component({
    selector: 'app-meshgraph',
    templateUrl: './meshgraph.component.html',
    styleUrls: ['./meshgraph.component.scss']
})
export class MeshgraphComponent implements AfterViewInit, OnChanges {
    constructor(public dataservice: DataserviceService) {}
    @ViewChild('chart') chartContainer: ElementRef;
    width = 460;
    height = 460;
    radius = this.width / 2;
    margin = { top: 20, right: 20, bottom: 30, left: 40 };
    private chart: any;
    data: IGraphData;
    roots: IMeshDevice[];
    currentRoot: IMeshDevice;

    ghetChild(parent) {
        return this.dataservice.meshDevces.filter(x => parseInt(x.parent, 16) === parent + 1);
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log('Changed: ', changes);
    }

    buildData(root) {
        console.log('builddata', root);
        const childs = this.ghetChild(parseInt(root.mac, 16));
        if (undefined === root.children) {
            root.children = [];
        }
        for (const child of childs) {
            if (child.isOnline) {
                const newchild: any = { mac: child.mac, name: child.name, children: [] , device_id: child.device_id.toString()};
                this.buildData(newchild);
                root.children.push(newchild);
            }
        }
    }
    ngAfterViewInit() {
        this.roots = this.getRoots();
        if (this.roots.length > 0) {
            this.buildChart(this.roots[0]);
        }
    }

    getRoots() {
        return this.dataservice.meshDevces.filter(x => x.deviceType === 100 && x.isOnline);
    }
    buildChart(rootElement: IMeshDevice) {
        this.chartContainer.nativeElement.innerHTML = '';
        this.currentRoot = rootElement;
        console.log('currentRoot: ', this.currentRoot);
        const system = {};
        // const rootElement = this.dataservice.meshDevces.find(x => x.deviceType === 100 && x.isOnline);
        if (undefined === rootElement) {
            return;
        }
        this.data = { name: 'ROOT', mac: rootElement.mac, children: [], device_id: 0};
        this.buildData(this.data);
        console.log(this.data);
        const element = this.chartContainer.nativeElement;
        const root = this.tree(this.data);
        const svg = d3
            .select(element)

            .append('svg')
            .attr('width', element.offsetWidth)
            .attr('height', element.offsetHeight)

            .append('g');
        const contentWidth = element.offsetWidth - this.margin.left - this.margin.right;
        const contentHeight = element.offsetHeight - this.margin.top - this.margin.bottom;

        const link = svg
            .append('g')
            .attr('fill', 'none')
            .attr('stroke', '#555')
            .attr('stroke-opacity', 0.4)
            .attr('stroke-width', 1.5)
            .selectAll('path')
            .data(root.links())
            .join('path')
            .attr(
                'd',
                d => `
        M${d.target.y},${d.target.x * 4}
        C${d.source.y + (root as any).dy / 2},${d.target.x * 4}
         ${d.source.y + (root as any).dy / 2},${d.source.x * 4}
         ${d.source.y},${d.source.x * 4}
      `
            );

        const node = svg
            .append('g')
            .attr('stroke-linejoin', 'round')
            .attr('stroke-width', 3)
            .selectAll('g')
            .data(root.descendants())
            .join('g')
            .attr('transform', d => `translate(${d.y},${d.x * 4})`);

        node.append('circle')
            .attr('fill', d => (d.children ? '#555' : '#999'))
            .attr('r', 2.5);

        node.append('text')
            .attr('dy', 12)
            .attr('font-size', 12)

            .attr('x', d => (d.children ? 0 : 6))
            .text(d => (d.data as any).name)
            .filter(d => (d as any).children)
            .attr('text-anchor', 'middle')
            .clone(true)
            .lower()
            .attr('stroke', 'white');
        node.append('text')
            .attr('dy', -6)
            .attr('font-size', 12)

            .attr('x', d => (d.children ? 0 : 6))
            .text(d => (d.data as any).device_id)
            .filter(d => (d as any).children)
            .attr('text-anchor', 'middle')
            .clone(true)
            .lower()
            .attr('stroke', 'white');
        const elem = svg.node();

        svg.attr('viewBox', this.autoBox(elem));
        const { x, y, width, height } = elem.getBBox();
        console.log('BBox: ', x, y, width, height);
        // element.attr('width', width).attr('height', height);
        svg.attr('transform', `translate(${-x},${-y})`);

        // Create the cluster layout:
        // const cluster = d3.cluster().size([360, this.radius - 60]); // 360 means whole circle. radius - 60 means 60 px of margin around dendrogram

        // Give the data to this cluster layout:
    }

    autoBox(svg): any {
        const { x, y, width, height } = svg.getBBox();
        return [x, y, width, height];
    }

    tree = data => {
        const root: any = d3.hierarchy(data).sort((a, b) => d3.descending(a.height, b.height) || d3.ascending(a.data.name, b.data.name));
        root.dx = 10;
        root.dy = this.width / (root.height + 1);
        return d3.cluster().nodeSize([root.dx, root.dy])(root);
    }
}
