<div class="login-wrapper fx-layout-row fx-align--center-x fx-align--x-center">
    <mat-card class="box">
        <mat-card-header>
            <img src="assets/logo_ipnetgreen_big.png" width="210px" />
            <!--<mat-card-title>Log in</mat-card-title>-->
        </mat-card-header>

        <div class="example-form">
            <mat-card-content>
                <mat-form-field class="example-full-width">
                    <input matInput placeholder="Username" [(ngModel)]="user.username" />
                </mat-form-field>

                <mat-form-field class="example-full-width">
                    <input matInput type="password" placeholder="Password" [(ngModel)]="user.password" (keydown.enter)="login()" />
                </mat-form-field>
            </mat-card-content>
            <button mat-stroked-button color="accent" class="btn-block" (click)="login()">Log in</button>
        </div>
    </mat-card>
</div>
