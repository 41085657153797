<div class="pagecontainer">
    <div>
        <div *ngFor="let area of dataservice.areas; let i = index" class="areas">
            <h2>{{ area.name }}</h2>
            <div class="roomscontainer">
                <div *ngFor="let policy of dataservice.splittedFields[area.id]">
                    <app-room *ngIf="dataservice.allowedRooms.includes(policy.type)" [policy]="policy"> </app-room>
                    <app-onoff [policy]="policy" *ngIf="policy.type == 'light'"></app-onoff>
                    <app-updown [policy]="policy" *ngIf="policy.type == 'window'"></app-updown>
                    <app-riscoalarm [policy]="policy" *ngIf="policy.type == 'alarm_risco'"></app-riscoalarm>
                    <app-scenitem [policy]="policy" *ngIf="policy.type == 'scenery'"></app-scenitem>
                    <app-door-phone [policy]="policy" *ngIf="policy.type == 'doorphone'"></app-door-phone>
                    <app-powermeter [policy]="policy" *ngIf="policy.type == 'powermeter'"></app-powermeter>
                </div>
               
            </div>
        </div>
    </div>
</div>
