<h1 mat-dialog-title>Aggiungi dispositivo</h1>
<div mat-dialog-content>
    <mat-divider></mat-divider>
    <mat-form-field class="fieldclass">
        <input matInput placeholder="Filter" [(ngModel)]="filter" />
    </mat-form-field>
    <!--
    <mat-form-field class="fieldclass">
        <mat-label>Elemento di sistema</mat-label>
        <mat-select [(ngModel)]="deviceAdd">
            <mat-option *ngFor="let device of dataservice.deviceDefs" [value]="device">
                {{ device.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="fieldclass">
        <mat-label>Tipo di connessione</mat-label>
        <mat-select [(ngModel)]="deviceComm">
            <mat-option *ngFor="let comm of dataservice.commTypes; let i = index" [value]="i">
                {{ comm }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="fieldclass" *ngIf="perm('MAC')">
        <input matInput placeholder="MAC Address" [(ngModel)]="devmac" />
    </mat-form-field>
    <mat-form-field class="fieldclass small" *ngIf="perm('IDX')">
        <input matInput placeholder="Indirizzo" [(ngModel)]="deviceId" />
    </mat-form-field>
    <button mat-icon-button aria-hidden="true" (click)="addPolicy()">
        <mat-icon>add</mat-icon>
    </button>
    <mat-divider></mat-divider>
  -->
    <div class="meshcontainer">
        <ng-template ngFor let-dev [ngForOf]="dataservice.meshDevces">
            <app-meshdevice *ngIf="(dev.root === root || root===undefined) && getFilter(dev)" [device]="dev" [forbid]="isForbid(dev)"
                (click)="addModule(dev)"></app-meshdevice>
        </ng-template>
    </div>
</div>
