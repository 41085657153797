import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-idebutt',
    templateUrl: './idebutt.component.html',
    styleUrls: ['./idebutt.component.scss']
})
export class IdebuttComponent implements OnInit {
    @Input() text;
    @Input() img;
    @Input() type;
    @Input() checked;
    @Input() icon;
    @Input() disabled;
    constructor() { }

    ngOnInit() { }

    click(event) {
        if (this.disabled) {
            event.stopPropagation();
        }
    }
}
