import { Component, OnInit } from '@angular/core';
import { DataserviceService } from '../dataservice.service';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
    constructor(public dataservice: DataserviceService) {}

    ngOnInit() {}

    getRoleStyle() {
        if (this.dataservice.currentUser) {
            switch (this.dataservice.currentUser.role) {
                case 0:
                    return '';
                case 1:
                    return 'userrole userrole--user';
                case 2:
                    return 'userrole userrole--admin';
                case 3:
                    return 'userrole userrole--sysadmin';
            }
        }
    }

    getRoleName() {
      if (this.dataservice.currentUser) {
          switch (this.dataservice.currentUser.role) {
              case 0:
                  return 'GUEST';
              case 1:
                  return 'USER';
              case 2:
                  return 'ADMIN';
              case 3:
                  return 'SYSADMIN';
          }
      }
    }
}
