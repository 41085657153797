<button  *ngIf="!loading" mat-icon-button (click)="getCustomSubConfig()"><mat-icon>refresh</mat-icon></button>
<mat-spinner class="spinner--small" *ngIf="loading" [diameter]="32" ></mat-spinner>
<div class="subdevices">
    <div class="subdev" *ngFor="let item of devices" [ngClass]="{ 'subdev--online': item.isOnline }">
        <div class="subdev__type">{{ getType(item.deviceType) }}-{{ item.deviceType }}</div>
        <div class="subdev__id">[{{ item.device_id }}]</div>
        <div class="subdev__mac">{{ item.mac }}</div>
        <div class="subdev__fw">Fw: 1.{{ item.fwVersion }}</div>
        <button mat-button (click)="onUpgrade(item)"><mat-icon>cloud_upload</mat-icon>{{getFw(item)}}</button>

    </div>
</div>
