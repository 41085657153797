<div class="bulletcontainer solidborder">
    <button class="insertbutton" (click)="btInclude('EXCLUDE')" mat-raised-button [disabled]="btDisable()" color="warn" *ngIf="getIncluded()">EXCL</button>
    <h4 class="alarm-label">{{ device?.mac }} - {{ device?.serial_number }}</h4>
    <div class="bullet bullet--Arm" [class]="getClass('Arm')">Arm</div>
    <div class="bullet bullet--Alarm" [class]="getClass('Alarm')">Alarm</div>
    <div class="bullet bullet--OpenTrip" [class]="getClass('OpenTrip')">OpenTrip</div>
    <div class="bullet bullet--Tamper" [class]="getClass('Tamper')">Tamper</div>
    <div class="bullet bullet--Trouble" [class]="getClass('Trouble')">Trouble</div>
    <div class="bullet bullet--Lost" [class]="getClass('Lost')">Lost</div>
    <div class="bullet bullet--LowBatterytrouble" [class]="getClass('LowBatterytrouble')">LowBattery</div>
    <div class="bullet bullet--Bypass" [class]="getClass('Bypass')">Bypass</div>
    <div class="bullet bullet--hourzone24" [class]="getClass('hourzone24')">hourzone24</div>
</div>
