import { Component, Input, OnInit } from '@angular/core';
import { DataserviceService } from 'src/app/dataservice.service';
import { IMeshDevice, IParameter, IPolicy } from 'src/interfaces';

@Component({
    selector: 'app-system',
    templateUrl: './system.component.html',
    styleUrls: ['./system.component.scss']
})
export class SystemComponent implements OnInit {
    @Input() parameter: IParameter;
    @Input() policy: IPolicy;

    device: IMeshDevice;

    constructor(public dataservice: DataserviceService) {}

    ngOnInit(): void {
        const id = parseInt(this.parameter.code.split('_')[1], 10);
        const device = this.dataservice.meshDevces.find((x) => x.device_id === id);
        this.device = device;
        console.log('Device: ', device);
    }

    getClass(key: string) {
        if (this.parameter.message) {
            return this.parameter.message[key] ? '' : 'bullet--disabled';
        }
        return 'bullet--disabled';
    }

    disconnect() {
        const data = {
            value: 0,
            action: 'DISCONNECT'
        };
        this.dataservice.sendMessageToServer(this.policy, 'SYSTEM', data);
    }

    connect() {
        const data = {
            value: 0,
            action: 'CONNECT'
        };
        this.dataservice.sendMessageToServer(this.policy, 'SYSTEM', data);
    }

    reset() {
        const data = {
            value: 0,
            action: 'RESET'
        };
        this.dataservice.sendMessageToServer(this.policy, 'SYSTEM', data);
    }
}
