import { Component, OnInit } from '@angular/core';
import { DataserviceService } from '../dataservice.service';

@Component({
    selector: 'app-editpolicyconf',
    templateUrl: './editpolicyconf.component.html',
    styleUrls: ['./editpolicyconf.component.scss']
})
export class EditpolicyconfComponent implements OnInit {
    polspec: string;
    constructor(  public dataservice: DataserviceService) {}

    ngOnInit() {
        this.dataservice.getPoliciesDef().subscribe(data => {
            this.polspec = JSON.stringify(data, null, '\t');
        });
    }
    saveConfig() {
        const parsed = JSON.parse(this.polspec);
        this.dataservice.setPoliciesDef(JSON.stringify(parsed, null, 0));
    }
}
