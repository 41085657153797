import { Component, OnInit, Input } from '@angular/core';
import { DataserviceService } from 'src/app/dataservice.service';
import { IPolicy } from 'src/interfaces';

@Component({
  selector: 'app-updown',
  templateUrl: './updown.component.html',
  styleUrls: ['./updown.component.scss']
})
export class UpdownComponent implements OnInit {

  constructor(public dataservice: DataserviceService) { }
  @Input() policy: IPolicy;
  ngOnInit() {
  }

  getClass(selector: string) {
    switch (selector) {
      case 'UP':
        if (this.isUp()) {
          return 'active';
        } else {
          return 'inactive';
        }
        break;
      case 'DOWN':
        if (this.isDown()) {
          return 'active';
        } else {
          return 'inactive';
        }
        break;
    }


  }

  isUp() {
    return this.policy.parameters.UP.value !== '00000000' && this.policy.parameters.UP.value !== 0;
  }

  isDown() {
    return this.policy.parameters.DOWN.value !== '00000000' && this.policy.parameters.DOWN.value !== 0;
  }



  butclick(data: string) {
    this.dataservice.sendmessage(this.policy, 'SET', data);
  }
  
}


